import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
  FormGroup,
  Box,
  IconButton,
} from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { AlertMessage } from '../../types/form';
import { ContraenteField, contraenteFields } from '../../types/request';
import { validateContraente as validate } from '../../utils/validate';
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadFile } from '../../models/request';
import { sanitizeEmail } from '../../utils/email';
import DropZone from '../DropZone';
import { cloneDeep, get } from 'lodash';
import { EXTRA_DOCUMENT_ID } from '../../utils/constants';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { EXTRA_DOCUMENT_CONTRAENTE } from '../../utils/constants';

type AddContraenteDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: Object) => void;
  documentTypes: any;
};

interface IExtraDocument {
  id: string;
  documentId: string;
  name: string;
  required: boolean;
  description: string;
}

const AddContraenteDialog = ({
  open,
  onClose,
  onSubmit,
  documentTypes,
}: AddContraenteDialogProps) => {
  const [fields, setFields] = useState(
    Object.fromEntries([
      ...contraenteFields.map((field) => [field.id, '']),
      ['document', []],
    ])
  );
  const [extraDocuments, setExtraDocuments] = useState<IExtraDocument[]>([
    EXTRA_DOCUMENT_CONTRAENTE(),
  ]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState<boolean>(true);
  const [documents, setDocuments] = useState<any>(
    documentTypes.filter((d: any) => d.persona === 'giuridica')
  );
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const handleConfirm = async () => {
    handleClose();
    setOpenConfirmation(false);
  };

  const closeConfirmation = () => setOpenConfirmation(false);

  const handleFieldChange = (field: ContraenteField, value: string) => {
    validate(value, field, switchChecked);

    setFields({ ...fields, [field.id]: value });
  };

  const handleSubmit = () => {
    const sanitizedEmail = sanitizeEmail(fields.pec);

    const updatedFields = { ...fields, pec: sanitizedEmail };

    onSubmit(updatedFields);

    //Reset fields
    setFields(
      Object.fromEntries([
        ...contraenteFields.map((field) => [field.id, '']),
        ['document', []],
      ])
    );

    // Reset other states
    setAlertMessage(null);
    setShowAlert(false);
    setSwitchChecked(true);
    setSelectedFiles([]);
  };

  const handleClose = () => {
    contraenteFields.forEach((field) => {
      field.error = false;
      field.helperText = undefined;
    });

    //Reset fields
    setFields(
      Object.fromEntries([
        ...contraenteFields.map((field) => [field.id, '']),
        ['document', []],
      ])
    );

    // Reset other states
    setAlertMessage(null);
    setShowAlert(false);
    setSwitchChecked(true);
    setSelectedFiles([]);

    onClose();
  };

  // Function to handle file selection
  const handleFileChange = (
    event: any,
    documentId: string,
    isExtraDoc: boolean = false
  ) => {
    const file = event.target.files[0];

    setSelectedFiles([
      ...selectedFiles,
      {
        id: documentId,
        file,
        isUploaded: false,
        tempId: isExtraDoc ? EXTRA_DOCUMENT_ID : null,
      },
    ]);
  };

  /**
   * @param documentId
   * Remove file from list of files that need to be uploaded.
   */
  const handleRemoveFile = (
    documentId: string,
    isExtraDoc: boolean = false
  ) => {
    const files = structuredClone(selectedFiles);
    const fileIndex = files.findIndex((item: any) => item.id === documentId);
    if (fileIndex >= 0) {
      files.splice(fileIndex, 1);
    }
    const clonedFieldDocs = cloneDeep(get(fields, 'document', []));
    const filteredDocs = clonedFieldDocs.filter(
      (d: any) => d.documentId !== documentId
    );

    // If it's an extra document, remove it from the extraDocuments piece of state

    let updatedExtraDocuments = cloneDeep(extraDocuments);

    if (isExtraDoc) {
      const uploadedFilesCount = selectedFiles.filter(
        (item: any) => item?.tempId === EXTRA_DOCUMENT_ID && item.isUploaded
      ).length;

      if (
        extraDocuments.length - uploadedFilesCount === 1 &&
        selectedFiles.find((item: any) => item.id === documentId)?.isUploaded
      ) {
        updatedExtraDocuments = updatedExtraDocuments.filter(
          (doc: any) => doc?.id !== documentId
        );
      } else {
        updatedExtraDocuments = updatedExtraDocuments.filter(
          (doc: any) => doc?.id !== documentId
        );
        updatedExtraDocuments.push(EXTRA_DOCUMENT_CONTRAENTE());
      }
    }

    setExtraDocuments(updatedExtraDocuments);

    setFields({ ...fields, document: filteredDocs });

    setSelectedFiles([...files]);
  };

  const handleUpload = async (document: any, isExtraDoc?: boolean) => {
    const file = selectedFiles.find(
      (file: any) => file.id === document.id
    ).file;

    if (file) {
      try {
        const res = await uploadFile(file);

        if (res.status === 201) {
          const docs: any = [...fields.document];
          docs.push({
            id: EXTRA_DOCUMENT_ID,
            name: res.data.originalname,
            path: res.data.filename,
            mime: res.data.mimetype,
            description: document?.description,
            documentId: document.id,
          });
          if (isExtraDoc) {
            const extraDocumentsCopy = cloneDeep(extraDocuments);
            extraDocumentsCopy.push(EXTRA_DOCUMENT_CONTRAENTE());
            setExtraDocuments(extraDocumentsCopy);
          }

          setFields({ ...fields, document: docs });
          const updatedFiles = selectedFiles.map((file: any) => {
            if (file.id === document.id) {
              return {
                ...file,
                isUploaded: true,
              };
            }
            return file;
          });

          setSelectedFiles(updatedFiles);
        } else {
          throw new Error('Unable to upload file');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: "Errore durante l'upload dei file",
        });
        setShowAlert(true);
      }
    }
  };

  const handleSwitchChange = () => {
    const field = contraenteFields.find((f) => f.id === 'partita_iva');

    const updatedSwitch = !switchChecked;
    // Avoid incorrect red error when switching
    if (field?.error || (!field?.error && fields['partita_iva']))
      validate(fields['partita_iva'], field, updatedSwitch); //validating with the updated value of switchChecked
    const name = updatedSwitch ? 'giuridica' : 'fisica';
    setDocuments(documentTypes.filter((d: any) => d.persona === name));
    setSelectedFiles([]);
    setSwitchChecked(updatedSwitch);
  };

  useLayoutEffect(() => {
    if (open) {
      const name = switchChecked ? 'giuridica' : 'fisica';
      setDocuments(documentTypes.filter((d: any) => d.persona === name));
    } else {
      setDocuments([]);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>Aggiungi Nuova Anagrafica</DialogTitle>
      <DialogContent>
        <FormGroup>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Button
              variant={switchChecked ? 'outlined' : 'text'}
              onClick={() => {
                if (!switchChecked) handleSwitchChange();
              }}
            >
              Persona giuridica
            </Button>
            <Button
              variant={!switchChecked ? 'outlined' : 'text'}
              onClick={() => {
                if (switchChecked) handleSwitchChange();
              }}
            >
              Persona fisica
            </Button>
          </Box>
          {contraenteFields.map((field) => {
            // Render fields for each type of Persona
            if (
              (field.showFor === 'persona_fisica' && switchChecked) ||
              (field.showFor === 'persona_giuridica' && !switchChecked)
            ) {
              return null;
            }
            return (
              <TextField
                key={field.id}
                label={switchChecked ? field.label_1 : field.label_2}
                type={field.type}
                value={fields[field.id]}
                required={field.required}
                error={field.error}
                helperText={field.helperText}
                onChange={(e) => handleFieldChange(field, e.target.value)}
                fullWidth
                sx={{ my: 1 }}
              />
            );
          })}
        </FormGroup>

        {documents.map((document: any) => {
          const required =
            !!get(document, 'required', false) ||
            !!get(document, 'document.required', false);
          const requiredLabel = required ? '*' : '';
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              key={document.id}
              margin="20px auto"
            >
              <Box flexGrow={1}>
                <label style={{ fontSize: 12 }}>
                  {document.name + requiredLabel}
                </label>
                <Box display="flex" alignItems="center">
                  <DropZone
                    file={
                      selectedFiles.find((file: any) => file.id === document.id)
                        ?.file
                    }
                    customFn={(event: any) =>
                      handleFileChange(event, document.id)
                    }
                    parent={document}
                    setFile={(acceptedFiles: any) =>
                      handleFileChange(
                        { target: { files: acceptedFiles } },
                        document.id,
                        false
                      )
                    }
                  />

                  <Box
                    marginLeft={2}
                    width="200px"
                    display="flex"
                    justifyContent="center"
                  >
                    {!selectedFiles.find(
                      (file: any) => file.id === document.id
                    ) ? (
                      <Button variant="outlined" component="label">
                        Scegli file
                        <input
                          type="file"
                          id={document.id}
                          hidden
                          onChange={(event) => {
                            handleFileChange(event, document.id);
                          }}
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          onClick={() => handleUpload(document)}
                          disabled={
                            selectedFiles.find(
                              (file: any) => file.id === document.id
                            )?.isUploaded
                          }
                        >
                          Conferma
                        </Button>
                        <IconButton
                          onClick={() => handleRemoveFile(document.id)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        {extraDocuments.map((extraDoc) => {
          const requiredLabel = extraDoc.required ? '*' : '';
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              key={extraDoc.id}
              margin="20px auto"
            >
              <Box flexGrow={1}>
                <label style={{ fontSize: 12 }}>
                  {extraDoc.name + requiredLabel}
                </label>
                <Box display="flex" alignItems="center">
                  <DropZone
                    file={
                      selectedFiles.find((file: any) => file.id === extraDoc.id)
                        ?.file
                    }
                    customFn={(event: any) =>
                      handleFileChange(event, extraDoc.id, true)
                    }
                    parent={extraDoc}
                    setFile={(acceptedFiles: any) =>
                      handleFileChange(
                        { target: { files: acceptedFiles } },
                        extraDoc.id,
                        true
                      )
                    }
                  />
                  <Box
                    marginLeft={2}
                    width="200px"
                    display="flex"
                    justifyContent="center"
                  >
                    {!selectedFiles.find(
                      (file: any) => file.id === extraDoc.id
                    ) ? (
                      <Button variant="outlined" component="label">
                        Scegli file
                        <input
                          type="file"
                          id={extraDoc.id}
                          hidden
                          onChange={(event) => {
                            handleFileChange(event, extraDoc.id, true);
                          }}
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          onClick={() => handleUpload(extraDoc, true)}
                          disabled={
                            selectedFiles.find(
                              (file: any) => file.id === extraDoc.id
                            )?.isUploaded
                          }
                        >
                          Conferma
                        </Button>
                        <IconButton
                          onClick={() => handleRemoveFile(extraDoc.id, true)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </DialogContent>
      <CardActions>
        <Button
          sx={{ width: '100%' }}
          variant="outlined"
          onClick={() => setOpenConfirmation(true)}
        >
          Annulla
        </Button>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            contraenteFields.some((field) => field.error) ||
            contraenteFields.some(
              (field) => field.required && !fields[field.id]
            ) ||
            selectedFiles.length > fields.document?.length
          }
        >
          Aggiungi
        </Button>
      </CardActions>
      {openConfirmation && (
        <ConfirmationDialog
          title="Sei sicuro?"
          message="Non potrai tornare indietro"
          confirmText="Sì"
          cancelText="Annulla"
          onConfirm={handleConfirm}
          open={openConfirmation}
          onClose={closeConfirmation}
        />
      )}
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddContraenteDialog;
