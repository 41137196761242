import { Button, ButtonTypeMap } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Done from '@mui/icons-material/Done';
import { useState } from 'react';

type LoadingButtonProps = ButtonTypeMap['props'] & {
  loading: boolean;
  children: string;
  onClick: Function;
};
const LoadingButton = (props: LoadingButtonProps) => {
  const { disabled, loading, children, startIcon, onClick } = props;
  const [completed, setCompleted] = useState(false);

  const markCompleted = () => {
    setCompleted(true);
    setTimeout(() => {
      setCompleted(false);
    }, 1000);
  };

  // Filters out custom properties before passing them to inner component
  let key: keyof typeof props;
  const filteredProps: any = {};
  for (key in props) {
    if (
      !['disabled', 'loading', 'children', 'startIcon', 'onClick'].includes(key)
    ) {
      filteredProps[key] = props[key];
    }
  }

  return (
    <Button
      {...filteredProps}
      startIcon={
        loading ? (
          <CircularProgress size={20} />
        ) : completed ? (
          <Done />
        ) : (
          startIcon
        )
      }
      disabled={disabled || loading}
      onClick={() => onClick().finally(() => markCompleted())}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
