import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './style.module.css';

const Loading = () => {
  return (
    <div className={styles.root}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
