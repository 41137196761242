import { Container, Grid } from '@mui/material';
import ForgotPassword from '../../components/ForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <Container sx={{ padding: '120px 0' }}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ marginBottom: '2em' }}
        gap={4}
      >
        <ForgotPassword />
      </Grid>
    </Container>
  );
};

export default ForgotPasswordPage;
