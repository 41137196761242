import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../context/auth';
import { getStatuses } from '../../models/request';
import { Status } from '../../types/request';
import {
  MACRO_STATUS,
  ROLENAMES,
  ADMIN_STATUS_TO_HIDE,
} from '../../utils/constants';
import { get, isEmpty } from 'lodash';

type SideMenuProps = {
  selectedStatus: Status;
  setSelectedStatus: Function;
  selectedMacro?: string;
};

const statusList = [
  MACRO_STATUS.TrattativeInCorso.name,
  MACRO_STATUS.TrattativeConcluse.name,
];

const ALL = {
  id: 'all',
  name: 'Tutti',
  role: 'admin',
};

const SideMenu = ({
  selectedStatus,
  setSelectedStatus,
  selectedMacro,
}: SideMenuProps) => {
  const [statuses, setStatuses] = useState<Status[] | null>(null);
  const [statusVerde, setStatusVerde] = useState<Status[]>([]);
  const [statusRosse, setStatusRosse] = useState<Status[]>([]);
  const { user } = useContext(authContext);

  const handleItemClick = (item: any) => {
    setSelectedStatus(item);
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const res = await getStatuses();
        if (res.status === 200) {
          let filteredStatuses = res.data.data;
          const role = get(user, 'role', '');
          const isBroker = !!(role === ROLENAMES.BROKER);
          const rosse = filteredStatuses.filter((s: any) =>
            ADMIN_STATUS_TO_HIDE.includes(s.id)
          );
          const verde = filteredStatuses.filter(
            (s: any) => !ADMIN_STATUS_TO_HIDE.includes(s.id)
          );
          const adminStatus =
            !!selectedMacro &&
            selectedMacro === MACRO_STATUS.TrattativeConcluse.name
              ? rosse
              : verde;
          adminStatus.unshift(ALL);
          setStatuses(isBroker ? filteredStatuses : adminStatus);
          setStatusRosse(rosse);
          setStatusVerde(verde);
          const selectedOne = isBroker
            ? MACRO_STATUS.TrattativeInCorso.name
            : adminStatus[0];
          setSelectedStatus(selectedOne);
        } else {
          throw new Error('Unable to retrieve statuses.');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchStatuses();
  }, [selectedMacro]);

  const allList = () => {
    return (
      <FormControl sx={{ minWidth: 260 }} fullWidth>
        <InputLabel id="status-select-label">Stato</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={selectedStatus?.id || ''}
          onChange={(event) => handleStatusChange(event, ROLENAMES.ADMIN)}
          label="Stato"
        >
          {!isEmpty(statuses) &&
            statuses?.map((status) => (
              <MenuItem
                key={status?.id}
                value={status?.id}
                style={{ textTransform: 'capitalize' }}
              >
                {status?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const renderStatusMenuItem = (status: string) => {
    return (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    );
  };

  const handleStatusChange = (event: any, role: string) => {
    if (role === ROLENAMES.ADMIN) {
      const status = statuses?.find(
        (s: Status) => s?.id === event.target.value
      );
      setSelectedStatus({ ...status });
    } else {
      setSelectedStatus(event.target.value as string);
    }
  };

  return (
    <>
      {get(user, 'role', '') === ROLENAMES.ADMIN && allList()}
      {get(user, 'role', '') === ROLENAMES.BROKER && (
        <FormControl fullWidth sx={{ minWidth: 260 }}>
          <InputLabel id="status-select-label">Stato</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={selectedStatus || ''}
            onChange={(event) => handleStatusChange(event, ROLENAMES.BROKER)}
            label="Stato"
          >
            {statusList.map(renderStatusMenuItem)}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SideMenu;
