import {
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  CardActions,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inviteUser } from '../../models/user';
import { AlertMessage } from '../../types/form';
import { isEmailValid, sanitizeEmail } from '../../utils/email';

const InviteUser = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isUserInvited, setIsUserInvited] = useState<boolean>(false);

  const handleInvite = async () => {
    // Validate and sanitize the email input
    const sanitizedEmail = sanitizeEmail(email);
    if (!isEmailValid(sanitizedEmail)) {
      setAlertMessage({
        severity: 'warning',
        message: 'Indirizzo email non valido',
      });
      setShowAlert(true);

      return;
    }

    const res = await inviteUser({
      email,
      role,
      fullname: name,
    });

    if (res.status === 201) {
      setAlertMessage({
        severity: 'success',
        message:
          'Invito inviato con successo! Verrai reindirizzato in pochi secondi.',
      });
      setShowAlert(true);
      setIsUserInvited(true);
      setTimeout(() => navigate('/users'), 3000);
    }
  };
  return (
    <>
      <Typography gutterBottom variant="h4" component="h1" align="center">
        Invita nuovo utente
      </Typography>
      <Card sx={{ maxWidth: 345, paddingTop: '20px' }}>
        <CardContent>
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-name">
              Ragione sociale / Nome Cognome
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-name"
              type="text"
              label="Ragione sociale / Nome Cognome"
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="text"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel id="select-role-label">Ruolo</InputLabel>
            <Select
              labelId="select-role-label"
              id="select-role"
              value={role}
              label="Ruolo"
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value="broker">Utente</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </CardContent>

        <CardActions>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={handleInvite}
            disabled={!email || !name || !role || isUserInvited}
          >
            Invita
          </Button>
        </CardActions>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default InviteUser;
