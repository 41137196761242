import { Alert, Grid, Snackbar } from '@mui/material';
import { cloneDeep, get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditRequestAgency from '../../components/Request/EditRequestAgency';
import EditRequestData from '../../components/Request/EditRequestData';
import EditRequestNotes from '../../components/Request/EditRequestNotes';
import {
  getRequest,
  updateSubmissionStatus,
  updateSubmissionInternalAgency,
  updateSubmission,
  uploadFile,
} from '../../models/request';
import { AlertMessage } from '../../types/form';
import Loading from '../../components/Loading';
import { parseJson } from '../../utils/parseJson';
import { REQUEST_STATUS } from '../../utils/constants'; 

const EditRequestAdmin = () => {
  const [request, setRequest] = useState<any>(null);
  const { id } = useParams();
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRequest = async () => {
    setLoading(true);
    try {
      const res = await getRequest(id!);
      if (res.status === 200) {
        const { contraentes, notes, status, ...rest } = res.data;

        //  Esclusione delle richieste in stato "In attesa di compilazione" (DRAFT)
        if (
          status !== REQUEST_STATUS.DRAFT_ADMIN &&
          status !== REQUEST_STATUS.DRAFT_BROKER
        ) {
            const updatedNotes = cloneDeep(notes);
            updatedNotes.push({
            id: 'extra-note',
            message: '',
            status: '',
          });
        const updatedRequest = {
          ...rest,
          contraentes: contraentes,
          notes: updatedNotes,
          notify: false,
        };
        setRequest(updatedRequest);
      }
        
      } else {
        throw new Error('Unable to retrieve the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const updateRequestInfo = async (payload: any) => {
    setLoading(true);
    try {
      const res = await updateSubmission(payload);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        return true;
      } else {
        throw new Error('Unable to update the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
      setLoading(false);
      return false;
    }
  };

  const updateRequest = async (payload: any) => {
    try {
      const res = await updateSubmissionStatus(payload);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        return true;
      } else {
        throw new Error('Unable to update the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
      setLoading(false);
      return false;
    }
  };

  const updateInternalAgency = async (payload: any) => {
    setLoading(true);
    try {
      const res = await updateSubmissionInternalAgency(payload);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        return true;
      } else {
        throw new Error('Unable to update the internal agency');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (id) {
      fetchRequest();
    }
  }, [id]);

  const handleSubmit = async () => {
    let message = '';
    setLoading(true);
    const currentStatus = get(request, 'status.id', '');
    const notes = get(request, 'notes', []);
    const extraNote = notes.find((n: any) => n.id === 'extra-note');
    const notify = !!get(request, 'notify', false);
    let documents = get(request, 'extraDocuments', []);

    // Removing the empty objects from the documents to prevent bugs when uploading
    documents = documents.filter((doc: any) => doc?.file);

    if (!isEmpty(documents)) {
      const docResponse: any = await Promise.all(
        documents.map((d: any) => {
          return uploadFile(d.file);
        })
      ).catch(() => []);
      message = isEmpty(docResponse)
        ? 'Richiesta aggiornata con successo ma ci sono problemi con i documenti caricati.'
        : '';
      documents = docResponse.map((res: any) => {
        return {
          id: 'extra-document',
          name: get(res, 'data.originalname', ''),
          path: get(res, 'data.filename', ''),
          mime: get(res, 'data.mimetype', ''),
          description: '',
        };
      });
    }
    const payload = {
      id: get(request, 'id', ''),
      documents: documents,
      status: extraNote?.status || currentStatus,
      notes: notes,
      notify: notify,
    };
    const isSuccess = await updateRequest(payload);
    if (!!isSuccess) {
      if (message) {
        setAlertMessage({
          severity: 'warning',
          message,
        });
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage(null);
        }, 5000);
      }
      fetchRequest();
    }
  };

  const handleSubmitInfo = async (editedRequest: any) => {
    const payload = cloneDeep(editedRequest);
    const isSuccess = await updateRequestInfo(payload);
    if (!!isSuccess) {
      fetchRequest();
    }
  };

  const handleSubmitInternalAgency = async () => {
    const INTERNAL_AGENCY_DEFAULT = {
      id: '0',
      name: '',
      OutcomeTrattativa: '',
      Tasso: '',
      Note: '',
    };
    const stringifyDefault = JSON.stringify(INTERNAL_AGENCY_DEFAULT);

    const groupamaCheck = parseJson(get(request, 'internalAgency.groupama'))
      ? get(request, 'internalAgency.groupama')
      : stringifyDefault;
    const zurichCheck = parseJson(get(request, 'internalAgency.zurich'))
      ? get(request, 'internalAgency.zurich')
      : stringifyDefault;
    const assicuratriceMilaneseCheck = parseJson(
      get(request, 'internalAgency.assicuratrice_milanese')
    )
      ? get(request, 'internalAgency.assicuratrice_milanese')
      : stringifyDefault;
    const revoCheck = parseJson(get(request, 'internalAgency.revo'))
      ? get(request, 'internalAgency.revo')
      : stringifyDefault;
    const tuaCheck = parseJson(get(request, 'internalAgency.tua'))
      ? get(request, 'internalAgency.tua')
      : stringifyDefault;
    const parsedAltre = parseJson(
      get(request, 'internalAgency.altre_compagnie')
    )
      ? parseJson(get(request, 'internalAgency.altre_compagnie'))
      : [{ ...INTERNAL_AGENCY_DEFAULT }];

    const filteredAltreCompagnie = parsedAltre.filter((ac: any) => !!ac?.name);

    const altreCompagnieCheck = JSON.stringify(parsedAltre);
    const filteredAltreCompagnieCheck = JSON.stringify(filteredAltreCompagnie);

    const payload = {
      id: get(request, 'id', ''),
      submissionId: get(request, 'id', ''),
      numero_polizza: get(request, 'internalAgency.numero_polizza', ''),
      premio: get(request, 'internalAgency.premio', ''),
      copie_firmate: get(request, 'internalAgency.copie_firmate', false),
      pagamento_polizza: get(request, 'internalAgency.pagamento_polizza', false),
      groupama: groupamaCheck,
      zurich: zurichCheck,
      assicuratrice_milanese: assicuratriceMilaneseCheck,
      revo: revoCheck,
      tua: tuaCheck,
      altre_compagnie: !!filteredAltreCompagnie?.length
        ? filteredAltreCompagnieCheck
        : altreCompagnieCheck,
    };

    const isSuccess = await updateInternalAgency(payload);
    if (!!isSuccess) {
      fetchRequest();
    }
  };

  return (
    <Grid container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item className="request" xs={12}>
            {request && (
              <EditRequestData
                request={request}
                setRequest={setRequest}
                submit={handleSubmit}
                submitInfo={handleSubmitInfo}
              />
            )}
          </Grid>
          {request && (
            <Grid item className="fields" xs={12}>
              <EditRequestAgency
                request={request}
                setRequest={setRequest}
                submit={handleSubmitInternalAgency}
              />
            </Grid>
          )}
          <Grid item className="fields" xs={12}>
            <EditRequestNotes request={request} setRequest={setRequest} />
          </Grid>
        </>
      )}
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default EditRequestAdmin;
