import {
  Alert,
  Snackbar,
  Grid,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import { cloneDeep, get, isEqual, set } from 'lodash';
import { useEffect, useState } from 'react';
import { AlertMessage } from '../../types/form';
import { parseJson } from '../../utils/parseJson';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { validateRequestAgency } from '../../utils/validate';
import { agencyFields } from '../../types/request';
import Actions from '../Actions';
import CancelIcon from '@mui/icons-material/Cancel';

const outcomeOptions = ['Non quotata', 'Quotata', 'Inviata', 'Conclusa'];

const INTERNAL_AGENCY_DEFAULT = {
  id: '0',
  OutcomeTrattativa: '',
  Tasso: '',
  Note: '',
};

type EditRequestAgencyProps = {
  request: any;
  setRequest: Function;
  submit: Function;
};
const EditRequestAgency = ({
  request,
  setRequest,
  submit,
}: EditRequestAgencyProps) => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [fields, setFields] = useState<any>([]);
  const [extraFields, setExtraFields] = useState<any>([]);

  const onSelectHandler = (e: any, name: string) => {
    const value = get(e, 'target.value', '');
    const boolValue = value === 'true';
    const updatedRequest = cloneDeep(request);
    set(updatedRequest, name, boolValue);
    setRequest(updatedRequest);
  };

  const onChangeHandler = (e: any, name: string) => {
    // const value = get(e, 'target.value', '');
    const value = formatValue(name, get(e, 'target.value', ''));
    const groupId = 'details';
    const fieldEditing = agencyFields.find(
      (field: any) => field.name === 'Premio'
    );

    const updatedRequest = cloneDeep(request);
    set(updatedRequest, name, value);

    const err = validateRequestAgency(
      fields,
      groupId,
      name,
      value,
      fieldEditing
    );

    setFields(err);
    setRequest(updatedRequest);
  };

  const formatValue = (name: string, value: any) => {
    if (name === 'internalAgency.premio') {
      // Remove any existing commas or symbols from the string and convert it to a number
      const valueToFormat = value.replace(/[.]/g, '');
      const parts = valueToFormat.split(',');
      // Format the part before the comma separating 
      const formattedBeforeComma = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // Concatenate the formatted part with the part after the comma
      return formattedBeforeComma + (parts[1] !== undefined ? ',' + parts[1] : '');
    }
    return value;
  }

  const isValid = () => {
    const hasErrors = fields.some((field: any) => {
      const keys = Object.keys(field);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (field[key]?.error) {
          return true;
        }
      }
      return false;
    });

    const extraFieldsHaveErrors = extraFields.some((field: any) => {
      const keys = Object.keys(field);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (field[key]?.error) {
          return true;
        }
      }
      return false;
    });

    const validation = !hasErrors && !extraFieldsHaveErrors;

    return validation;
  };

  useEffect(() => {
    const defaultAltreCompagnie = [{ ...INTERNAL_AGENCY_DEFAULT }];

    const altreCompagnie =
      get(request, 'internalAgency.altre_compagnie') || defaultAltreCompagnie;
    const parsedAltre = parseJson(altreCompagnie);

    if (isEqual(parsedAltre, defaultAltreCompagnie)) {
      const updatedRequest = cloneDeep(request);
      set(
        updatedRequest,
        'internalAgency.altre_compagnie',
        JSON.stringify(parsedAltre)
      );

      setRequest({ ...updatedRequest });
    }

    const updatedExtraFields = parsedAltre?.map((field: any) => {
      return { id: field.id };
    });

    setExtraFields([...updatedExtraFields]);
  }, []);

  const renderGroupama = () => {
    const groupama = get(request, 'internalAgency.groupama', '');

    const parsed = parseJson(groupama);
    const groupId = 'groupama';

    const changeHandler = (e: any, name: string) => {
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      set(parsed, name, value);
      const stringify = JSON.stringify(parsed);
      const updatedRequest = cloneDeep(request);
      set(updatedRequest, 'internalAgency.groupama', stringify);

      const err = validateRequestAgency(
        fields,
        groupId,
        name,
        value,
        fieldEditing
      );

      setFields(err);

      setRequest(updatedRequest);
    };

    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <div
            style={{
              backgroundColor: 'var(--clr-main-blue)',
              textAlign: 'center',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: 18,
              padding: `10px 0px`,
              border: '1px solid #BDBDBD',
            }}
          >
            Compagnia
          </div>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Groupama</InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            disabled
            fullWidth
            value=""
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            style={{
              backgroundColor: 'var(--clr-main-blue)',
              textAlign: 'center',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: 18,
              padding: `10px 0px`,
              border: '1px solid #BDBDBD',
            }}
          >
            Outcome Trattativa
          </div>
          <FormControl fullWidth sx={{ position: 'relative' }}>
            <Select
              value={get(parsed, 'OutcomeTrattativa', '')}
              onChange={(e: any) => changeHandler(e, 'OutcomeTrattativa')}
              disabled={!isEditable}
              size="small"
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
            >
              {outcomeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {get(parsed, 'OutcomeTrattativa', '') !== '' && isEditable &&
              <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa')} aria-label="Annulla" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                <CancelIcon fontSize="small" />
              </IconButton>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            style={{
              backgroundColor: 'var(--clr-main-blue)',
              textAlign: 'center',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: 18,
              padding: `10px 0px`,
              border: '1px solid #BDBDBD',
            }}
          >
            Tasso
          </div>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            value={get(parsed, 'Tasso', '')}
            onChange={(e: any) => changeHandler(e, 'Tasso')}
            disabled={!isEditable}
            size="small"
            error={
              fields.find((field: any) => field.id === groupId)?.[`Tasso`]
                ?.error
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            style={{
              backgroundColor: 'var(--clr-main-blue)',
              textAlign: 'center',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: 18,
              padding: `10px 0px`,
              border: '1px solid #BDBDBD',
            }}
          >
            Note
          </div>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            fullWidth
            value={get(parsed, 'Note', '')}
            onChange={(e: any) => changeHandler(e, 'Note')}
            disabled={!isEditable}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  const renderZurich = () => {
    const zurich = get(request, 'internalAgency.zurich', '');
    const parsed = parseJson(zurich);
    const groupId = 'zurich';

    const changeHandler = (e: any, name: string) => {
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      set(parsed, name, value);
      const stringify = JSON.stringify(parsed);
      const updatedRequest = cloneDeep(request);
      set(updatedRequest, 'internalAgency.zurich', stringify);

      const err = validateRequestAgency(
        fields,
        groupId,
        name,
        value,
        fieldEditing
      );

      setFields(err);
      setRequest(updatedRequest);
    };

    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Zurich</InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            disabled
            fullWidth
            value=""
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Select
              value={get(parsed, 'OutcomeTrattativa', '')}
              onChange={(e: any) => changeHandler(e, 'OutcomeTrattativa')}
              disabled={!isEditable}
              size="small"
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
            >
              {outcomeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {get(parsed, 'OutcomeTrattativa', '') !== '' && isEditable &&
              <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa')} aria-label="Annulla" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                <CancelIcon fontSize="small" />
              </IconButton>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            value={get(parsed, 'Tasso', '')}
            onChange={(e: any) => changeHandler(e, 'Tasso')}
            disabled={!isEditable}
            size="small"
            error={
              fields.find((field: any) => field.id === groupId)?.[`Tasso`]
                ?.error
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            fullWidth
            value={get(parsed, 'Note', '')}
            onChange={(e: any) => changeHandler(e, 'Note')}
            disabled={!isEditable}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  const renderAssicuratrice = () => {
    const assicuratrice = get(
      request,
      'internalAgency.assicuratrice_milanese',
      ''
    );
    const parsed = parseJson(assicuratrice);
    const groupId = 'assicuratrice_milanese';

    const changeHandler = (e: any, name: string) => {
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      set(parsed, name, value);
      const stringify = JSON.stringify(parsed);
      const updatedRequest = cloneDeep(request);
      set(updatedRequest, 'internalAgency.assicuratrice_milanese', stringify);
      const err = validateRequestAgency(
        fields,
        groupId,
        name,
        value,
        fieldEditing
      );

      setFields(err);
      setRequest(updatedRequest);
    };

    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Assicuratrice Milanese
                </InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            disabled
            fullWidth
            value=""
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Select
              value={get(parsed, 'OutcomeTrattativa', '')}
              onChange={(e: any) => changeHandler(e, 'OutcomeTrattativa')}
              disabled={!isEditable}
              size="small"
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
            >
              {outcomeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {get(parsed, 'OutcomeTrattativa', '') !== '' && isEditable &&
              <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa')} aria-label="Annulla" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                <CancelIcon fontSize="small" />
              </IconButton>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            value={get(parsed, 'Tasso', '')}
            onChange={(e: any) => changeHandler(e, 'Tasso')}
            disabled={!isEditable}
            size="small"
            error={
              fields.find((field: any) => field.id === groupId)?.[`Tasso`]
                ?.error
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            fullWidth
            value={get(parsed, 'Note', '')}
            onChange={(e: any) => changeHandler(e, 'Note')}
            disabled={!isEditable}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  const renderRevo = () => {
    const revo = get(request, 'internalAgency.revo', '');
    const parsed = parseJson(revo);
    const groupId = 'revo';

    const changeHandler = (e: any, name: string) => {
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      set(parsed, name, value);
      const stringify = JSON.stringify(parsed);
      const updatedRequest = cloneDeep(request);
      set(updatedRequest, 'internalAgency.revo', stringify);
      const err = validateRequestAgency(
        fields,
        groupId,
        name,
        value,
        fieldEditing
      );

      setFields(err);
      setRequest(updatedRequest);
    };

    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Revo</InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            disabled
            fullWidth
            value=""
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Select
              value={get(parsed, 'OutcomeTrattativa', '')}
              onChange={(e: any) => changeHandler(e, 'OutcomeTrattativa')}
              disabled={!isEditable}
              size="small"
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
            >
              {outcomeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {get(parsed, 'OutcomeTrattativa', '') !== '' && isEditable &&
              <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa')} aria-label="Annulla" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                <CancelIcon fontSize="small" />
              </IconButton>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            value={get(parsed, 'Tasso', '')}
            onChange={(e: any) => changeHandler(e, 'Tasso')}
            disabled={!isEditable}
            size="small"
            error={
              fields.find((field: any) => field.id === groupId)?.[`Tasso`]
                ?.error
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            fullWidth
            value={get(parsed, 'Note', '')}
            onChange={(e: any) => changeHandler(e, 'Note')}
            disabled={!isEditable}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  const renderTua = () => {
    const tua = get(request, 'internalAgency.tua', '');
    const parsed = parseJson(tua);
    const groupId = 'tua';

    const changeHandler = (e: any, name: string) => {
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      set(parsed, name, value);
      const stringify = JSON.stringify(parsed);
      const updatedRequest = cloneDeep(request);
      set(updatedRequest, 'internalAgency.tua', stringify);
      const err = validateRequestAgency(
        fields,
        groupId,
        name,
        value,
        fieldEditing
      );

      setFields(err);
      setRequest(updatedRequest);
    };

    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Tua</InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            disabled
            fullWidth
            value={''}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Select
              value={get(parsed, 'OutcomeTrattativa', '')}
              onChange={(e: any) => changeHandler(e, 'OutcomeTrattativa')}
              disabled={!isEditable}
              size="small"
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
            >
              {outcomeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {get(parsed, 'OutcomeTrattativa', '') !== '' && isEditable &&
              <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa')} aria-label="Annulla" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                <CancelIcon fontSize="small" />
              </IconButton>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            value={get(parsed, 'Tasso', '')}
            onChange={(e: any) => changeHandler(e, 'Tasso')}
            disabled={!isEditable}
            size="small"
            error={
              fields.find((field: any) => field.id === groupId)?.[`Tasso`]
                ?.error
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: 0,
              },
            }}
            fullWidth
            value={get(parsed, 'Note', '')}
            onChange={(e: any) => changeHandler(e, 'Note')}
            disabled={!isEditable}
            size="small"
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  const renderAltreCompagnie = () => {
    const updatedRequest = cloneDeep(request);
    const defaultAltreCompagnie = [{ ...INTERNAL_AGENCY_DEFAULT }];
    const altreCompagnie =
      get(updatedRequest, 'internalAgency.altre_compagnie', '') ||
      defaultAltreCompagnie;
    const parsedAltre = parseJson(altreCompagnie);

    const changeHandler = (e: any, name: string, id: string | number) => {
      //Field that is being edited, used for knowing what kind of check to do on the value
      const fieldEditing = agencyFields.find(
        (field: any) => field.name === name
      );
      const value = get(e, 'target.value', '');
      const parsedItemIndex = parsedAltre?.findIndex((p: any) => +p.id === +id);
      if (parsedItemIndex !== -1) {
        set(parsedAltre, `[${parsedItemIndex}].${name}`, value);
        const stringify = JSON.stringify(parsedAltre);
        set(updatedRequest, 'internalAgency.altre_compagnie', stringify);

        const err = validateRequestAgency(
          extraFields,
          parsedItemIndex,
          name,
          value,
          fieldEditing,
          true
        );

        setExtraFields(err);

        setRequest(updatedRequest);
      }
    };

    const addAltreCompagnieHandler = (parsed: any) => {
      const updatedRequest = cloneDeep(request);
      const lastItem = parsed[parsed.length - 1];
      const id = +lastItem?.id + 1;
      const updatedDefault = { ...INTERNAL_AGENCY_DEFAULT };
      set(updatedDefault, 'id', id);
      parsed.push(updatedDefault);
      set(
        updatedRequest,
        'internalAgency.altre_compagnie',
        JSON.stringify(parsed)
      );
      setExtraFields([...extraFields, { id: id }]);
      setRequest(updatedRequest);
    };

    const removeItemHandler = (itemId: string | number) => {
      const updatedRequest = cloneDeep(request);
      const updatedParsed = parsedAltre.filter(
        (item: any) => item.id !== itemId
      );
      set(
        updatedRequest,
        'internalAgency.altre_compagnie',
        JSON.stringify(updatedParsed)
      );

      const updatedExtraFields = extraFields.filter(
        (item: any) => item.id != itemId
      );
      setExtraFields([...updatedExtraFields]);
      setRequest(updatedRequest);
    };

    const compagnies = parsedAltre?.map((ac: any, index: number) => {
      return (
        <Grid
          container
          spacing={0}
          justifyContent="flex-start"
          alignItems="center"
          key={`${ac.id}-${index}--`}
        >
          <Grid item xs={12} md={3}>
            <TextField
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
              placeholder="Nome Compagnia"
              disabled={!isEditable}
              fullWidth
              onChange={(e: any) => changeHandler(e, 'name', ac.id)}
              value={get(ac, 'name', '')}
              size="small"
              error={
                extraFields.find((field: any) => field?.id == ac.id)?.['name']
                  ?.error
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Select
                value={get(ac, 'OutcomeTrattativa', '')}
                onChange={(e: any) =>
                  changeHandler(e, 'OutcomeTrattativa', ac.id)
                }
                disabled={!isEditable || !ac.name}
                size="small"
                sx={{
                  '& fieldset': {
                    borderRadius: 0,
                  },
                }}
              >
                {outcomeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {get(ac, 'OutcomeTrattativa', '') !== '' && isEditable &&
                <IconButton onClick={(e: any) => changeHandler(e, 'OutcomeTrattativa', ac.id)} aria-label="Copia link" sx={{ position: 'absolute', right: '1.5rem', height: '100%' }}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              fullWidth
              value={get(ac, 'Tasso', '')}
              onChange={(e: any) => changeHandler(e, 'Tasso', ac.id)}
              disabled={!isEditable || !ac.name}
              size="small"
              error={
                extraFields.find((field: any) => field?.id == ac.id)?.['Tasso']
                  ?.error
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              sx={{
                '& fieldset': {
                  borderRadius: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isEditable && (
                      <Actions
                        onDeleteClick={() => removeItemHandler(ac.id)}
                        showEdit={false}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={get(ac, 'Note', '')}
              onChange={(e: any) => changeHandler(e, 'Note', ac.id)}
              disabled={!isEditable || !ac.name}
              size="small"
              multiline
            />
          </Grid>
        </Grid>
      );
    });

    const hasMissingName = parsedAltre?.some((field: any) => !field?.name);

    const valid = isValid() && !hasMissingName;

    return (
      <div style={{ margin: `0 auto` }}>
        {compagnies}
        {isEditable ? (
          <Grid
            container
            spacing={0}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  alignItems: `center`,
                  color: `rgba(25, 118, 210, 0.65)`,
                  display: `flex`,
                  fontSize: 48,
                  justifyContent: `center`,
                  margin: 24,
                  width: `100%`,
                }}
              >
                <AddBoxRoundedIcon
                  style={{
                    cursor: valid ? 'pointer' : 'not-allowed',
                    opacity: valid ? 1 : 0.5,
                  }}
                  fontSize="inherit"
                  color="inherit"
                  onClick={
                    valid
                      ? () => addAltreCompagnieHandler(parsedAltre)
                      : undefined
                  }
                />
              </div>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  };

  const renderCopieFirmate = () => {
    return (
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <InputLabel id="select-copie-firmate">Copie Firmate</InputLabel>
        <Select
          labelId="select-copie-firmate"
          id="select-copie-firmate"
          value={`${get(request, 'internalAgency.copie_firmate', false)}`}
          label="Copie Firmate"
          onChange={(e: any) =>
            onSelectHandler(e, 'internalAgency.copie_firmate')
          }
          disabled={!isEditable}
        >
          <MenuItem value="false">No</MenuItem>
          <MenuItem value="true">Sì</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderPagamentoPolizza = () => {
    return (
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <InputLabel id="select-pagamento_polizza">Pagamento Polizza</InputLabel>
        <Select
          labelId="select-pagamento_polizza"
          id="select-pagamento_polizza"
          value={`${get(request, 'internalAgency.pagamento_polizza', false)}`}
          label="Pagamento Polizza"
          onChange={(e: any) =>
            onSelectHandler(e, 'internalAgency.pagamento_polizza')
          }
          disabled={!isEditable}
        >
          <MenuItem value="false">No</MenuItem>
          <MenuItem value="true">Sì</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <div
      style={{
        borderRadius: 12,
        margin: `0 24px`,
        padding: `80px 4px`,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={11} marginBottom="40px">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={3}></Grid>
            <Grid item md={6}>
              <Typography variant="h4" align="center">
                <strong>Dati Interni Agenzia</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `flex-end`,
                }}
              >
                {isEditable ? (
                  <>
                    <div style={{ margin: `2px 8px` }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setIsEditable(false);
                          window.location.reload();
                        }}
                      >
                        Annulla
                      </Button>
                    </div>
                    <div style={{ margin: `2px 8px` }}>
                      <Button
                        variant="contained"
                        onClick={() => submit()}
                        disabled={!isValid()}
                      >
                        Salva
                      </Button>
                    </div>
                  </>
                ) : (
                  <div style={{ margin: `2px 8px` }}>
                    <Button
                      variant="outlined"
                      onClick={() => setIsEditable(true)}
                    >
                      Modifica
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
  
        <Grid item xs={11}>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      Numero Polizza
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={get(request, 'internalAgency.numero_polizza', '')}
                onChange={(e: any) =>
                  onChangeHandler(e, 'internalAgency.numero_polizza')
                }
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Premio</InputAdornment>
                  ),
                }}
                fullWidth
                value={get(request, 'internalAgency.premio', '')}
                onChange={(e: any) =>
                  onChangeHandler(e, 'internalAgency.premio')
                }
                disabled={!isEditable}
                error={
                  fields.find((field: any) => field.id === 'details')?.[
                    'internalAgency.premio'
                  ]?.error
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {renderCopieFirmate()}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderPagamentoPolizza()}
            </Grid>
          </Grid>
        </Grid>
  
        <Grid item xs={11}>
          {renderGroupama()}
          {renderZurich()}
          {renderAssicuratrice()}
          {renderRevo()}
          {renderTua()}
          {renderAltreCompagnie()}
        </Grid>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
  
};

export default EditRequestAgency;
