export const parseJson = (obj: any) => {
  if (typeof obj === 'object') {
    return obj;
  }

  if (typeof obj === 'string') {
    try {
      return JSON.parse(obj);
    } catch {
      return {};
    }
  }

  if (typeof obj === 'undefined') return null;

  return {};
};
