import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AlertMessage } from '../../types/form';
import { beneficiarioFields } from '../../types/request';
import { isEmailValid, sanitizeEmail } from '../../utils/email';
import { validateBeneficiario as validate } from '../../utils/validate';

type AddBeneficiarioDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: Object) => void;
};

const AddBeneficiarioDialog = ({
  open,
  onClose,
  onSubmit,
}: AddBeneficiarioDialogProps) => {
  const [fields, setFields] = useState(
    Object.fromEntries(beneficiarioFields.map((field) => [field.id, '']))
  );

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleFieldChange = (fieldId: string, value: string) => {
    setFields((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldId]: value,
    }));
  };

  const handleSubmit = () => {
    const sanitizedEmail = sanitizeEmail(fields.pec);

    const updatedFields = { ...fields, pec: sanitizedEmail };

    onSubmit(updatedFields);
    setFields(
      Object.fromEntries(beneficiarioFields.map((field) => [field.id, '']))
    );
  };

  const handleClose = () => {
    beneficiarioFields.forEach((field) => {
      field.error = false;
      field.helperText = undefined;
    });
    setFields(
      Object.fromEntries(beneficiarioFields.map((field) => [field.id, '']))
    );

    // Reset other states
    setAlertMessage(null);
    setShowAlert(false);

    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Aggiungi nuovo beneficiario</DialogTitle>
      <DialogContent>
        {beneficiarioFields.map((field) => (
          <TextField
            key={field.id}
            label={field.label}
            type={field.type}
            value={fields[field.id]}
            required={field.required}
            error={field.error}
            helperText={field.helperText}
            onChange={(e) =>
              validate(e.target.value, field, () =>
                handleFieldChange(field.id, e.target.value)
              )
            }
            fullWidth
            sx={{ my: 1 }}
          />
        ))}
      </DialogContent>
      <CardActions>
        <Button sx={{ width: '100%' }} variant="outlined" onClick={handleClose}>
          Annulla
        </Button>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            beneficiarioFields.some((field) => field.error) ||
            beneficiarioFields.some(
              (field) =>
                field.required && (!fields[field.id] || fields[field.id] === '')
            )
          }
        >
          Aggiungi
        </Button>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddBeneficiarioDialog;
