import { useContext, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  Button,
  Alert,
  Snackbar,
  Tooltip,
  IconButton,
  Card,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { createInitialSubmission } from '../../models/request';
import { authContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { AlertMessage } from '../../types/form';
import SelectContraente from '../../components/GenerateLink/Broker/SelectContraente';
import { EXTRA_DOCUMENT_REQUEST, REQUEST_STATUS } from '../../utils/constants';
import { InitialSubmissionDTO } from '../../types/request';
import { generateGuestLink } from '../../utils/common';

const GenerateLinkPage = () => {
  const { user } = useContext(authContext);
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [request, setRequest] = useState<any>({
    userId: user?.id,
    status: REQUEST_STATUS.DRAFT_BROKER,
    contraentes: [],
    is_ati: false,
    submissionTypeId: '',
    submissionTypeSubTypeId: '',
    fieldValues: [],
    documents: [],
    extraDocuments: [EXTRA_DOCUMENT_REQUEST()],
    selectedFiles: [],
  });

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [generatedLinkId, setGeneratedLinkId] = useState<string>('');
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<any>({
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
  });

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCopyToClipboard = () => {
    if (generatedLinkId) {
      navigator.clipboard.writeText(generatedLinkId);
      setOpenTooltip(true);
      setLinkCopied(true);
      setTimeout(() => setOpenTooltip(false), 2000);
    }
  };

  const handleSend = async () => {
    setLoading(true);

    const payload: InitialSubmissionDTO = {
      userId: request.userId,
      status: request.status,
      contraentes: request.contraentes,
      is_ati: request.is_ati,
    };

    try {
      const res = await createInitialSubmission(payload);

      if (res.status === 201 && res?.data?.id) {
        setGeneratedLinkId(generateGuestLink(res?.data?.id));
        setAlertMessage({
          severity: 'success',
          message: 'Link creato con successo.',
        });
      } else {
        throw new Error('Unable to send new User request');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante la creazione del link',
      });
    } finally {
      setLoading(false);
      setShowAlert(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: '100px 20px',
      }}
    >
      <Typography variant="h3" component="h1" align="center">
        Genera Link
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <SelectContraente
            request={request}
            setRequest={setRequest}
            isValid={isFormValid}
            setIsValid={setIsFormValid}
          />
        </Grid>

        {generatedLinkId && (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <Card
              sx={{
                padding: '16px',
                boxShadow: 'none',
                border: '1px solid #BDBDBD',
                backgroundColor: 'var(--clr-bg-grey)',
              }}
            >
              <Typography variant="h6" component="h6">
                {generatedLinkId}
              </Typography>
            </Card>
            <Tooltip
              title={
                linkCopied ? 'Link copiato!' : 'Clicca qui per copiare il link'
              }
              open={openTooltip || (!linkCopied && !!generatedLinkId)}
            >
              <IconButton onClick={handleCopyToClipboard}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '40px 0',
            }}
          >
            <Button variant="outlined" onClick={() => navigate('/')} sx={{opacity: loading || !generatedLinkId ? '1' : '0'}}>
              Annulla
            </Button>

            <Button
              variant="contained"
              onClick={handleSend}
              disabled={
                loading ||
                !!generatedLinkId ||
                !isFormValid[`step_${activeStep}`]
              }
            >
              Ottieni link
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default GenerateLinkPage;
