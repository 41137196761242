import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/admin/incorso');
  }, []);

  return <div>AdminHome</div>;
};

export default AdminHome;
