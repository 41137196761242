import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Grid,
} from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { userListRequests } from '../../models/request';
import { RequestType, Status } from '../../types/request';
import Actions from '../Actions';
import DeleteModal from '../Request/DeleteModal';
import UpdateStatusModal from '../Request/UpdateStatusModal';
import WeekendTwoToneIcon from '@mui/icons-material/WeekendTwoTone';
import { MACRO_STATUS, REQUEST_STATUS } from '../../utils/constants';

const PER_PAGE = 20;

type AdminRequestsTableProps = {
  selectedStatus: Status | null;
  searchTerm?: string;
  triggerSearch?: boolean;
};

const AdminRequestsTable = ({
  selectedStatus,
  searchTerm,
  triggerSearch,
}: AdminRequestsTableProps) => {
  const [requests, setRequests] = useState<any>([]);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] =
    useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<RequestType | null>(
    null
  );
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const navigate = useNavigate();
  const { id: urlEndPoint } = useParams();

  const fetchRequests = async () => {
    let queryEndPoint = '';
    if (urlEndPoint === MACRO_STATUS.TrattativeInCorso.endPoint) {
      queryEndPoint = MACRO_STATUS.TrattativeInCorso.id;
    } else if (urlEndPoint === MACRO_STATUS.TrattativeConcluse.endPoint) {
      queryEndPoint = MACRO_STATUS.TrattativeConcluse.id;
    }

    const query =
      !selectedStatus?.id || selectedStatus?.id === 'all'
        ? queryEndPoint
        : selectedStatus?.id;
    try {
      const res = await userListRequests(query, page, PER_PAGE, searchTerm);
      if (res.status === 200) {
        setRequests(res.data.data);
        setTotalPages(res.data.total_pages);
      } else {
        console.error('Unable to retrieve the requests.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving the requests.');
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [selectedStatus, urlEndPoint, page]);

  useEffect(() => {
    setPage(1);
    fetchRequests();
  }, [triggerSearch]);

  const handleShortcut = (request: RequestType) => {
    setSelectedRequest(request);
    setShowUpdateStatusDialog(true);
  };

  const handleEdit = (requestId: string) => {
    navigate(`/admin/edit/${requestId}`);
  };

  const handleDelete = (request: RequestType) => {
    setSelectedRequest(request);
    setIsDelete(true);
  };
  const closeStatusDialog = () => {
    setSelectedRequest(null);
    setShowUpdateStatusDialog(false);
  };
  const closeDeleteDialog = () => {
    setSelectedRequest(null);
    setIsDelete(false);
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  return isEmpty(requests) ? (
    <Grid container gap={2} justifyContent="center">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          opacity: 0.7,
          marginTop: 18,
        }}
      >
        <div style={{ fontSize: 24 }}>
          <span>Nessun dato</span>
        </div>
        <div style={{ fontSize: 84 }}>
          <WeekendTwoToneIcon fontSize="inherit" />
        </div>
      </div>
    </Grid>
  ) : (
    <Grid container gap={2} justifyContent="end">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contraente</TableCell>
              <TableCell>Tipologia</TableCell>
              <TableCell>Beneficiario</TableCell>
              <TableCell>Importo da garantire</TableCell>
              <TableCell>Data scadenza provvisoria</TableCell>
              <TableCell>Inserimento</TableCell>
              <TableCell>Ultimo aggiornamento</TableCell>
              <TableCell>Intermediario</TableCell>
              <TableCell>Stato</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests?.map((request: any) => (
              <TableRow key={request?.id}>
                <TableCell>{request?.mandataria?.ragione_sociale}</TableCell>
                <TableCell>{request?.submission_type?.name}</TableCell>
                <TableCell>{request?.beneficiario_ragione_sociale}</TableCell>
                <TableCell>{request?.importo_da_garantire}</TableCell>
                <TableCell>
                  {request?.termine_presentazione_offerte
                    ? moment(request?.termine_presentazione_offerte).format(
                        'DD/MM/yyyy'
                      )
                    : ''}
                </TableCell>
                <TableCell>
                  {request?.created
                    ? moment(request?.created).format('DD/MM/yyyy')
                    : ''}
                </TableCell>
                <TableCell>
                  {request?.updated
                    ? moment(request?.updated).format('DD/MM/yyyy')
                    : ''}
                </TableCell>
                <TableCell>{request?.user?.fullname}</TableCell>
                <TableCell>{request?.status?.name}</TableCell>
                <TableCell align="center">
                  <Actions
                    onShortcutClick={() => handleShortcut(request)}
                    onEditClick={() => handleEdit(request?.id)}
                    onDeleteClick={() => handleDelete(request)}
                    showShortcut={
                      request?.status?.id ===
                      REQUEST_STATUS.DA_PRENDERE_IN_CARICO
                    }
                    showEdit={
                      request?.status?.id !== REQUEST_STATUS.DRAFT_ADMIN
                    }
                    showGenerateLink={
                      request?.status?.id === REQUEST_STATUS.DRAFT_ADMIN
                    }
                    requestId={request?.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        color="primary"
        count={totalPages || 1}
        page={page}
        onChange={handlePageChange}
      />
      {showUpdateStatusDialog && (
        <UpdateStatusModal
          open={showUpdateStatusDialog}
          onClose={closeStatusDialog}
          request={selectedRequest}
          refreshFn={fetchRequests}
        />
      )}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          onClose={closeDeleteDialog}
          request={selectedRequest}
          refreshFn={fetchRequests}
        />
      )}
    </Grid>
  );
};

export default AdminRequestsTable;
