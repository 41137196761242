import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useState } from 'react';
import AppBar from '../AppBar';
import Menu from '../Menu';

const Layout = ({ children }: any) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getDrawerWidth = () => {
    return collapsed ? 100 : 240;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        handleDrawerToggle={handleDrawerToggle}
        getDrawerWidth={getDrawerWidth}
      />
      <Box
        component="nav"
        sx={{
          width: { sm: getDrawerWidth() },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240,
            },
          }}
        >
          <Menu />
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              backgroundColor: 'var(--clr-bg-grey)',
            },
          }}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: getDrawerWidth(),
              borderRight: '1px solid var(--clr-main-blue)'
            },
          }}
          open
        >
          <Menu collapsed={collapsed} style={{ paddingBottom: '50px' }} />
          <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <ListItem key="collapse" disablePadding>
              <ListItemButton onClick={() => setCollapsed(!collapsed)}>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  {collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
      {/* <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => (window.location.href = "/")}
      > */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          width: { sm: `calc(100% - ${getDrawerWidth()}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
      {/* </ErrorBoundary> */}
    </Box>
  );
};

export default Layout;
