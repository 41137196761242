import api from './api';

export type UserDTO = {
  id: string;
  email: string;
  role: string;
  status?: string;
  fullname?: string;
};

export const me = () => {
  return api.get('/user/me');
};

export const listUsers = async (page = 1, take = 20) => {
  try {
    return await api.get('/user', {
      params: {
        page,
        take,
      },
    });
  } catch (error) {
    throw new Error('An error occurred while retrieving the list of users');
  }
};

export const inviteUser = async (body: any) => {
  try {
    return await api.post('/user', body);
  } catch (error) {
    throw new Error('An error occurred while inviting the user');
  }
};

export const editUser = async (id: string, body: any) => {
  try {
    return await api.put(`/user/${id}`, body);
  } catch (error) {
    throw new Error('An error occurred while editing the user');
  }
};

export const deleteUser = async (id: string) => {
  try {
    return await api.delete(`/user/${id}`);
  } catch (error) {
    throw new Error('An error occurred while deleting the user');
  }
};

export const getBrokerList = async (
  q?: string,
  page?: number,
  perPage: number = 10
) => {
  try {
    const params = {
      q,
      page,
      perPage,
    };
    return await api.get('/user/brokers', { params });
  } catch (error) {
    throw new Error('An error occurred while retrieving the list of Brokers');
  }
};
