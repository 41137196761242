import { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  Button,
  Alert,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';

import UserRequestFields from '../../components/Request/RequestFields';
import RequestType from '../../components/Request/RequestType';
import { finalizeSubmission, getInitialSubmission } from '../../models/request';
import BeneficiarioData from '../../components/Request/BeneficiarioData';
import { authContext } from '../../context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertMessage } from '../../types/form';
import { cloneDeep, get, omit, set } from 'lodash';
import Recap from '../../components/Request/Recap';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import {
  API_RESPONSE_TYPE,
  EXTRA_DOCUMENT_REQUEST,
} from '../../utils/constants';
import SelectContraente from '../../components/GenerateLink/Guest/SelectContraente';

const steps = [
  { label: 'Contraente' },
  { label: 'Beneficiario' },
  { label: 'Tipologia richiesta' },
  { label: 'Dati richiesta' },
  { label: 'Riepilogo' },
];

const FinalSubmissionPage = () => {
  const { user } = useContext(authContext);
  const { id: requestId = '' } = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);

  const [request, setRequest] = useState<any>({
    userId: user?.id,
    status: 'pending',
    contraentes: [],
    is_ati: false,
    submissionTypeId: '',
    submissionTypeSubTypeId: '',
    fieldValues: [],
    documents: [],
    extraDocuments: [EXTRA_DOCUMENT_REQUEST()],
    selectedFiles: [],
  });

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [missingDocs, setMissingDocs] = useState<boolean>(false);
  const [isPageVisible, setIsPageVisible] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<any>({
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
  });

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const navigate = useNavigate();

  // Confirmation dialog for missing documents
  const handleConfirm = () => {
    setActiveStep(activeStep + 1);
  };

  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleNext = () => {
    if (missingDocs && activeStep === 4) {
      setOpenConfirmation(true);
    } else setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSend = async () => {
    setLoading(true);
    const updatedRequest = cloneDeep(request);
    const updatedSubmissionTypeId =
      get(updatedRequest, 'submissionTypeSubTypeId', '') ||
      get(updatedRequest, 'submissionTypeId', '');
    set(updatedRequest, 'submissionTypeId', updatedSubmissionTypeId);
    set(updatedRequest, 'fields', get(updatedRequest, 'fieldValues', []));
    const updatedDocuments = updatedRequest.documents.map((d: any) =>
      omit(d, ['tempId'])
    );
    set(updatedRequest, 'documents', updatedDocuments);

    const payload = omit(updatedRequest, [
      'fieldValues',
      'submissionTypeSubTypeId',
      'selectedFiles',
      'extraDocuments',
    ]);

    try {
      const res = await finalizeSubmission(requestId, payload);

      if (res.status === 201) {
        setAlertMessage({
          severity: 'success',
          message: 'Richiesta inviata con successo.',
        });
        setShowAlert(true);
      } else {
        throw new Error('Unable to send new User request');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: "Errore durante l'invio della richiesta.",
      });
      setShowAlert(true);
    }

    setTimeout(() => {
      navigate('/');
    }, 2000);
  };

  useEffect(() => {
    const fetchInitialSubmission = async () => {
      try {
        const res = await getInitialSubmission(requestId);
        if (res.status === 200) {
          setIsPageVisible(true);
          // Hide page if the submission has been already completed
          if (res?.data?.status === API_RESPONSE_TYPE.SUBMISSION_COMPLETED) {
            setIsPageVisible(false);
            setTimeout(() => {
              navigate('/');
            }, 3000);
          } else {
            const initialData = res.data;
            const updatedContraenti = initialData?.contraenti?.map(
              (contraenteItem: any) => {
                return {
                  ...contraenteItem.contraente,
                  is_mandataria: contraenteItem.is_mandatario,
                };
              }
            );
            setRequest({
              ...request,
              status: res.data.status.id,
              is_ati: initialData.is_ati,
              contraentes: updatedContraenti,
              userId: res.data.user.id
            });
          }
        }
      } catch (error) {
        console.error('Error fetching initial submission:', error);
      }
    };

    if (requestId) {
      fetchInitialSubmission();
    }
  }, [requestId]);

  return isPageVisible ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: '100px 20px',
      }}
    >
      <Typography variant="h3" component="h1" align="center">
        Censimento richiesta
      </Typography>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <Stepper activeStep={activeStep - 1} sx={{ margin: '80px' }}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        {activeStep === 1 && (
          <SelectContraente
            request={request}
            setRequest={setRequest}
            isValid={isFormValid}
            setIsValid={setIsFormValid}
          />
        )}

        {activeStep === 2 && (
          <BeneficiarioData
            request={request}
            setRequest={setRequest}
            isValid={isFormValid}
            setIsValid={setIsFormValid}
          />
        )}

        {activeStep === 3 && (
          <RequestType
            request={request}
            setRequest={setRequest}
            isValid={isFormValid}
            setIsValid={setIsFormValid}
          />
        )}
        {activeStep === 4 && (
          <UserRequestFields
            request={request}
            setRequest={setRequest}
            isValid={isFormValid}
            setIsValid={setIsFormValid}
            setMissingDocs={setMissingDocs}
          />
        )}

        {activeStep === 5 && <Recap request={request} />}

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ margin: '40px 0' }}
        >
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {activeStep === 1 ? (
                <Button variant="outlined" onClick={() => navigate('/')}>
                  Annulla
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleBack}>
                  Indietro
                </Button>
              )}

              {activeStep === steps.length ? (
                <Button
                  variant="contained"
                  onClick={handleSend}
                  disabled={loading}
                >
                  Invia
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={!isFormValid[`step_${activeStep}`]}
                >
                  Avanti
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {openConfirmation && (
        <ConfirmationDialog
          title="Documenti mancanti"
          message="Non risultano caricati alcuni documenti necessari alla valutazione, procedere comunque nella richiesta?"
          confirmText="Sì"
          cancelText="Annulla"
          onConfirm={handleConfirm}
          open={openConfirmation}
          onClose={closeConfirmation}
        />
      )}

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h3" component="h3" align="center">
        La richiesta non è più accessibile
      </Typography>
    </Box>
  );
};

export default FinalSubmissionPage;
