import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Contraente, ContraenteField, RequestField } from '../types/request';

/**
 * Validates a field based on its value and the check defined on it
 *
 * @param value
 * @param field
 * @param isPersonaGiuridica
 */
const validateContraente = (
  value: any,
  field: any, // ContraenteField,
  isPersonaGiuridica?: boolean
) => {
  if (field?.required) {
    if (!value) {
      field.error = true;
    } else {
      field.error = false;
    }
  }

  //Extracting pattern based on Contraente type (Persona Fisica or Giuridica)
  if (field?.check?.pattern) {
    let pattern = field?.check?.pattern;

    if (isPersonaGiuridica !== undefined) {
      pattern = isPersonaGiuridica
        ? field.check.pattern
        : field.check.pattern_2;
    }

    if (!pattern.test(value) && !(!value && !field?.required)) {
      field.error = true;
    } else {
      field.error = false;
    }
  }

  if (field.error && field?.check?.helperText) {
    let helperText = field?.check?.helperText;

    if (isPersonaGiuridica !== undefined) {
      helperText = isPersonaGiuridica
        ? field.check.helperText
        : field.check.helperText_2;
    }

    field.helperText = helperText;
  } else {
    field.helperText = null;
  }
};

/**
 * Validates a field based on its value and the check defined on it
 * The callback is for adding a new Beneficiario
 *
 * @param value
 * @param field
 * @param callback
 *
 */
const validateBeneficiario = (value: any, field: any, callback?: Function) => {
  if (field?.required) {
    if (!value) {
      field.error = true;
    } else {
      field.error = false;
    }
  }

  if (field?.check?.pattern) {
    let pattern = field?.check?.pattern;

    if (!pattern.test(value) && !(!value && !field?.required)) {
      field.error = true;
    } else {
      field.error = false;
    }
  }

  if (field.error && !value) {
    field.helperText = 'Questo campo è obbligatorio';
  } else if (field.error && field?.check?.helperText) {
    field.helperText = field?.check?.helperText;
  } else {
    field.helperText = null;
  }
  if (callback) callback();
};

/**
 * Validates a field based on its value and the check defined on it
 * This handles the specific case where the React state needs to me changed
 *
 * @param request
 * @param field
 * @param fields
 * @param setFields
 *
 */

const validateField = (
  request: any,
  field: RequestField,
  fields: RequestField[],
) => {
  const updatedField = { ...field };
  updatedField.helperText = null;
  updatedField.error = false;

  //Field object holding the data
  const fieldObj =
    request.fieldValues?.find((f: any) => f.id === field.id) || null;

  const value = fieldObj?.value || '';

  if (updatedField.required && !value.replace(/[.]/g, '').replace(/,/g, ".")) {
    updatedField.helperText = 'Questo campo è obbligatorio';
    updatedField.error = true;
  } else if (!updatedField.required && !value) {
    //Doesn't show error if the field is not required and empty
  } else if (updatedField.check?.type === 'numberGreaterThanZero') {
    // Replace commas with periods to standardize the decimal separator
    const standardizedValue = value.replace(/[.]/g, '').replace(/,/g, ".");
    const numericValue = Number(standardizedValue);

    if (numericValue <= 0|| value.trim() !== value) {
      updatedField.helperText = 'Il valore deve essere maggiore di zero';
      updatedField.error = true;
    } else if( isNaN(numericValue)){
      updatedField.helperText = `Inserire un numero valido`;
      updatedField.error = true;
    }
  } else if (
    updatedField.check?.type === 'percentage' &&
    (Number(value) < 0 ||
      Number(value) > 100 ||
      isNaN(Number(value)) ||
      value.trim() !== value)
  ) {
    updatedField.helperText = 'Il valore deve essere compreso tra 0 e 100';
    updatedField.error = true;
  } else if (updatedField.check?.type === 'currentDate') {
    const isValidDate = moment(value, 'YYYY-MM-DD', true).isValid();
    const isAfterOrEqual = moment(value).isSameOrAfter(moment(), 'day');
    if (!isValidDate || !isAfterOrEqual) {
      updatedField.helperText =
        'Inserire una data uguale o successiva alla data odierna';
      updatedField.error = true;
    }
  } else if (updatedField.check?.type === 'endDate') {
    const startDateField = request.fieldValues?.find(
      (f: any) => f.id === updatedField?.conditional?.fieldId
    );
    const isValidStartDate = moment(
      startDateField?.value,
      'YYYY-MM-DD',
      true
    ).isValid();
    const isValidEndDate = moment(value, 'YYYY-MM-DD', true).isValid();
    const endDateValue = value;

    if (isValidStartDate && isValidEndDate) {
      const isAfterOrEqual = moment(endDateValue).isSameOrAfter(
        moment(startDateField?.value)
      );
      if (!isAfterOrEqual) {
        updatedField.helperText = `La data deve essere uguale o successiva alla data immessa precedentemente`;
        updatedField.error = true;
      }
    } else {
      // Handle invalid start or end date case
      updatedField.helperText = `La data immessa non è valida`;
      updatedField.error = true;
    }
  } else if (updatedField.check?.type === 'greaterThan') {
    const parentField = request.fieldValues?.find(
      (f: any) => f.id === updatedField?.conditional?.fieldId
    );
    // Remove any existing commas or symbols from the string and convert it to a number
    const parentFieldValue = Number(parentField?.value.replace(/[.]/g, '').replace(/,/g, "."));
    const childFieldValue = Number(value.replace(/[.]/g, '').replace(/,/g, "."));

    if (childFieldValue < parentFieldValue) {
      updatedField.helperText = `Inserire un numero maggiore dell'Importo da garantire`;
      updatedField.error = true;
    } else if (isNaN(childFieldValue) || isNaN(parentFieldValue)){
      updatedField.helperText = `Inserire un numero valido`;
      updatedField.error = true;
    }
  }

  const updatedFields = fields.map((field: RequestField) => {
    if (field.id === updatedField.id) {
      // Update the field object here
      return updatedField;
    } else {
      // Return the original field object for all other fields
      return field;
    }
  });
  return updatedFields
};

/**
 * Validates a field based on its value and the check defined on it
 * It is specific for the EditRequest component
 *
 * @param request
 * @param field
 * @param fields
 * @param setFields
 *
 */

const validateFieldEdit = (
  request: any,
  field: RequestField,
  fields: RequestField[],
) => {
  const updatedField = { ...field };
  updatedField.helperText = null;
  updatedField.error = false;

  //Field object holding the data
  const fieldObj =
    request.fields?.find((f: any) => f.field.id === field.id) || null;

  const value = fieldObj?.value.replace(/[.]/g, '').replace(/,/g, ".") || '';

  if (updatedField.required && !value) {
    updatedField.helperText = 'Questo campo è obbligatorio';
    updatedField.error = true;
  } else if (!updatedField.required && !value) {
    //Doesn't show error if the field is not required and empty
  } else if (updatedField.check?.type === 'numberGreaterThanZero') {
    // Replace commas with periods to standardize the decimal separator
    const standardizedValue = value.replace(/[.]/g, '').replace(/,/g, ".");
    const numericValue = Number(standardizedValue);

    if (numericValue <= 0 || isNaN(numericValue) || value.trim() !== value) {
      updatedField.helperText = 'Il valore deve essere maggiore di zero';
      updatedField.error = true;
    }
  } else if (
    updatedField.check?.type === 'percentage' &&
    (Number(value) < 0 ||
      Number(value) > 100 ||
      isNaN(Number(value)) ||
      value.trim() !== value)
  ) {
    updatedField.helperText = 'Il valore deve essere compreso tra 0 e 100';
    updatedField.error = true;
  } else if (updatedField.check?.type === 'currentDate') {
    const isValidDate = moment(value, 'YYYY-MM-DD', true).isValid();
    const isAfterOrEqual = moment(value).isSameOrAfter(moment(), 'day');
    if (!isValidDate || !isAfterOrEqual) {
      updatedField.helperText =
        'Inserire una data uguale o successiva alla data odierna';
      updatedField.error = true;
    }
  } else if (updatedField.check?.type === 'endDate') {
    const startDateField = request.fields?.find(
      (f: any) => f?.field?.id === updatedField?.conditional?.fieldId
    );
    const isValidStartDate = moment(
      startDateField?.value,
      'YYYY-MM-DD',
      true
    ).isValid();
    const isValidEndDate = moment(value, 'YYYY-MM-DD', true).isValid();
    const endDateValue = value;

    if (isValidStartDate && isValidEndDate) {
      const isAfterOrEqual = moment(endDateValue).isSameOrAfter(
        moment(startDateField?.value)
      );
      if (!isAfterOrEqual) {
        updatedField.helperText = `La data deve essere uguale o successiva alla data immessa precedentemente`;
        updatedField.error = true;
      }
    } else {
      // Handle invalid start or end date case
      updatedField.helperText = `La data immessa non è valida`;
      updatedField.error = true;
    }
  } else if (updatedField.check?.type === 'greaterThan') {
    const parentField = request.fields?.find(
      (f: any) => f?.field?.id === updatedField?.conditional?.fieldId
    );
    const parentFieldValue = Number(parentField?.value.replace(/[.]/g, '').replace(/,/g, "."));
    const childFieldValue = Number(value.replace(/[.]/g, '').replace(/,/g, "."));

    if (childFieldValue < parentFieldValue) {
      updatedField.helperText = `Inserire un numero maggiore dell'Importo da garantire`;
      updatedField.error = true;
    } else if (isNaN(childFieldValue) || isNaN(parentFieldValue)){
      updatedField.helperText = `Inserire un numero valido`;
      updatedField.error = true;
    }
  }

  const updatedFields = fields.map((field: RequestField) => {
    if (field.id === updatedField.id) {
      // Update the field object here
      return updatedField;
    } else {
      // Return the original field object for all other fields
      return field;
    }
  });

  return updatedFields;
};

/**
 * Validates a field and returns the errors
 *
 * @param fields
 *
 *
 */

const validateRequestAgency = (
  fields: any,
  identifier: string,
  name: string,
  value: string,
  field?: any,
  isExtraField?: boolean
) => {
  let error = false;
  debugger
  const fieldsCopy = cloneDeep(fields);

  if (!field?.check) return fieldsCopy;

  if (field.required && !value) {
    error = true;
  }

  if (
    field.check.type === 'percentage' &&
    (Number(value) < 0 ||
      Number(value) > 100 ||
      isNaN(Number(value)) ||
      value.trim() !== value)
  ) {
    error = true;
  } else if (
    field.check.type === 'number' &&
    (isNaN(Number(value.replace(/[.]/g, '').replace(/,/g, "."))))
  ) {
    error = true;
  }

  const index = isExtraField
    ? identifier
    : fieldsCopy.findIndex((field: any) => field.id == identifier);

  if (index >= 0) {
    fieldsCopy[index] = {
      id: fieldsCopy[index]?.id || identifier,
      ...fieldsCopy[index],
      [`${name}`]: { error },
    };
  } else {
    fieldsCopy.push({ id: identifier, [`${name}`]: { error } });
  }

  return fieldsCopy;
};

export {
  validateContraente,
  validateBeneficiario,
  validateField,
  validateFieldEdit,
  validateRequestAgency,
};
