import {
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  CardActions,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { editUser, UserDTO } from '../../models/user';
import { AlertMessage } from '../../types/form';
import { isEmailValid, sanitizeEmail } from '../../utils/email';

type EditUserDialogProps = {
  user: UserDTO | null;
  open: boolean;
  onClose: () => void;
  refresh: () => void;
};

const EditUserDialog = ({
  user,
  open,
  onClose,
  refresh,
}: EditUserDialogProps) => {
  const [name, setName] = useState(user?.fullname || '');
  const [email, setEmail] = useState(user?.email || '');
  const [role, setRole] = useState(user?.role || '');
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isUserEdited, setIsUserEdited] = useState<boolean>(false);

  const handleSave = async () => {
    // Validate and sanitize the email input
    const sanitizedEmail = sanitizeEmail(email);
    if (!isEmailValid(sanitizedEmail)) {
      setAlertMessage({
        severity: 'warning',
        message: 'Indirizzo email non valido',
      });
      setShowAlert(true);

      return;
    }

    const res = await editUser(user!.id, {
      email,
      role,
      fullname: name,
    });

    if (res.status === 200) {
      setAlertMessage({
        severity: 'success',
        message: 'Utente modificato con successo.',
      });
      setShowAlert(true);
      setIsUserEdited(true);
      setTimeout(() => {
        onClose();
        refresh();
      }, 1500);
    } else {
      setAlertMessage({
        severity: 'warning',
        message: "Non è stato possibile modificare l'utente.",
      });
      setShowAlert(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle align="center">Modifica utente</DialogTitle>

      <DialogContent>
        <CardContent>
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-name">
              Ragione sociale / Nome Cognome
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-name"
              type="text"
              label="Ragione sociale / Nome Cognome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
            <InputLabel id="select-role-label">Ruolo</InputLabel>
            <Select
              labelId="select-role-label"
              id="select-role"
              value={role}
              label="Ruolo"
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value="broker">Utente</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </CardContent>

        <CardActions>
          <Button sx={{ width: '100%' }} variant="outlined" onClick={onClose}>
            Annulla
          </Button>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={() => handleSave()}
            disabled={!email || !name || !role || isUserEdited}
          >
            Salva
          </Button>
        </CardActions>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDialog;
