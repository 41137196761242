import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useState } from 'react';
import { generateGuestLink } from '../../utils/common';

type ActionsProps = {
  onShortcutClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  showGenerateLink?: boolean;
  showShortcut?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  requestId?: string;
  alignment?: string;
};

const Actions = ({
  onShortcutClick,
  onEditClick,
  onDeleteClick,
  showGenerateLink = false,
  showShortcut = false,
  showEdit = true,
  showDelete = true,
  requestId,
  alignment = 'end',
}: ActionsProps) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleGenerateLink = () => {
    if (requestId) {
      const link = generateGuestLink(requestId);
      navigator.clipboard.writeText(link);
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
    if (linkCopied) {
      setTimeout(() => setLinkCopied(false), 3000);
    }
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: alignment }}>
      {showGenerateLink && (
        <Tooltip
          title={linkCopied ? 'Link copiato' : tooltipOpen ? 'Copia link' : ''}
          placement="top"
          open={tooltipOpen || linkCopied}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
        >
          <IconButton onClick={handleGenerateLink} aria-label="Copia link">
            <IosShareIcon />
          </IconButton>
        </Tooltip>
      )}
      {showShortcut && (
        <Tooltip title="Prendi in carico" placement="top">
          <IconButton onClick={onShortcutClick} aria-label="Prendi in carico">
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {showEdit && (
        <Tooltip title="Vedi" placement="top">
          <IconButton onClick={onEditClick} aria-label="edit">
            <VisibilityIcon style={{ color: 'var(--clr-main-blue)' }} />
          </IconButton>
        </Tooltip>
      )}
      {showDelete && (
        <Tooltip title="Elimina" placement="top">
          <IconButton onClick={onDeleteClick} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Actions;
