import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type ConfirmationDialogProps = {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
};

const ConfirmationDialog = ({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  open,
  onClose,
}: ConfirmationDialogProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: '100%' }} onClick={onClose} variant="outlined">
          {cancelText}
        </Button>
        <Button sx={{ width: '100%' }} onClick={handleConfirm} variant="contained" autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
