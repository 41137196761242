import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { AlertMessage } from '../../types/form';
import { exportSubmissions, getListOfStatus } from '../../models/request';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { authContext } from '../../context/auth';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { isDate } from 'moment';
import LoadingButton from '../Loading/button';
import * as downloadjs from 'downloadjs';

type ExportModalProps = {
  open: boolean;
  onClose: () => void;
};

interface IExportBody {
  from?: string | Date;
  to?: string | Date;
}

const ExportModal = ({ open, onClose }: ExportModalProps) => {
  const { user } = useContext(authContext);
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const fromHandler = (val: any) => setFrom(val);
  const toHandler = (val: any) => setTo(val);
  const postExport = async (body: IExportBody) => {
    const success = [200, 201];
    try {
      const res = await exportSubmissions(body);
      if (!success.includes(res.status)) {
        throw new Error('Unable to export requests');
      }
      const formatted = new Date().toISOString().split('T')[0];
      const filename = `richiesta-${formatted}.xlsx`;
      // @ts-ignore
      downloadjs(res.data, filename);
    } catch (error) {
      console.error(error);
      setAlertMessage({
        severity: 'error',
        message: `Errore durante l'upload dei file`,
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const f = moment(from).toDate();
    const t = moment(to).endOf('day').toDate();
    if (!isDate(f) || !isDate(t)) {
      setAlertMessage({
        severity: 'error',
        message: 'Data non valida',
      });
      setShowAlert(true);
      return setLoading(false);
    }
    if (!moment(t).isSameOrAfter(f)) {
      setAlertMessage({
        severity: 'error',
        message: 'Data non valida',
      });
      setShowAlert(true);
      return setLoading(false);
    }
    const payload = {
      from: f,
      to: t,
    };
    await postExport(payload);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Richiesta di esportazione</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          <div style={{ margin: `12px 4px` }}>
            <DatePicker
              sx={{
                width: `100%`,
              }}
              label="Data inizio"
              onChange={fromHandler}
            />
          </div>
          <div style={{ margin: `12px 4px` }}>
            <DatePicker
              sx={{
                width: `100%`,
              }}
              label="Data fine"
              onChange={toHandler}
            />
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Esporta
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ExportModal;
