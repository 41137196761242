import { useState, useEffect } from 'react';
import { Autocomplete, TextField, Grid } from '@mui/material';
import { getBrokerList } from '../../../models/user';

const PER_PAGE = 3;

type SelectBroker = {
  onBrokerSelect: (brokerId: string) => void;
};

const SelectBroker = ({ onBrokerSelect }: SelectBroker) => {
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);

  useEffect(() => {
    fetchBrokerList('');
  }, []);

  const fetchBrokerList = async (query: string) => {
    try {
      const res = await getBrokerList(query, undefined, PER_PAGE);
      if (res.status === 200) {
        setBrokers(res.data.data);
      }
    } catch (error) {
      console.error(`Error fetching brokers: ${error}`);
    }
  };

  const handleBrokerChange = (_: any, value: any) => {
    setSelectedBroker(value);
    onBrokerSelect(value?.id || '');
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        options={brokers}
        getOptionLabel={(option: any) => option.fullname || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedBroker}
        onChange={handleBrokerChange}
        onInputChange={(_, value) => fetchBrokerList(value)}
        renderInput={(params) => (
          <TextField {...params} label="Scegli Broker" variant="outlined" />
        )}
      />
    </Grid>
  );
};

export default SelectBroker;
