import api from './api';

const login = (body: any) => {
  return api.post('/auth/login', body).then((res: any) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
    localStorage.setItem('token', res.data.authorization);
    localStorage.setItem('refresh', res.data.refresh);
    return res;
  });
};

const refresh = (refresh: string) => {
  return api.post('/auth/refresh', { token: refresh });
};

const forgotPassword = async (body: any) => {
  return await api.post('/auth/forgot-password', body);
};

const resetPassword = async (body: any) => {
  return await api.post('/auth/reset-password', body);
};

export { login, refresh, forgotPassword, resetPassword };
