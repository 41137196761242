import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { cloneDeep, constant, get, set } from 'lodash';
import { useEffect, useState } from 'react';
import { getDocuments, getFields, uploadFile } from '../../models/request';
import { AlertMessage } from '../../types/form';
import { Doc, RequestField } from '../../types/request';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  EXTRA_DOCUMENT_ID,
  EXTRA_DOCUMENT_REQUEST,
} from '../../utils/constants';
import { validateField } from '../../utils/validate';
import DropZone from '../DropZone';

const STEP = 'step_4';

type RequestFieldsProps = {
  request: any;
  setRequest: Function;
  isValid: any;
  setIsValid: Function;
  setMissingDocs: any;
};

const UserRequestFields = ({
  request,
  setRequest,
  isValid,
  setIsValid,
  setMissingDocs,
}: RequestFieldsProps) => {
  const [fields, setFields] = useState<RequestField[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Doc[]>([]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const res = await getFields(
          request.submissionTypeSubTypeId || request.submissionTypeId
        );
        if (res.status === 200) {
          // Add all the fields into the request
          const updatedFields = res.data.data.map((field: any) => ({
            id: field.id,
            value:
              request.fieldValues.find((f: any) => f.id === field.id)?.value ||
              '',
          }));

          setFields(res.data.data);

          setRequest({ ...request, fieldValues: updatedFields });
        } else {
          throw new Error('Unable to retrieve the field list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    const fetchDocuments = async () => {
      try {
        const res = await getDocuments(
          request.submissionTypeSubTypeId || request.submissionTypeId
        );
        if (res.status === 200) {
          setDocuments(
            res.data.data.filter((doc: Doc) => doc.id !== EXTRA_DOCUMENT_ID)
          );
        } else {
          throw new Error('Unable to retrieve the documents list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    fetchFields();
    fetchDocuments();
  }, []);

  // Validation
  useEffect(() => {
    // Check if has empty required fields, excluding the ones not visible
    const visibleFields = fields.filter((field) => {
      if (
        field.conditional &&
        field.conditional.fieldId !== '03_importo_da_garantire'
      ) {
        const { fieldId, fieldValue } = field.conditional;
        const reqFieldObj = request.fieldValues.find(
          (f: any) => f.id === fieldId
        );
        const fieldObj = fields.find((f: any) => f.id === reqFieldObj?.id);

        if (!fieldValue && reqFieldObj?.value && !fieldObj?.error) {
          return true;
        }

        return reqFieldObj?.value === fieldValue;
      }

      return true;
    });

    const hasErrors = visibleFields.some((field) => field.error);

    const hasMissingRequired = visibleFields.some((field) => {
      const fieldObj = request.fieldValues?.find((f: any) => f.id === field.id);
      const value = fieldObj?.value ?? '';

      return field.required && !value;
    });
    // Check if there are files selected but not uploaded yet
    const hasUploadedDocs =
      request.documents.length === request.selectedFiles.length;

    const hasMissingDocs = !!documents?.some((document) => {
      const documentObj = request.documents?.find(
        (d: any) => d.id === document.id
      );

      return document.required && !documentObj;
    });

    setMissingDocs(hasMissingDocs);

    const validation = !hasErrors && !hasMissingRequired && hasUploadedDocs;

    setIsValid({ ...isValid, [STEP]: validation });
  }, [fields, request.selectedFiles]);

  // const handleFieldChange = (value: string, field: RequestField) => {
  //   let updatedFieldValues = cloneDeep(request.fieldValues);
  //   const fv = updatedFieldValues.findIndex((f: any) => f.id === field.id);
  //   if (fv >= 0) {
  //     set(updatedFieldValues, `[${fv}].value`, value);
  //   } else {
  //     updatedFieldValues.push({
  //       id: field.id,
  //       value,
  //     });
  //   }

  //   const updatedRequest = {
  //     ...request,
  //     fieldValues: updatedFieldValues,
  //   };

  //   validateField(updatedRequest, field, fields, setFields);
  //   setRequest(updatedRequest);
  // };

  const handleFieldChange = (value: string, field: RequestField) => {
    let updatedFieldValues = cloneDeep(request.fieldValues);
    const fv = updatedFieldValues.findIndex((f: any) => f.id === field.id);
    if (fv >= 0) {
      set(updatedFieldValues, `[${fv}].value`, formatValue(field, value));
    } else {
      updatedFieldValues.push({
        id: field.id,
        value,
      });
    }

    const updatedRequest = {
      ...request,
      fieldValues: updatedFieldValues,
    };

    // Validate the updated field
    const updatedFields = validateField(updatedRequest, field, fields)
    setFields(updatedFields);

    // Revalidate dependent child fields if necessary
    fields.forEach((potentialChildField) => {
      if (potentialChildField.conditional?.fieldId === field.id) {
        setFields(validateField(updatedRequest, potentialChildField, updatedFields))
      }
    });
    
    setRequest(updatedRequest);
  };

  const renderValue = (request: any, field: any) =>
    request.fieldValues?.find((f: any) => f.id === field.id)?.value || '';

  const formatValue = (field : any, value: any) => {
    if(field.check){
      if(field.check.type === 'numberGreaterThanZero' || field.check.type === 'greaterThan'){
        // Remove any existing dots or symbols from the string and convert it to a number
        const valueToFormat = value.replace(/[.]/g, '');
        const parts = valueToFormat.split(',');
        // Format the part before the comma separating 
        const formattedBeforeComma = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        // Concatenate the formatted part with the part after the comma
        return formattedBeforeComma + (parts[1] !== undefined ? ',' + parts[1] : '');
      }
    }
    return value;
  }

  // Function to handle file selection
  const handleFileChange = (
    event: any,
    objectId: string,
    isExtraDoc: boolean = false
  ) => {
    const file = event.target.files[0];

    // Clone the request object to avoid mutating the original state
    const updatedFiles = [...request.selectedFiles];

    // Find the index of the selectedFile with the matching id
    const index = updatedFiles.findIndex(
      (selectedFile: any) => selectedFile.id === objectId
    );

    if (index >= 0) {
      updatedFiles[index] = {
        ...updatedFiles[index],
        file: file,
        isUploaded: false,
        tempId: isExtraDoc ? EXTRA_DOCUMENT_ID : null,
      };
    } else {
      updatedFiles.push({
        id: objectId,
        file: file,
        isUploaded: false,
        tempId: isExtraDoc ? EXTRA_DOCUMENT_ID : null,
      });
    }

    // Update the selectedFiles array in the request state
    setRequest({ ...request, selectedFiles: updatedFiles });
  };

  /**
   * @param documentId
   * Remove file from list of files that need to be uploaded.
   */

  const handleRemoveFile = (objectId: string, isExtraDoc: boolean = false) => {
    const updatedFiles = request.selectedFiles.filter(
      (item: any) => item.id !== objectId
    );

    const filteredDocs = request.documents.filter(
      (d: any) => d?.tempId !== objectId
    );

    let updatedExtraDocs = cloneDeep(request.extraDocuments);

    if (isExtraDoc) {
      const uploadedFilesCount = request.selectedFiles.filter(
        (item: any) => item?.tempId === EXTRA_DOCUMENT_ID && item.isUploaded
      ).length;

      if (
        request.extraDocuments.length - uploadedFilesCount === 1 &&
        request.selectedFiles.find((item: any) => item.id === objectId)
          ?.isUploaded
      ) {
        updatedExtraDocs = updatedExtraDocs.filter(
          (doc: any) => doc?.id !== objectId
        );
      } else {
        updatedExtraDocs = updatedExtraDocs.filter(
          (doc: any) => doc?.id !== objectId
        );
        updatedExtraDocs.push(EXTRA_DOCUMENT_REQUEST());
      }
    }

    setRequest({
      ...request,
      selectedFiles: updatedFiles,
      documents: filteredDocs,
      extraDocuments: updatedExtraDocs,
    });
  };

  const handleUpload = async (id: string, extraDocumentId?: string) => {
    const docId = extraDocumentId ?? id;
    const fileObj = request.selectedFiles.find((file: any) => file.id === id);
    const file = fileObj.file;

    if (file) {
      try {
        const res = await uploadFile(file);

        if (res.status === 201) {
          let docs: any = request.documents ?? [];
          docs = [
            ...docs,
            {
              id: docId,
              tempId: id,
              name: res.data.originalname,
              path: res.data.filename,
              mime: res.data.mimetype,
            },
          ];

          const updatedFiles = request.selectedFiles.map((file: any) => {
            if (file.id === id) {
              return { ...file, isUploaded: true };
            }
            return file;
          });

          let updatedExtraDocs = request.extraDocuments;
          if (extraDocumentId) {
            updatedExtraDocs.push(EXTRA_DOCUMENT_REQUEST());
          }

          setRequest({
            ...request,
            documents: docs,
            selectedFiles: updatedFiles,
            extraDocuments: updatedExtraDocs,
          });
        } else {
          throw new Error('Unable to upload file');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: "Errore durante l'upload dei file",
        });
        setShowAlert(true);
      }
    }
  };

  return (
    <Grid container spacing={8} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {fields
            .filter((field) => {
              if (
                !field.conditional ||
                field.conditional.fieldId === '03_importo_da_garantire'
              ) {
                return true;
              }
              const { fieldId, fieldValue } = field.conditional;
              // Parent field that controls if the child gets rendered
              const reqFieldObj = request.fieldValues.find(
                (f: any) => f.id === fieldId
              );

              const fieldObj = fields.find((f: any) => f.id === reqFieldObj.id);

              if (!fieldValue && reqFieldObj?.value && !fieldObj?.error) {
                return true;
              }

              return reqFieldObj?.value === fieldValue;
            })
            .map((field) => {
              return (
                <Grid item xs={12} sm={8} key={field.id}>
                  {field.type === 'dropdown' ? (
                    <TextField
                      select
                      label={field.name}
                      InputLabelProps={{ shrink: true }}
                      value={renderValue(request, field)}
                      onChange={(e) => handleFieldChange(e.target.value, field)}
                      fullWidth
                      required={field.required}
                      error={field.error}
                      helperText={field.helperText}
                    >
                      {field.check?.options?.map((option, index) => (
                        <MenuItem key={index} value={get(option, 'id', '')}>
                          {get(option, 'value', '')}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      label={field.name}
                      InputLabelProps={{ shrink: true }}
                      type={field.type}
                      value={renderValue(request, field)}
                      onChange={(e) => handleFieldChange(e.target.value, field)}
                      fullWidth
                      error={field.error}
                      helperText={field.helperText}
                      required={field.required}
                      multiline={
                        field.check?.type === 'textarea' ? true : false
                      }
                    />
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography variant="h4">Allegati</Typography>
          </Grid>
          {documents?.map((document) => {
            const required =
              !!get(document, 'required', false) ||
              !!get(document, 'document.required', false);
            const requiredLabel = required ? '*' : '';
            return (
              <Grid item xs={12} sm={8} key={document.id}>
                <Box display="flex" alignItems="center" justifyContent="start">
                  <Box flexGrow={1}>
                    <label style={{ fontSize: 12 }}>
                      {document.name + requiredLabel}
                    </label>

                    <Box display="flex" alignItems="center">
                      <DropZone
                        file={
                          request.selectedFiles.find(
                            (file: any) => file.id === document.id
                          )?.file
                        }
                        customFn={(event: any) =>
                          handleFileChange(event, document.id)
                        }
                        parent={document}
                        setFile={(acceptedFiles: any) =>
                          handleFileChange(
                            { target: { files: acceptedFiles } },
                            document.id,
                            false
                          )
                        }
                      />

                      <Box
                        marginLeft={2}
                        width="200px"
                        display="flex"
                        justifyContent="center"
                      >
                        {!request.selectedFiles.find(
                          (file: any) => file.id === document.id
                        ) ? (
                          <Button variant="outlined" component="label">
                            Scegli file
                            <input
                              type="file"
                              id={document.id}
                              hidden
                              onChange={(event) => {
                                handleFileChange(event, document.id);
                              }}
                            />
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              onClick={() => handleUpload(document.id)}
                              disabled={
                                request.selectedFiles.find(
                                  (file: any) => file.id === document.id
                                ).isUploaded
                                  ? true
                                  : false
                              }
                            >
                              Conferma
                            </Button>
                            <IconButton
                              onClick={() => handleRemoveFile(document.id)}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}

          {request?.extraDocuments?.map((document: any, index: number) => {
            return index <= request.extraDocuments.length - 1 ? (
              <Grid item xs={12} sm={8} key={`${document.id}-${index}`}>
                <Box display="flex" alignItems="center" justifyContent="start">
                  <Box flexGrow={1}>
                    <label style={{ fontSize: 12 }}>{document.name}</label>

                    <Box display="flex" alignItems="center">
                      <DropZone
                        file={
                          request.selectedFiles.find(
                            (file: any) => file.id === document.id
                          )?.file
                        }
                        customFn={(event: any) =>
                          handleFileChange(event, document.id, true)
                        }
                        parent={document}
                        setFile={(acceptedFiles: any) =>
                          handleFileChange(
                            { target: { files: acceptedFiles } },
                            document.id,
                            true
                          )
                        }
                      />

                      <Box
                        marginLeft={2}
                        width="200px"
                        display="flex"
                        justifyContent="center"
                      >
                        {!request.selectedFiles.find(
                          (file: any) => file.id === document.id
                        ) ? (
                          <Button variant="outlined" component="label">
                            Scegli file
                            <input
                              type="file"
                              id={document.id}
                              hidden
                              onChange={(event) => {
                                handleFileChange(event, document.id, true);
                              }}
                            />
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              onClick={() =>
                                handleUpload(document.id, document.documentId)
                              }
                              disabled={
                                request.selectedFiles.find(
                                  (file: any) => file.id === document.id
                                ).isUploaded
                              }
                            >
                              Conferma
                            </Button>
                            <IconButton
                              onClick={() =>
                                handleRemoveFile(document.id, true)
                              }
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : null;
          })}
        </Grid>
      </Grid>

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default UserRequestFields;
