import { Grid, Button, Typography, Card } from '@mui/material';
import UsersTable from '../../components/UsersTable';
import { useNavigate } from 'react-router-dom';

function Users() {
  const navigate = useNavigate();

  const handleInvite = () => {
    navigate('/invite');
  };

  return (
    <Grid container gap={2} justifyContent="center" sx={{ padding: '40px' }}>
      <Grid item xs={10}>
        <Grid container justifyContent="center" alignItems="center">
          <Card
            sx={{
              padding: '16px 0',
              width: '600px',
              boxShadow: 'none',
              border: '1px solid #BDBDBD',
              backgroundColor: 'var(--clr-bg-grey)',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              align="center"
              color="var(--clr-main-blue)"
              fontWeight={600}
            >
              Elenco utenti
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={10} marginTop="80px">
        <Grid container justifyContent="end">
          <Button variant="contained" onClick={handleInvite}>
            Aggiungi nuovo utente
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <UsersTable />
      </Grid>
    </Grid>
  );
}

export default Users;
