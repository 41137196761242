import { useState, useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { authContext } from '../../context/auth';
import { Menu, MenuItem } from '@mui/material';

const CustomAppBar = (props: any) => {
  const { getDrawerWidth, handleDrawerToggle } = props;
  const { user, logout } = useContext(authContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#263B7D',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          width: { sm: `calc(100% - ${getDrawerWidth()}px)` },
          ml: { sm: `${getDrawerWidth()}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon style={{ color: 'var(--clr-main-1)' }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {process.env.DEFAULT_TITLE}
          </Typography>
          <List
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <ListItem
              style={{ width: 'fit-content' }}
              key="profile"
              disablePadding
            >
              {user && (
                <ListItemButton
                  onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                >
                  <ListItemIcon
                    style={{
                      justifyContent: 'flex-end',
                      marginRight: '0.5em',
                    }}
                  >
                    <AccountCircleIcon style={{ color: 'white' }} />
                  </ListItemIcon>

                  <ListItemText
                    style={{ color: 'white' }}
                    primary={`Ciao, ${user ? user.email : ''}`}
                  />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default CustomAppBar;
