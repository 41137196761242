import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getContraenteDocumentTypeList,
  getContraenteList,
  updateContraente,
} from '../../models/request';
import { Contraente } from '../../types/request';
import Actions from '../Actions';
import EditContraenteDialog from './EditContraenteDialog';
import { get } from 'lodash';

const PER_PAGE = 20;

type AnagraficheTableProps = {
  setAlertMessage: Function;
  setShowAlert: Function;
  refreshFired?: boolean;
  searchTerm?: string;
  triggerSearch?: boolean;
};

const AnagraficheTable = ({
  setAlertMessage,
  setShowAlert,
  refreshFired,
  searchTerm,
  triggerSearch,
}: AnagraficheTableProps) => {
  const [contraenti, setContraenti] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedContraente, setSelectedContraente] = useState<Contraente>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [contraenteDocTypes, setContraenteDocTypes] = useState([]);

  const closeDialog = () => {
    setOpenDialog(false);
    setRefresh(!refresh);
  };

  const fetchContraenteDocTypes = async () => {
    try {
      const res = await getContraenteDocumentTypeList();
      if (res.status !== 200) {
        throw new Error("Errore durante l'aggiunta del Contraente.");
      }
      setContraenteDocTypes(get(res, 'data.data', []));
    } catch (error) {
      setAlertMessage({
        severity: 'warning',
        message: 'Non è stato possibile aggiungere il Contraente.',
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    const fetchContraenteList = async () => {
      try {
        await fetchContraenteDocTypes();
        const orderBy = 'ragione_sociale';
        const orderDirection = 'ASC';

        const res = await getContraenteList(
          searchTerm,
          page,
          PER_PAGE,
          orderBy,
          orderDirection
        );
        if (res.status === 200) {
          setContraenti(res.data.data);
          setTotalPages(res.data.total_pages);
        } else {
          throw new Error('Unable to retrieve Contraente list.');
        }
      } catch (error) {
        console.error(
          `An error occurred while retrieving Contraente list: ${error}`
        );
        setAlertMessage({
          severity: 'warning',
          message: "Non è stato possibile recuperare l'elenco dei Contraenti.",
        });
        setShowAlert(true);
      }
    };

    fetchContraenteList();
  }, [refresh, page, refreshFired, triggerSearch]);

  const handleEdit = (contraente: any) => {
    setSelectedContraente(contraente);
    setOpenDialog(true);
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  const handleSubmit = async (fields: any) => {
    // Skip if no Contraente is selected
    if (!selectedContraente) return;
    try {
      const res = await updateContraente(selectedContraente?.id, fields);

      if (res.status === 200) {
        setAlertMessage({
          severity: 'success',
          message: 'Contraente aggiornato con successo.',
        });
        setShowAlert(true);
      } else {
        throw new Error("Errore durante l'aggiornamento del Contraente.");
      }
    } catch (error: any) {
      setAlertMessage({
        severity: 'warning',
        message:
          error?.message || 'Non è stato possibile aggiornare il Contraente.',
      });
      setShowAlert(true);
    } finally {
      setTimeout(() => {
        closeDialog();
        setRefresh(!refresh);
      }, 3000);
    }
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ragione sociale </TableCell>
                <TableCell>P.Iva / Codice Fiscale</TableCell>
                <TableCell align="center">Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contraenti?.map((contraente: any) => (
                <TableRow key={contraente?.id}>
                  <TableCell>{contraente?.ragione_sociale}</TableCell>
                  <TableCell>{contraente.partita_iva}</TableCell>
                  <TableCell align="center">
                    <Actions
                      showDelete={false}
                      onEditClick={() => handleEdit(contraente)}
                      onDeleteClick={() => {}}
                      alignment="center"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="end">
          <Pagination
            color="primary"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>

      {openDialog ? (
        <EditContraenteDialog
          open={openDialog}
          onClose={closeDialog}
          onSubmit={handleSubmit}
          contraente={selectedContraente}
          documentTypes={contraenteDocTypes}
          setContraente={setSelectedContraente}
        />
      ) : null}
    </Grid>
  );
};

export default AnagraficheTable;
