import { Alert, Grid, Snackbar } from '@mui/material';
import { get } from 'lodash';
import { useContext, useState } from 'react';
import moment from 'moment';
import { authContext } from '../../context/auth';
import { AlertMessage } from '../../types/form';

type EditRequestNotesProps = {
  request: any;
  setRequest: Function;
};
const EditRequestNotes = ({ request, setRequest }: EditRequestNotesProps) => {
  const { user } = useContext(authContext);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const notes = request?.notes?.filter((n: any) => n.id !== 'extra-note') || [];
  return (
    <div
      style={{
        borderRadius: 12,
        margin: `6px 24px 24px`,
        padding: `16px 4px 24px`,
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <h1>Cronologia comunicazioni</h1>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  maxHeight: '600px',
                }}
              >
                {notes.map((n: any) => {
                  if (!n?.message) {
                    return null;
                  }
                  const fromCurrentUser =
                    get(n, 'user.id', '') !== get(user, 'id', '');
                  const justifycontent = fromCurrentUser
                    ? 'flex-start'
                    : 'flex-end';
                  const width = fromCurrentUser ? '80%' : 'auto';
                  const borderradius = fromCurrentUser
                    ? `0px 12px 12px 12px`
                    : `12px 12px 0px 12px`;
                  const bgcolor = fromCurrentUser
                    ? 'rgb(25, 118, 210, 0.65)'
                    : 'rgb(102, 187, 106, 0.65)';
                  return (
                    <div
                      key={n.id}
                      style={{
                        display: 'flex',
                        justifyContent: justifycontent,
                      }}
                    >
                      <p
                        style={{
                          background: bgcolor,
                          padding: `12px 40px`,
                          width: width,
                          borderRadius: borderradius,
                          color: '#FFF',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{ textTransform: 'capitalize', fontSize: 11 }}
                        >
                          {n?.user?.fullname} -{' '}
                          {moment(n.created).format('DD/MM/yyyy HH:mm')}
                        </span>
                        <span>{n.message}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default EditRequestNotes;
