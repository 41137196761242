import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Pagination,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userListRequests } from '../../models/request';
import { RequestType, Status } from '../../types/request';
import Actions from '../Actions';
import moment from 'moment';
import {
  MACRO_STATUS,
  REQUEST_STATUS,
  BROKER_STATUS_TO_HIDE,
} from '../../utils/constants';
import WeekendTwoToneIcon from '@mui/icons-material/WeekendTwoTone';
import DeleteModal from '../Request/DeleteModal';

const PER_PAGE = 20;

type RequestsTableProps = {
  selectedStatus: Status | string | null;
  searchTerm?: string;
  triggerSearch?: boolean;
};

const RequestsTable = ({
  selectedStatus,
  searchTerm,
  triggerSearch,
}: RequestsTableProps) => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<RequestType | null>(
    null
  );

  const fetchRequests = async () => {
    let query = '';
    if (selectedStatus === MACRO_STATUS.TrattativeInCorso.name) {
      query = MACRO_STATUS.TrattativeInCorso.id;
    } else if (selectedStatus === MACRO_STATUS.TrattativeConcluse.name) {
      query = MACRO_STATUS.TrattativeConcluse.id;
    }

    const selected: Status | string = selectedStatus || '';

    try {
      const res = await userListRequests(query, page, PER_PAGE, searchTerm);
      if (res.status === 200) {
        if (selected === MACRO_STATUS.TrattativeConcluse.name) {
          const data = res.data.data?.filter((s: any) =>
            BROKER_STATUS_TO_HIDE.includes(s?.status?.id || '')
          );
          setRequests(data);
          setTotalPages(res.data.total_pages);
          return;
        }
        if (selected === MACRO_STATUS.TrattativeInCorso.name) {
          const data = res.data.data?.filter(
            (s: any) => !BROKER_STATUS_TO_HIDE.includes(s?.status?.id || '')
          );
          setRequests(data);
          setTotalPages(res.data.total_pages);
          return;
        }
      } else {
        console.error('Unable to retrieve requests.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving requests.');
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [selectedStatus, page]);

  useEffect(() => {
    setPage(1);
    fetchRequests();
  }, [triggerSearch]);

  const handleEdit = (requestId: string) => {
    navigate(`/edit/${requestId}`);
  };

  const handleDelete = (request: RequestType) => {
    setSelectedRequest(request);
    setIsDelete(true);
  };

  const closeDeleteDialog = () => {
    setSelectedRequest(null);
    setIsDelete(false);
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  return isEmpty(requests) ? (
    <Grid container gap={2} justifyContent="center">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          opacity: 0.7,
          marginTop: 18,
        }}
      >
        <div style={{ fontSize: 24 }}>
          <span>Nessun dato</span>
        </div>
        <div style={{ fontSize: 84 }}>
          <WeekendTwoToneIcon fontSize="inherit" />
        </div>
      </div>
    </Grid>
  ) : (
    <Grid container gap={2} justifyContent="end">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contraente</TableCell>
              <TableCell>Tipologia</TableCell>
              <TableCell>Beneficiario</TableCell>
              <TableCell>Inserimento</TableCell>
              <TableCell>Ultimo aggiornamento</TableCell>
              <TableCell>Stato</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests?.map((request: any) => (
              <TableRow key={request?.id}>
                <TableCell>{request?.mandataria?.ragione_sociale}</TableCell>
                <TableCell>{request?.submission_type?.name}</TableCell>
                <TableCell>{request?.beneficiario_ragione_sociale}</TableCell>
                <TableCell>
                  {request?.created
                    ? moment(request?.created).format('DD/MM/yyyy')
                    : ''}
                </TableCell>
                <TableCell>
                  {request?.updated
                    ? moment(request?.updated).format('DD/MM/yyyy')
                    : ''}
                </TableCell>
                <TableCell>{request?.status?.name}</TableCell>
                <TableCell align="center">
                  <Actions
                    onEditClick={() => handleEdit(request.id)}
                    onDeleteClick={() => handleDelete(request)}
                    showEdit={
                      request?.status?.id !== REQUEST_STATUS.DRAFT_BROKER
                    }
                    showDelete={
                      request?.status?.id === REQUEST_STATUS.DRAFT_BROKER
                        ? true
                        : false
                    }
                    showGenerateLink={
                      request?.status?.id === REQUEST_STATUS.DRAFT_BROKER
                    }
                    requestId={request?.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        color="primary"
        count={totalPages || 1}
        page={page}
        onChange={handlePageChange}
      />

      {isDelete && (
        <DeleteModal
          open={isDelete}
          onClose={closeDeleteDialog}
          request={selectedRequest}
          refreshFn={fetchRequests}
        />
      )}
    </Grid>
  );
};

export default RequestsTable;
