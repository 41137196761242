import { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Paper,
    Snackbar,
    Alert,
    FormGroup,
    TextField,
    Box,
    Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { contraenteFields } from '../../types/request';
import { getContraente, getContraenteDocumentTypeList } from '../../models/request';
import { AlertMessage } from '../../types/form';
import { get } from 'lodash';
import moment from 'moment';
import api from '../../models/api';
import downloadjs from 'downloadjs';
import {
    EXTRA_DOCUMENT_CONTRAENTE,
    EXTRA_DOCUMENT_ID,
  } from '../../utils/constants';

type FieldsType = {
    [key: string]: any;
};

interface IExtraDocument {
    id: string;
    documentId: string;
    name: string;
    required: boolean;
    description: string;
};

const ContraenteDetails = () => {
    const { id } = useParams();
    const [contraente, setContraente] = useState<any>(null);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
    const [showAlert, setShowAlert] = useState(false);
    const [fields, setFields] = useState<FieldsType>({
        document: [],
    });
    const [contraenteDocTypes, setContraenteDocTypes] = useState([]);
    const [documents, setDocuments] = useState<any>(
        contraenteDocTypes.filter((d: any) => d.persona === 'giuridica')
    );
    const [extraDocuments, setExtraDocuments] = useState<IExtraDocument[]>([
        EXTRA_DOCUMENT_CONTRAENTE(),
      ]);
    const [personaType, setPersonaType] = useState('');

    useEffect(() => {
        const fetchContraente = async () => {
            try {
                const res = await getContraente(id!);
                if (res.status === 200) {
                    setContraente(res.data);
                } else {
                    throw new Error('Unable to retrieve the field list');
                }
            } catch (error) {
                setAlertMessage({
                    severity: 'error',
                    message: 'Errore durante il carimento della pagina.',
                });
                setShowAlert(true);
            }
        };

        fetchContraenteDocTypes();
        fetchContraente();
    }, [id]);

    useEffect(() => {
        if (contraente) {
            const initialFields: FieldsType = {
                ...Object.fromEntries(
                    contraenteFields.map((field) => [field.id, contraente[field.id]])
                ),
                document: [],
            };
            setFields(initialFields);

            //Update documentTypes:
            const value = contraente.partita_iva;
            const isCodiceFiscale =
              /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(
                value
              );
            const isPartitaIva = /^[0-9]{11}$/.test(value);
        
            if (isPartitaIva) {
                setPersonaType('giuridica');
            } else if (isCodiceFiscale) {
                setPersonaType('fisica');
            }
            setDocuments(contraenteDocTypes.filter((d: any) => d.persona === personaType));
        }
    }, [contraente]);

      const downloadHandler = async (document: any) => {
        try {
          const url = '/request/contraente/download/' + document.id;
          const response = await api.get(url, { responseType: 'blob' });
          const blob = new Blob([response.data]);
          downloadjs(blob, document.name);
        } catch (error) {
          setAlertMessage({
            severity: 'error',
            message: 'Errore durante il download del file',
          });
          setShowAlert(true);
        }
      };

    const fetchContraenteDocTypes = async () => {
        try {
            const res = await getContraenteDocumentTypeList();
            if (res.status !== 200) {
                throw new Error("Errore durante l'aggiunta del Contraente.");
            }
            setContraenteDocTypes(get(res, 'data.data', []));
        } catch (error) {
            setAlertMessage({
                severity: 'warning',
                message: 'Non è stato possibile aggiungere il Contraente.',
            });
            setShowAlert(true);
        }
    };

    if (!contraente) {
        return (
            <Container>
                <Typography variant="h4">Caricamento...</Typography>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Dettagli Contraente
            </Typography>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                {fields && (
                    <FormGroup>
                        <Box sx={{ display: 'flex', my: 2 }}>
                            <Button variant={'outlined'}>
                                {'Persona ' + personaType }
                            </Button>
                        </Box>
                        {contraenteFields.map((field) => {
                            // Render fields for each type of Persona
                            if (
                                field.showFor === 'persona_fisica' ||
                                field.showFor === 'persona_giuridica'
                            ) {
                                return null;
                            }

                            return (
                                <TextField
                                    key={field.id}
                                    label={field.label_1}
                                    type={field.type as any}
                                    value={fields[field.id] || ''}
                                    fullWidth
                                    sx={{ my: 1 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            );
                        })}
                    </FormGroup>
                )}

                <Typography variant="h6" gutterBottom>
                    Documenti
                </Typography>
                {contraente.documents          
                .filter(
                    (d: any) =>
                        d?.document && d?.document?.id !== EXTRA_DOCUMENT_ID
                )
                .map((document: any) => (
                    <Box key={document.id} mb={2}>
                        <Typography variant="body1">
                            <strong style={{ fontWeight: 600 }}>
                                {document.document.name}
                            </strong>
                            <br></br>
                            <label>Nome:</label>{' '}
                            <span
                                style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                                onClick={() => downloadHandler(document)}>
                                {document.name}
                            </span>
                        </Typography>
                        <Typography variant="body2">
                            <label>Data inserimento:</label> {moment(document.created).format('DD/MM/yyyy')}
                        </Typography>
                    </Box>
                ))}
                {contraente.documents          
                .filter(
                    (d: any) =>
                        (!d?.document && !!d.id) || d?.document?.id === EXTRA_DOCUMENT_ID
                )
                .map((extraDoc: any) => (
                    <Box key={extraDoc.id} mb={2}>
                        <Typography variant="body1">
                            <strong style={{ fontWeight: 600 }}>
                                {extraDocuments[0].name}
                            </strong>
                            <br></br>
                            <label>Nome:</label>{' '}
                            <span
                                style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                                onClick={() => downloadHandler(document)}>
                                {extraDoc.name}
                            </span>
                        </Typography>
                        <Typography variant="body2">
                            <label>Data inserimento:</label> {moment(extraDoc.created).format('DD/MM/yyyy')}
                        </Typography>
                    </Box>
                ))}
            </Paper>
            <Snackbar
                open={showAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => setShowAlert(false)}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </Snackbar>
        </Container>
    );
};

export default ContraenteDetails;