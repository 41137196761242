import {
  Grid,
  TextField,
  Button,
  Alert,
  Snackbar,
  Autocomplete,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { addBeneficiario, getBeneficiarioList } from '../../models/request';
import { AlertMessage } from '../../types/form';
import {
  Beneficiario,
  beneficiarioDefault,
  BeneficiarioField,
  beneficiarioFields,
} from '../../types/request';
import { validateBeneficiario as validate } from '../../utils/validate';
import AddBeneficiarioDialog from './AddBeneficiario';

type BeneficiarioDataProps = {
  request: any;
  setRequest: Function;
  isValid: any;
  setIsValid: Function;
};

const STEP = 'step_2';

const BeneficiarioData = ({
  request,
  setRequest,
  isValid,
  setIsValid,
}: BeneficiarioDataProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [beneficiarioList, setBeneficiarioList] = useState<Beneficiario[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [beneficiarioFieldsData, setBeneficiarioFieldsData] =
    useState(beneficiarioFields);

  const [selectedBeneficiario, setSelectedBeneficiario] =
    useState<Beneficiario | null>(null);

  // Component is initialized
  useEffect(() => {
    // If we have the beneficiario data in the current request (i.e. when I go back),
    //  we fill in the data in the selected beneficiario to correctly display the information
    if (request.beneficiarioId) {
      setSelectedBeneficiario({
        address: request.beneficiario_address,
        address_number: request.beneficiario_address_number,
        comune: request.beneficiario_comune,
        id: request.beneficiarioId,
        partita_iva: request.beneficiario_partita_iva,
        pec: request.beneficiario_pec,
        province: request.beneficiario_province,
        ragione_sociale: request.beneficiario_ragione_sociale,
        zip: request.beneficiario_zip,
      });
    }
  }, []);

  const fetchBeneficiarioList = async (query: string = '') => {
    try {
      const res = await getBeneficiarioList(query);
      if (res.status === 200) {
        setBeneficiarioList(res.data.data);
      } else {
        throw new Error('Unable to retrieve the Beneficiario list');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    const fetchList = async () => {
      await fetchBeneficiarioList(searchQuery);
    };

    fetchList();
  }, [searchQuery]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (fields: any) => {
    try {
      const res = await addBeneficiario(fields);

      if (res.status === 201) {
        setAlertMessage({
          severity: 'success',
          message: 'Beneficiario aggiunto con successo.',
        });
        setShowAlert(true);
        setTimeout(() => {
          closeDialog();
        }, 3000);
      } else {
        throw new Error("Errore durante l'aggiunta del Beneficiario.");
      }
    } catch (error) {
      setAlertMessage({
        severity: 'warning',
        message: 'Non è stato possibile aggiungere il Beneficiario.',
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    // We only update the beneficiario data when something is selected
    if (selectedBeneficiario !== null) {
      const updatedFields = beneficiarioFieldsData.map((field) => {
        const updatedField = { ...field };
        updatedField.value = selectedBeneficiario
          ? selectedBeneficiario[updatedField.id as keyof Beneficiario]
          : '';
        return updatedField;
      });

      const customBeneficiario: Beneficiario = beneficiarioDefault;
      if (selectedBeneficiario) {
        customBeneficiario.id = selectedBeneficiario?.id;
      }

      updatedFields.forEach((field) => {
        customBeneficiario[field.id as keyof Beneficiario] = field.value;
      });
      setBeneficiarioFieldsData(updatedFields);
      setRequest({
        ...request,
        beneficiarioId: selectedBeneficiario?.id,
        beneficiario_ragione_sociale: customBeneficiario.ragione_sociale,
        beneficiario_partita_iva: customBeneficiario.partita_iva,
        beneficiario_address: customBeneficiario.address,
        beneficiario_address_number: customBeneficiario.address_number,
        beneficiario_zip: customBeneficiario.zip,
        beneficiario_province: customBeneficiario.province,
        beneficiario_comune: customBeneficiario.comune,
        beneficiario_pec: customBeneficiario.pec,
      });
    }
  }, [selectedBeneficiario]);

  const handleFieldChange = (field: BeneficiarioField, value: string) => {
    const updatedF = { ...field, value };
    validate(value, updatedF);

    const updatedFieldsData = beneficiarioFieldsData.map(
      (f: BeneficiarioField) => (f.id === updatedF.id ? { ...updatedF } : f)
    );

    const customBeneficiario: Beneficiario = beneficiarioDefault;
    if (selectedBeneficiario) {
      customBeneficiario.id = selectedBeneficiario?.id;
    }

    updatedFieldsData.forEach((field) => {
      customBeneficiario[field.id as keyof Beneficiario] = field.value;
    });

    setBeneficiarioFieldsData(updatedFieldsData);
    setRequest({
      ...request,
      beneficiarioId: selectedBeneficiario?.id,
      beneficiario_ragione_sociale: customBeneficiario.ragione_sociale,
      beneficiario_partita_iva: customBeneficiario.partita_iva,
      beneficiario_address: customBeneficiario.address,
      beneficiario_address_number: customBeneficiario.address_number,
      beneficiario_zip: customBeneficiario.zip,
      beneficiario_province: customBeneficiario.province,
      beneficiario_comune: customBeneficiario.comune,
      beneficiario_pec: customBeneficiario.pec,
    });
  };

  useEffect(() => {
    const validation =
      !isEmpty(selectedBeneficiario) &&
      !beneficiarioFieldsData.some(
        (field) => field.error || (field.required && isEmpty(field.value))
      );

    setIsValid({ ...isValid, [STEP]: validation });
  }, [selectedBeneficiario, beneficiarioFieldsData]);

  const handleOpen = async () => {
    try {
      await fetchBeneficiarioList(searchQuery);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10}>
        <Autocomplete
          onOpen={handleOpen}
          disablePortal
          id="combo-box-beneficiario"
          options={beneficiarioList}
          filterOptions={(x) => x}
          isOptionEqualToValue={(
            option: Beneficiario | null,
            value: Beneficiario | null
          ) => option?.id === value?.id}
          getOptionLabel={(option: Beneficiario) => option.ragione_sociale}
          value={selectedBeneficiario}
          onChange={(e, value: Beneficiario | null) =>
            setSelectedBeneficiario(value)
          }
          onInputChange={(e, newInputValue) => {
            setSearchQuery(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Beneficiario"
              variant="outlined"
              sx={{ mt: 2, mb: 1, width: '100%' }}
            />
          )}
        />
        <Button onClick={() => setOpenDialog(true)}>
          Aggiungi Nuovo Beneficiario
        </Button>
      </Grid>

      {selectedBeneficiario?.id &&
        beneficiarioFieldsData.map((field) => (
          <Grid
            item
            xs={10}
            sm={
              field.id === 'address_number' ||
              field.id === 'zip' ||
              field.id === 'province'
                ? 3
                : 10
            }
            key={field.id}
          >
            <TextField
              label={field.label}
              InputLabelProps={{ shrink: true }}
              type={field.type}
              value={field.value || ''}
              required={field.required}
              error={field.error}
              helperText={field.helperText}
              onChange={(e) => handleFieldChange(field, e.target.value)}
              fullWidth
            />
          </Grid>
        ))}
      <AddBeneficiarioDialog
        open={openDialog}
        onClose={closeDialog}
        onSubmit={handleSubmit}
      />
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default BeneficiarioData;
