import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
  FormGroup,
  Box,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AlertMessage } from '../../types/form';
import { ContraenteField, contraenteFields } from '../../types/request';
import { validateContraente as validate } from '../../utils/validate';
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadFile } from '../../models/request';
import { sanitizeEmail } from '../../utils/email';
import DropZone from '../DropZone';
import {
  EXTRA_DOCUMENT_CONTRAENTE,
  EXTRA_DOCUMENT_ID,
  ROLENAMES,
} from '../../utils/constants';
import { cloneDeep, get, set } from 'lodash';
import moment from 'moment';
import downloadjs from 'downloadjs';
import AssignBroker from './AssignBroker';
import { authContext } from '../../context/auth';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import api from '../../models/api';

type EditContraenteDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: Object) => void;
  contraente: any;
  setContraente: Function;
  documentTypes?: any;
};

interface IExtraDocument {
  id: string;
  documentId: string;
  name: string;
  required: boolean;
  description: string;
}

const EditContraenteDialog = ({
  open,
  onClose,
  onSubmit,
  contraente,
  setContraente,
  documentTypes,
}: EditContraenteDialogProps) => {
  const { user } = useContext(authContext);
  const [fields, setFields] = useState(
    Object.fromEntries([
      ...contraenteFields.map((field) => [field.id, contraente[field.id]]),
      ['document', []],
    ])
  );
  const [extraDocuments, setExtraDocuments] = useState<IExtraDocument[]>([
    EXTRA_DOCUMENT_CONTRAENTE(),
  ]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState<boolean>(true);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [documents, setDocuments] = useState<any>(
    documentTypes.filter((d: any) => d.persona === 'giuridica')
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const handleConfirm = async () => {
    handleClose();
    setOpenConfirmation(false);
  };

  const closeConfirmation = () => setOpenConfirmation(false);

  interface Document {
    id: string;
    name: string;
    date: string;
    document: DocumentType;
    created?: string;
    path?: string;
  }

  const downloadHandler = async (document: Document) => {
    try {
      const url = '/request/contraente/download/' + document.id;

      const response = await api.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      downloadjs(blob, document.name);
    } catch (error) {
      console.error('Error downloading file', error);
    }
  };

  const handleFieldChange = (field: ContraenteField, value: string) => {
    validate(value, field, switchChecked);

    setFields({ ...fields, [field.id]: value });
  };

  const handleSubmit = () => {
    const sanitizedEmail = sanitizeEmail(fields.pec);
    const updatedFields = { ...fields, pec: sanitizedEmail };

    const modifiedDocs = get(fields, 'document', []);

    // copy contraentedocs and format it sae with updatedFields
    const contraenteDocs = get(contraente, 'documents', []).map((d: any) => {
      const modifiedIdx = modifiedDocs?.findIndex(
        (md: any) => md.id === d.id
        // && md.documentId === d?.document?.id
      );
      if (modifiedIdx >= 0) {
        const updatedObject = {
          description: modifiedDocs[modifiedIdx]?.description,
          documentId:
            modifiedDocs[modifiedIdx]?.documentId || EXTRA_DOCUMENT_ID,
          id: modifiedDocs[modifiedIdx]?.id,
          mime: modifiedDocs[modifiedIdx]?.mime,
          name: modifiedDocs[modifiedIdx]?.name,
          path: modifiedDocs[modifiedIdx]?.path,
        };
        modifiedDocs.splice(modifiedIdx, 1);
        return updatedObject;
      }
      return {
        description: d?.description,
        documentId: d?.document?.id,
        id: d?.id,
        mime: d?.mime,
        name: d?.name,
        path: d?.path,
      };
    });
    const mergedDocs = contraenteDocs.concat(modifiedDocs);
    set(updatedFields, 'document', mergedDocs);
    set(updatedFields, 'brokerId', user?.id);

    onSubmit(updatedFields);

    // Reset fields
    setFields(
      Object.fromEntries([
        ...contraenteFields.map((field) => [field.id, '']),
        ['document', []],
      ])
    );

    // Reset other states
    setAlertMessage(null);
    setShowAlert(false);
    setSwitchChecked(true);
    setSelectedFiles([]);
  };

  const handleClose = () => {
    contraenteFields.forEach((field) => {
      field.error = false;
      field.helperText = undefined;
    });

    //Reset fields
    setFields(
      Object.fromEntries([
        ...contraenteFields.map((field) => [field.id, '']),
        ['document', []],
      ])
    );

    // Reset other states
    setAlertMessage(null);
    setShowAlert(false);
    setSwitchChecked(true);
    setSelectedFiles([]);

    onClose();
  };

  const closeAssignBrokerDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle file selection
  const handleFileChange = (
    event: any,
    documentId: string,
    isExtraDoc: boolean = false
  ) => {
    const file = event.target.files[0];
    setSelectedFiles([
      ...selectedFiles,
      {
        id: documentId,
        file,
        isUploaded: false,
        tempId: isExtraDoc ? EXTRA_DOCUMENT_ID : null,
      },
    ]);
  };

  /**
   * @param documentId
   * Remove file from list of files that need to be uploaded.
   */
  const handleRemoveFile = (
    documentId: string,
    isExtraDoc: boolean = false
  ) => {
    const files = [...selectedFiles];
    const fileIndex = files.findIndex((item: any) => item.id === documentId);
    if (fileIndex >= 0) {
      files.splice(fileIndex, 1);
    }
    const clonedFieldDocs = cloneDeep(get(fields, 'document', []));
    const filteredDocs = clonedFieldDocs.filter(
      (d: any) => d.documentId !== documentId
    );

    let updatedExtraDocuments = cloneDeep(extraDocuments);

    if (isExtraDoc) {
      const uploadedFilesCount = selectedFiles.filter(
        (item: any) => item?.tempId === EXTRA_DOCUMENT_ID && item.isUploaded
      ).length;

      if (
        extraDocuments.length - uploadedFilesCount === 1 &&
        selectedFiles.find((item: any) => item.id === documentId)?.isUploaded
      ) {
        updatedExtraDocuments = updatedExtraDocuments.filter(
          (doc: any) => doc?.id !== documentId
        );
      } else {
        updatedExtraDocuments = updatedExtraDocuments.filter(
          (doc: any) => doc?.id !== documentId
        );
        updatedExtraDocuments.push(EXTRA_DOCUMENT_CONTRAENTE());
      }
    }

    setExtraDocuments(updatedExtraDocuments);

    setFields({ ...fields, document: filteredDocs });

    setSelectedFiles([...files]);
  };

  const handleUpload = async (
    document: any,
    loadedDoc?: any,
    isExtraDoc?: boolean
  ) => {
    const file = selectedFiles.find(
      (file: any) => file.id === document.id
    )?.file;

    if (file) {
      try {
        const res = await uploadFile(file);
        const documentId = get(document, 'id', '');
        const loadedDocId = get(loadedDoc, 'id', '');
        if (res.status === 201) {
          const docs: any = [...fields.document];

          docs.push({
            id: loadedDocId || crypto.randomUUID(),
            name: res.data.originalname,
            path: res.data.filename,
            mime: res.data.mimetype,
            description: document?.description,
            documentId:
              documentId === loadedDocId ? EXTRA_DOCUMENT_ID : documentId,
          });
          if (isExtraDoc) {
            const extraDocumentsCopy = cloneDeep(extraDocuments);

            extraDocumentsCopy.push(EXTRA_DOCUMENT_CONTRAENTE());
            setExtraDocuments(extraDocumentsCopy);
          }

          setFields({ ...fields, document: docs });
          const updatedFiles = selectedFiles.map((file: any) => {
            if (file.id === document.id) {
              return {
                ...file,
                isUploaded: true,
              };
            }
            return file;
          });

          setSelectedFiles(updatedFiles);
        } else {
          throw new Error('Unable to upload file');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: "Errore durante l'upload dei file",
        });
        setShowAlert(true);
      }
    }
  };

  const handleSwitchChange = () => {
    const field = contraenteFields.find((f) => f.id === 'partita_iva');

    const updatedSwitch = !switchChecked;
    // Avoid incorrect red error when switching
    if (field?.error || (!field?.error && fields['partita_iva']))
      validate(fields['partita_iva'], field, updatedSwitch); //validating with the updated value of switchChecked
    const name = updatedSwitch ? 'giuridica' : 'fisica';
    setDocuments(documentTypes.filter((d: any) => d.persona === name));
    setSwitchChecked(updatedSwitch);
  };

  useEffect(() => {
    const value = contraente.partita_iva;
    const isCodiceFiscale =
      /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(
        value
      );
    const isPartitaIva = /^[0-9]{11}$/.test(value);

    if (isPartitaIva) {
      setSwitchChecked(true);
    } else if (isCodiceFiscale) {
      setSwitchChecked(false);
    } else {
    }
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Modifica Contraente
        {user?.role === ROLENAMES.ADMIN ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Assegna Utente
          </Button>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Button
              variant={switchChecked ? 'outlined' : 'text'}
              onClick={() => {
                if (!switchChecked) handleSwitchChange();
              }}
            >
              Persona giuridica
            </Button>
            <Button
              variant={!switchChecked ? 'outlined' : 'text'}
              onClick={() => {
                if (switchChecked) handleSwitchChange();
              }}
            >
              Persona fisica
            </Button>
          </Box>
          {contraenteFields.map((field) => {
            // Render fields for each type of Persona
            if (
              (field.showFor === 'persona_fisica' && switchChecked) ||
              (field.showFor === 'persona_giuridica' && !switchChecked)
            ) {
              return null;
            }

            return (
              <TextField
                key={field.id}
                label={switchChecked ? field.label_1 : field.label_2}
                type={field.type}
                value={fields[field.id] || ''}
                required={field.required}
                error={field.error}
                helperText={field.helperText}
                onChange={(e) => handleFieldChange(field, e.target.value)}
                fullWidth
                sx={{ my: 1 }}
              />
            );
          })}
        </FormGroup>

        {documents.map((document: any) => {
          const required =
            !!get(document, 'required', false) ||
            !!get(document, 'document.required', false);
          const requiredLabel = required ? '*' : '';
          const docs = get(contraente, 'documents', []);
          const loadedDoc = docs?.find(
            (d: any) => d?.document?.id === document.id
          );
          const renderCreated = (document: Document) => {
            const created = get(document, 'created', '');
            return !!created ? moment(created).format('DD/MM/yyyy') : '';
          };
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              key={document.id}
              margin="20px auto"
            >
              <Box flexGrow={1}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 12,
                  }}
                >
                  <label style={{ fontWeight: 600 }}>
                    {document.name + requiredLabel}
                  </label>
                  <label>
                    <span>{`Nome: `}</span>
                    <span
                      style={{
                        textDecoration: 'underline',
                        color: '#1976d2',
                        cursor: 'pointer',
                      }}
                      onClick={() => downloadHandler(loadedDoc)}
                    >{`${get(loadedDoc, 'name', '')}`}</span>
                  </label>
                  <label>
                    {`Data inserimento: ${renderCreated(loadedDoc)}`}
                  </label>
                </div>

                <Box display="flex" alignItems="center">
                  <DropZone
                    file={
                      selectedFiles.find((file: any) => file.id === document.id)
                        ?.file
                    }
                    //customFn={handleFileChange}
                    customFn={(event: any) =>
                      handleFileChange(event, document.id)
                    }
                    parent={document}
                    //setFile={handleFileChange}
                    setFile={(acceptedFiles: any) =>
                      handleFileChange(
                        { target: { files: acceptedFiles } },
                        document.id,
                        false
                      )
                    }
                  />

                  <Box
                    marginLeft={2}
                    width="200px"
                    display="flex"
                    justifyContent="center"
                  >
                    {!selectedFiles.find(
                      (file: any) => file.id === document.id
                    ) ? (
                      <Button variant="outlined" component="label">
                        Scegli file
                        <input
                          type="file"
                          id={document.id}
                          hidden
                          onChange={(event) => {
                            handleFileChange(event, document.id);
                          }}
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          onClick={() => handleUpload(document, loadedDoc)}
                          disabled={
                            selectedFiles.find(
                              (file: any) => file.id === document.id
                            )?.isUploaded
                          }
                        >
                          Conferma
                        </Button>
                        <IconButton
                          onClick={() => handleRemoveFile(document.id)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        {get(contraente, 'documents', [])
          .filter(
            (d: any) =>
              (!d?.document && !!d.id) || d?.document?.id === EXTRA_DOCUMENT_ID
          )
          .map((document: any) => {
            const renderCreated = (document: Document) => {
              const created = get(document, 'created', '');
              return !!created ? moment(created).format('DD/MM/yyyy') : '';
            };
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                key={document.id}
                margin="20px auto"
              >
                <Box flexGrow={1}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: 12,
                    }}
                  >
                    <label style={{ fontWeight: 600 }}>
                      Ulteriori documenti
                    </label>
                    <label>
                      <span>{`Nome: `}</span>
                      <span
                        style={{
                          textDecoration: 'underline',
                          color: '#1976d2',
                          cursor: 'pointer',
                        }}
                        onClick={() => downloadHandler(document)}
                      >{`${get(document, 'name', '')}`}</span>
                    </label>
                    <label>
                      {`Data inserimento: ${renderCreated(document)}`}
                    </label>
                  </div>

                  <Box display="flex" alignItems="center">
                    <DropZone
                      file={
                        selectedFiles.find(
                          (file: any) => file.id === document.id
                        )?.file
                      }
                      //customFn={handleFileChange}
                      customFn={(event: any) =>
                        handleFileChange(event, document.id)
                      }
                      parent={document}
                      //setFile={handleFileChange}
                      setFile={(acceptedFiles: any) =>
                        handleFileChange(
                          { target: { files: acceptedFiles } },
                          document.id,
                          false
                        )
                      }
                    />

                    <Box
                      marginLeft={2}
                      width="200px"
                      display="flex"
                      justifyContent="center"
                    >
                      {!selectedFiles.find(
                        (file: any) => file.id === document.id
                      ) ? (
                        <Button variant="outlined" component="label">
                          Scegli file
                          <input
                            type="file"
                            id={document.id}
                            hidden
                            onChange={(event) => {
                              handleFileChange(event, document.id, true);
                            }}
                          />
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            component="label"
                            onClick={() => handleUpload(document, document)}
                            disabled={
                              selectedFiles.find(
                                (file: any) => file.id === document.id
                              )?.isUploaded
                            }
                          >
                            Conferma
                          </Button>
                          <IconButton
                            onClick={() => handleRemoveFile(document.id)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}

        {extraDocuments.map((extraDoc) => {
          const requiredLabel = extraDoc.required ? '*' : '';
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              key={extraDoc.id}
              margin="20px auto"
            >
              <Box flexGrow={1}>
                <label style={{ fontSize: 12 }}>
                  {extraDoc.name + requiredLabel}
                </label>
                <Box display="flex" alignItems="center">
                  <DropZone
                    file={
                      selectedFiles.find((file: any) => file.id === extraDoc.id)
                        ?.file
                    }
                    //customFn={handleFileChange}
                    customFn={(event: any) =>
                      handleFileChange(event, extraDoc.id, true)
                    }
                    parent={extraDoc}
                    //setFile={handleFileChange}
                    setFile={(acceptedFiles: any) =>
                      handleFileChange(
                        { target: { files: acceptedFiles } },
                        extraDoc.id,
                        true
                      )
                    }
                  />

                  <Box
                    marginLeft={2}
                    width="200px"
                    display="flex"
                    justifyContent="center"
                  >
                    {!selectedFiles.find(
                      (file: any) => file.id === extraDoc.id
                    ) ? (
                      <Button variant="outlined" component="label">
                        Scegli file
                        <input
                          type="file"
                          id={extraDoc.id}
                          hidden
                          onChange={(event) => {
                            handleFileChange(event, extraDoc.id, true);
                          }}
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          onClick={() =>
                            handleUpload(extraDoc, undefined, true)
                          }
                          disabled={
                            selectedFiles.find(
                              (file: any) => file.id === extraDoc.id
                            )?.isUploaded
                          }
                        >
                          Conferma
                        </Button>
                        <IconButton
                          onClick={() => handleRemoveFile(extraDoc.id, true)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </DialogContent>
      <CardActions>
        <Button
          sx={{ width: '100%' }}
          variant="outlined"
          onClick={() => setOpenConfirmation(true)}
        >
          Annulla
        </Button>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            contraenteFields.some((field) => field.error) ||
            contraenteFields.some(
              (field) => field.required && !fields[field.id]
            ) ||
            selectedFiles.length > fields.document?.length
          }
        >
          Salva
        </Button>
      </CardActions>

      {openDialog ? (
        <AssignBroker
          open={openDialog}
          onClose={closeAssignBrokerDialog}
          contraente={contraente}
          setContraente={setContraente}
        />
      ) : null}
      {openConfirmation && (
        <ConfirmationDialog
          title="Sei sicuro?"
          message="Non potrai tornare indietro"
          confirmText="Sì"
          cancelText="Annulla"
          onConfirm={handleConfirm}
          open={openConfirmation}
          onClose={closeConfirmation}
        />
      )}
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditContraenteDialog;
