import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { forgotPassword } from '../../models/auth';
import { isEmailValid } from '../../utils/email';
import { AlertMessage } from '../../types/form';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSendEmail = () => {
    const validation = isEmailValid(email);
    if (validation) {
      forgotPassword({ email }).then(() => {
        setEmailSent(true);
        setAlertMessage({
          severity: 'success',
          message: `Se l'email che hai inserito è nei nostri sistemi, riceverai un link per ripristinare la password.`,
        });
        setShowAlert(true);
      });
    } else {
      setAlertMessage({
        severity: 'warning',
        message: "Il formato dell'email non è valido",
      });
      setShowAlert(true);
    }
  };

  return (
    <>
      <Typography gutterBottom variant="h4" component="h1" align="center">
        Password dimenticata
      </Typography>
      <Card sx={{ maxWidth: 345, paddingTop: '20px' }}>
        <CardContent>
          <Typography gutterBottom align="center">
            Inserisci l'email con cui hai creato l'account e ti invieremo un
            link per reimpostare la password
          </Typography>
          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-username">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="text"
              label="Email"
              onChange={(e) => setEmail(e?.target?.value)}
              disabled={emailSent}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </CardContent>

        <CardActions>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={handleSendEmail}
            disabled={!email || emailSent}
          >
            Invia
          </Button>
        </CardActions>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={alertMessage?.severity === 'success' ? null : 5000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Card>
      <Typography
          className="link"
          align="left"
          onClick={() => navigate("/login")}
          sx={{ cursor: 'pointer' }}
        >
          Torna alla pagina di login
      </Typography>
    </>
  );
};

export default ForgotPassword;
