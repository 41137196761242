import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Pagination,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { deleteUser, listUsers, UserDTO } from '../../models/user';
import Actions from '../Actions';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import EditUserDialog from '../UserInfo/EditUserDialog';

const PER_PAGE = 20;

const UsersTable = () => {
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
  const [openEditUserDialog, setOpenEditUserDialog] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await listUsers(page, PER_PAGE);
        if (res.status === 200) {
          setUsers(res.data.data);
          setTotalPages(res.data.total_pages);
        } else {
          throw new Error('Unable to retrieve users.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving users.');
      }
    };

    fetchUsers();
  }, [refresh]);

  const editUser = (user: UserDTO) => {
    setSelectedUser(user);
    setOpenEditUserDialog(true);
  };

  const removeUser = async (id: string) => {
    try {
      await deleteUser(id);
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const closeEditUserDialog = () => {
    setSelectedUser(null);
    setOpenEditUserDialog(false);
  };

  const handleConfirm = async () => {
    if (selectedUser?.id) {
      await removeUser(selectedUser.id);
      refreshPage();
    }
    setOpenConfirmation(false);
  };

  const handleDeleteClick = (user: UserDTO) => {
    setSelectedUser(user);
    setOpenConfirmation(true);
  };

  const closeConfirmation = () => {
    setSelectedUser(null);
    setOpenConfirmation(false);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
    refreshPage();
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ragione sociale / Nome Cognome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Ruolo</TableCell>
                <TableCell align="center">Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell align="center">
                    <Actions
                      showDelete={false}
                      onEditClick={() => editUser(user)}
                      alignment="center"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="end">
          <Pagination
            color="primary"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
      {openEditUserDialog && (
        <EditUserDialog
          user={selectedUser}
          open={openEditUserDialog}
          onClose={closeEditUserDialog}
          refresh={refreshPage}
        />
      )}
      {openConfirmation && (
        <ConfirmationDialog
          title="Sei sicuro?"
          message="Non potrai tornare indietro"
          confirmText="Sì"
          cancelText="Annulla"
          onConfirm={handleConfirm}
          open={openConfirmation}
          onClose={closeConfirmation}
        />
      )}
    </Grid>
  );
};

export default UsersTable;
