import { Alert, Grid, Snackbar } from '@mui/material';
import { cloneDeep, get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import EditRequestData from '../../components/Request/EditRequestData';
import EditRequestNotes from '../../components/Request/EditRequestNotes';
import {
  getRequest,
  updateSubmissionStatus,
  uploadFile,
} from '../../models/request';
import { AlertMessage } from '../../types/form';
import { REQUEST_STATUS } from '../../utils/constants'; // Importa REQUEST_STATUS


const EditRequestPage = () => {
  const [request, setRequest] = useState<any>(null);
  const { id } = useParams();
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRequest = async () => {
    setLoading(true);
    try {
      const res = await getRequest(id!);
      if (res.status === 200) {
        const { contraentes, notes, ...rest } = res.data;
        const updatedNotes = cloneDeep(notes);
        updatedNotes.push({
          id: 'extra-note',
          message: '',
          status: '',
        });
        const updatedRequest = {
          ...rest,
          contraentes: contraentes,
          notes: updatedNotes,
          notify: false,
        };
        setRequest(updatedRequest);
      } else {
        throw new Error('Unable to retrieve the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il caricamento della pagina.',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };  

  const updateRequest = async (payload: any) => {
    setLoading(true);
    try {
      const res = await updateSubmissionStatus(payload);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        return true;
      } else {
        throw new Error('Unable to update the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Errore durante il carimento della pagina.',
      });
      setShowAlert(true);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (id) {
      fetchRequest();
    }
  }, [id]);

  const handleSubmit = async () => {
    let message = '';
    setLoading(true);
    const currentStatus = get(request, 'status.id', '');
    const notes = get(request, 'notes', []);
    const extraNote = notes.find((n: any) => n.id === 'extra-note');
    let documents = get(request, 'extraDocuments', []);
    if (!isEmpty(documents)) {
      const docResponse: any = await Promise.all(
        documents.map((d: any) => {
          return uploadFile(d.file);
        })
      ).catch(() => []);
      message = isEmpty(docResponse)
        ? 'Richiesta aggiornata con successo ma ci sono problemi con i documenti caricati.'
        : '';
      documents = docResponse.map((res: any) => {
        return {
          id: 'extra-document',
          name: get(res, 'data.originalname', ''),
          path: get(res, 'data.filename', ''),
          mime: get(res, 'data.mimetype', ''),
          description: '',
        };
      });
    }

    const payload = {
      id: get(request, 'id', ''),
      documents: documents,
      status: extraNote?.status,
      notes: notes,
    };
    const isSuccess = await updateRequest(payload);
    if (!!isSuccess) {
      if (message) {
        setAlertMessage({
          severity: 'warning',
          message,
        });
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage(null);
        }, 5000);
      }
      fetchRequest();
    }
  };

  return (
    <Grid container spacing={4}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item className="request" xs={12}>
            {request && (
              <EditRequestData
                request={request}
                setRequest={setRequest}
                submit={handleSubmit}
              />
            )}
          </Grid>
          <Grid item className="fields" xs={12}>
            <EditRequestNotes request={request} setRequest={setRequest} />
          </Grid>
          {/* Mostra messaggio se il campo "Contraente" non è valorizzato */}
          <Grid item xs={12}>
            {request && request.contraentes.length === 0 && (
              <p>Contraente non valorizzato</p>
            )}
          </Grid>
        </>
      )}
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default EditRequestPage;
