import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import { authContext } from '../context/auth';
import LoginPage from '../pages/Auth';
import ForgotPasswordPage from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import Users from '../pages/Admin/Users';
import InviteUser from '../pages/Admin/InviteUser';
import AdminHome from '../pages/Admin/AdminHome';
import UserHome from '../pages/User/UserHome';
import UserRequestPage from '../pages/User/UserRequest';
import EditRequestPage from '../pages/User/EditRequest';
import EditRequestAdmin from '../pages/Admin/EditRequestAdmin';
import AdminUserHome from '../pages/Admin/AdminUserHome';
import Anagrafiche from '../pages/User/Anagrafiche';
import { ROLENAMES } from '../utils/constants';
import BrokerGenerateLinkPage from '../pages/User/GenerateLink';
import AdminGenerateLinkPage from '../pages/Admin/GenerateLink';
import FinalSubmissionPage from '../pages/Guest/FinalSubmission';
import ContraenteDetail from '../pages/User/ContraenteDetail';

const RoutesHOC = () => {
  const { token: authenticated, user } = useContext(authContext);

  if (!authenticated) {
    return (
      <div>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/request/:id" element={<FinalSubmissionPage />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    );
  }

  if (user?.role === ROLENAMES.ADMIN) {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/users" element={<Users />} />
          <Route path="/invite" element={<InviteUser />} />
          <Route path="/admin/anagrafiche" element={<Anagrafiche />} />
          <Route path="/admin/edit/:id" element={<EditRequestAdmin />} />
          <Route path="/generate-link" element={<AdminGenerateLinkPage />} />
          <Route path="/admin/:id" element={<AdminUserHome />} />
          <Route path="/contraente/:id" element={<ContraenteDetail />} />
        </Routes>
      </Layout>
    );
  }

  if (user?.role === ROLENAMES.BROKER) {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<UserHome />} />
          <Route path="/new-request" element={<UserRequestPage />} />
          <Route path="/edit/:id" element={<EditRequestPage />} />
          <Route path="/anagrafiche" element={<Anagrafiche />} />
          <Route path="/generate-link" element={<BrokerGenerateLinkPage />} />
          <Route path="/contraente/:id" element={<ContraenteDetail />} />
        </Routes>
      </Layout>
    );
  }

  return null;
};

export default RoutesHOC;
