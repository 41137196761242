import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import LockIcon from '@mui/icons-material/Lock';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { resetPassword } from '../../models/auth';
import { AlertMessage, ValidationResponse } from '../../types/form';
import { validatePassword } from '../../utils/email';

const TIMER = 1500;
const SUCCESS_MESSAGE = `Password reimpostata con successo. In pochi secondi verrai reindirizzato alla home.`;

const ResetPassword = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const code = query?.get('code');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [codeConfirm, setCodeConfirm] = useState(code);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handlePassword = () => {
    const validationResult: ValidationResponse = validatePassword(
      password,
      confirmPassword
    );

    if (!validationResult.isValid) {
      setAlertMessage({
        severity: 'warning',
        message: validationResult.error,
      });
      setShowAlert(true);
      return;
    }

    resetPassword({ code: codeConfirm, password, confirmPassword })
      .then((res) => {
        setAlertMessage({
          severity: 'success',
          message: SUCCESS_MESSAGE,
        });
        setShowAlert(true);
        setTimeout(() => navigate('/login'), TIMER);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        }
      });
  };

  return (
    <>
      <Typography gutterBottom variant="h4" component="h1" align="center">
        Ripristino password
      </Typography>
      <Card sx={{ maxWidth: 345, paddingTop: '20px' }}>
        <CardContent>
          <Typography gutterBottom align="left">
            Inserisci la nuova password
          </Typography>
          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Nuova Password
            </InputLabel>
            <OutlinedInput
              id="new-password"
              type="password"
              label="Nuova Password"
              onChange={(e) => setPassword(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <KeyRoundedIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Conferma Nuova Password
            </InputLabel>
            <OutlinedInput
              id="confirm-new-password"
              type="password"
              label="Conferma Nuova Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <KeyRoundedIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-confirm-code">
              Codice conferma
            </InputLabel>
            <OutlinedInput
              id="confirm-code"
              type="text"
              label="Codice conferma "
              defaultValue={codeConfirm}
              onChange={(e) => setCodeConfirm(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </CardContent>

        <CardActions>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={handlePassword}
          >
            Reset
          </Button>
        </CardActions>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default ResetPassword;
