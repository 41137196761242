import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
} from '@mui/material';
import { useState } from 'react';
import { AlertMessage } from '../../types/form';
import { updateSubmissionStatus } from '../../models/request';
import LoadingButton from '../Loading/button';
import { RequestType } from '../../types/request';

// Default payload for updating the status of a request
const defaultPayload = {
  notes: [
    {
      id: 'extra-note',
      message: '',
      status: '02_presa_in_carico',
    },
  ],
  status: '02_presa_in_carico',
  notify: true,
};

const ALERT_DURATION = 3000;

type UpdateStatusModalProps = {
  open: boolean;
  onClose: () => void;
  request: RequestType | null;
  refreshFn: Function;
};

const UpdateStatusModal = ({
  open,
  onClose,
  request,
  refreshFn,
}: UpdateStatusModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const updateRequest = async (id: string) => {
    const payload = { id, ...defaultPayload };
    try {
      const res = await updateSubmissionStatus(payload);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        setAlertMessage({
          severity: 'success',
          message: 'Stato della richiesta aggiornato con successo.',
        });
        return true;
      } else {
        throw new Error('Unable to update the request details');
      }
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: "Errore durante l'aggiornamento dello stato della richiesta.",
      });

      setLoading(false);
      return false;
    } finally {
      setShowAlert(true);
      try {
        await refreshFn();
      } catch {}
      setTimeout(() => {
        onClose();
      }, ALERT_DURATION + 1);
    }
  };

  const handleSubmit = async () => {
    if (!request?.id) return;
    setLoading(true);
    await updateRequest(request.id);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Prendi in carico</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          <div style={{ margin: `12px 4px` }}>
            <label>
              Questo metterà la richiesta nello stato "Presa in carico". Vuoi
              procedere?
            </label>
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <Button
          sx={{ width: '100%' }}
          variant="outlined"
          onClick={handleClose}
          disabled={showAlert}
        >
          Annulla
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          disabled={showAlert}
        >
          Sì
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={ALERT_DURATION}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default UpdateStatusModal;
