import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from '@mui/material';
import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { AlertMessage } from '../../types/form';
import { getListOfStatus } from '../../models/request';
import { cloneDeep, get, set } from 'lodash';
import { authContext } from '../../context/auth';
import { ROLENAMES } from '../../utils/constants';
import DropZone from '../DropZone';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

type UpdateModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  request: any;
  setRequest: Function;
};

const docObject = {
  tempId: 'extra-document',
  mime: '',
  name: '',
  path: '',
  id: 0,
  file: undefined,
};

const UpdateModal = ({
  open,
  onClose,
  onSubmit,
  request,
  setRequest,
}: UpdateModalProps) => {
  const { user } = useContext(authContext);
  const role = get(user, 'role', '');
  const [statuslist, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    request?.status?.id
  );
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [documents, setDocuments] = useState([docObject]);

  const handleSubmit = () => onSubmit();

  const handleClose = () => onClose();

  const handleCheck = (e: any) => {
    const checked = !!get(e, 'target.checked', false);
    const updatedRequest = cloneDeep(request);
    set(updatedRequest, 'notify', checked);
    setRequest(updatedRequest);
  };

  const handleStatus = (e: any) => {
    const status = get(e, 'target.value', '');
    setSelectedStatus(status);
    const updatedRequest = cloneDeep(request);
    const notes = get(updatedRequest, 'notes', []);
    const existingNote = notes.findIndex((n: any) => n.id === 'extra-note');
    notes[existingNote].status = status;
    set(updatedRequest, 'notes', notes);
    setRequest(updatedRequest);
  };

  const handleNotes = (e: any) => {
    const updatedRequest = cloneDeep(request);
    const notes = get(updatedRequest, 'notes', []);
    const existingNote = notes.findIndex((n: any) => n.id === 'extra-note');
    notes[existingNote].message = e?.target?.value;
    set(updatedRequest, 'notes', notes);
    setRequest(updatedRequest);
  };

  const handleFileChange = (event: any, documentId: string) => {
    const file = event.target.files[0];
    const copiedDocuments = cloneDeep(documents);
    set(copiedDocuments, `[${documentId}].file`, file);
    setDocuments(copiedDocuments);
    const updatedRequest = cloneDeep(request);
    set(updatedRequest, 'extraDocuments', copiedDocuments);
    setRequest(updatedRequest);
  };

  const handleAddDocument = () => {
    const lastRecord = documents[documents.length - 1];
    const addedObject = cloneDeep(docObject);
    set(addedObject, 'id', lastRecord.id + 1);
    const copiedDocuments = cloneDeep(documents);
    copiedDocuments.push(addedObject);
    setDocuments(copiedDocuments);
  };

  useEffect(() => {
    const runAsync = async () => {
      try {
        const res = await getListOfStatus();
        if (res.status === 200) {
          setStatusList(res.data.data);
        } else {
          throw new Error('Unable to retrieve the status list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    runAsync();
  }, []);

  useLayoutEffect(() => {
    setDocuments([docObject]);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Aggiornamento richiesta</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          {role === ROLENAMES.ADMIN && (
            <>
              <div style={{ margin: `10px 4px`, textTransform: `capitalize` }}>
                <FormControl fullWidth>
                  <InputLabel>Stato</InputLabel>
                  <Select
                    labelId="status-label"
                    value={
                      selectedStatus ||
                      get(
                        statuslist?.find(
                          (s: any) => s.id === request?.status?.id
                        ),
                        'id',
                        ''
                      ) ||
                      ''
                    }
                    label="Stato"
                    onChange={handleStatus}
                  >
                    {statuslist?.map((s: any) => {
                      return (
                        <MenuItem
                          style={{ textTransform: `capitalize` }}
                          key={s.id}
                          value={s.id}
                        >
                          {s.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ margin: `10px 4px` }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Invia email"
                    onChange={handleCheck}
                  />
                </FormGroup>
              </div>
            </>
          )}
          <div style={{ margin: `12px 4px` }}>
            {documents.map((d: any) => (
              <Box
                key={`${d.tempId}-${d.id}`}
                display="flex"
                alignItems="center"
                justifyContent="start"
              >
                <Box flexGrow={1}>
                  <div style={{ width: '100%', margin: `8px 0px` }}>
                    <label style={{ fontSize: 12 }}>
                      {'Ulteriori Documenti a supporto della richiesta'}
                    </label>
                    <DropZone
                      file={d?.file}
                      customFn={(event: any) => handleFileChange(event, d.id)}
                      parent={d}
                      setFile={(acceptedFiles: any) =>
                        handleFileChange(
                          { target: { files: acceptedFiles } },
                          d.id
                        )
                      }
                      customStyle={{
                        border: `2px dashed var(--clr-secondary-2)`,
                        padding: `4px 8px`,
                        width: `100%`,
                      }}
                    />
                  </div>
                </Box>
              </Box>
            ))}
            <Box display="flex" alignItems="center" justifyContent="start">
              <Box flexGrow={1}>
                <div
                  style={{
                    alignItems: `center`,
                    color: `rgba(25, 118, 210, 0.65)`,
                    display: `flex`,
                    fontSize: 48,
                    justifyContent: `center`,
                    margin: `12px 0px`,
                    width: `100%`,
                  }}
                >
                  <AddBoxRoundedIcon
                    style={{
                      cursor: 'pointer',
                    }}
                    fontSize="inherit"
                    color="inherit"
                    onClick={handleAddDocument}
                  />
                </div>
              </Box>
            </Box>
          </div>
          <div style={{ margin: `12px 4px` }}>
            <TextField
              multiline
              rows={4}
              value={
                request?.notes?.find((n: any) => n.id === 'extra-note')
                  ?.message || ''
              }
              placeholder="Note"
              fullWidth
              onChange={handleNotes}
            />
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <Button sx={{ width: '100%' }} variant="outlined" onClick={handleClose}>
          Annulla
        </Button>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Salva
        </Button>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default UpdateModal;
