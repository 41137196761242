import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
export const EXTRA_DOCUMENT_ID = 'extra-document';

export const MACRO_STATUS = {
  TrattativeInCorso: {
    id: 'in_corso',
    name: 'Trattative in corso',
    endPoint: 'incorso',
  },
  TrattativeConcluse: {
    id: 'concluse',
    name: 'Trattative concluse',
    endPoint: 'concluse',
  },
};

export const MENU_END_POINTS = {
  TrattativeInCorso: 'incorso',
  TrattativeConcluse: 'concluse',
};

export const MACRO_NAVS = [
  {
    id: MACRO_STATUS.TrattativeInCorso.name,
    params: MENU_END_POINTS.TrattativeInCorso,
    icon: <PlaylistPlayIcon style={{ color: 'var(--clr-main-blue)' }} />,
  },
  {
    id: MACRO_STATUS.TrattativeConcluse.name,
    params: MENU_END_POINTS.TrattativeConcluse,
    icon: <PlaylistAddCheckIcon style={{ color: 'var(--clr-main-blue)' }} />,
  },
];

export const ROLENAMES = {
  ADMIN: 'admin',
  BROKER: 'broker',
};

export const BROKER_STATUS_TO_HIDE = [
  '05_rifiutata',
  '09_abbandonata',
  '12_finalizzata',
  '00_draft_admin',
];

export const ADMIN_STATUS_TO_HIDE = [
  '05_rifiutata',
  '09_abbandonata',
  '12_finalizzata',
  '00_draft_broker',
];

export const IMPORTI_LIST = [
  '03_importo_da_garantire',
  '15_importo_anticipazione',
  '20_importo_saldo',
  '26_importo_da_garantire_2',
  '24_importo_rimborso',
];

// Creates a Contraente extra document object
export const EXTRA_DOCUMENT_CONTRAENTE = () => ({
  id: crypto.randomUUID(),
  documentId: EXTRA_DOCUMENT_ID,
  name: 'Ulteriori Documenti',
  required: false,
  description: '',
});

// Creates a Contraente extra document object
export const EXTRA_DOCUMENT_REQUEST = () => ({
  id: crypto.randomUUID(),
  documentId: EXTRA_DOCUMENT_ID,
  name: 'Ulteriori Documenti a supporto della richiesta',
  required: false,
  description: '',
});

export const BASE_URL = process.env.REACT_APP_PUBLIC_URL || 'http://localhost:3001';

export enum REQUEST_STATUS {
  DRAFT_ADMIN = '00_draft_admin',
  DRAFT_BROKER = '00_draft_broker',
  DA_PRENDERE_IN_CARICO = '01_da_prendere_in_carico',
  PRESA_IN_CARICO = '02_presa_in_carico',
  RICHIESTA_APPROFONDIMENTI = '03_richiesta_approfondimenti',
  IN_ATTESA_RISCONTRO_COMPAGNIE = '04_in_attesa_riscontro_compagnie',
  RIFIUTATA = '05_rifiutata',
  INVIATA_BOZZA = '06_inviata_bozza',
  IN_ATTESA_REVERSALE = '07_in_attesa_reversale',
  DA_EMETTERE = '08_da_emettere',
  ABBANDONATA = '09_abbandonata',
  IN_ATTESA_COPIE_FIRMATE = '10_in_attesa_copie_firmate',
  DA_INVIARE_ESEMPLARE_BENEFICIARIO = '11_da_inviare_esemplare_beneficiario',
  FINALIZZATA = '12_finalizzata',
}

export enum API_RESPONSE_TYPE {
  SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED',
}
