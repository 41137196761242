import { get, isEmpty } from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';

type DropzoneProps = {
  file: File | null;
  setFile: Function;
  parent?: any;
  customFn?: any;
  customStyle?: any;
};

const DropZone = (props: DropzoneProps) => {
  const { file, setFile, parent, customFn, customStyle } = props;

  return (
    // eventualmente da aggiungere anche il tipo dentro l'array filePaths
    <Dropzone
      onDrop={(acceptedFiles) => {
        if (typeof customFn === 'function') {
          const event = {
            target: {
              files: acceptedFiles,
            },
          };
          return customFn(event);
        }
        setFile(acceptedFiles[0]);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section
          style={
            !isEmpty(customStyle)
              ? customStyle
              : {
                  border: '3px dashed var(--clr-secondary-2)',
                  padding: '1em',
                  width: '100%',
                }
          }
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              {!file && <>{'Trascina file qui.'}</>}
              <b>{!!file && file.name}</b>
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropZone;
