import { useContext } from 'react';
import Divider from '@mui/material/Divider';
import UserIcon from '@mui/icons-material/Person';
import ListIcon from '@mui/icons-material/List';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom';
import { authContext } from '../../context/auth';
import PeopleIcon from '@mui/icons-material/People';
import { MACRO_NAVS, ROLENAMES } from '../../utils/constants';

const Menu = (props) => {
  const { user } = useContext(authContext);
  const { collapsed } = props;

  const renderAdminRichiesteNavs = () => {
    return MACRO_NAVS.map((i) => (
      <ListItem key={`admin-${i.params}`} disablePadding>
        <Tooltip title={collapsed ? i.id : ''}>
          <ListItemButton component={Link} to={`/admin/${i.params}`}>
            <ListItemIcon style={{ minWidth: 'auto' }}>{i.icon}</ListItemIcon>
            {!collapsed && <ListItemText sx={{ px: 1 }} primary={i.id} />}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ));
  };
  return (
    <>
      <Toolbar
        style={{
          padding: '0 6px 0 6px',
          display: 'flex',
          justifyContent: 'start',
          backgroundColor: 'var(--clr-main-blue)',
        }}
      >
        <Link to="/">
          <img src="/mpa-logo.svg" alt="Logo MPA" style={{ height: '58px' }} />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        {user && user.role === ROLENAMES.ADMIN && (
          <>
            {renderAdminRichiesteNavs()}
            <ListItem key="users" disablePadding>
              <Tooltip title={collapsed ? 'Utenti' : ''}>
                <ListItemButton component={Link} to="/users">
                  <ListItemIcon
                    style={{ minWidth: 'auto', color: 'var(--clr-main-blue)' }}
                  >
                    <UserIcon />
                  </ListItemIcon>
                  {!collapsed && <ListItemText sx={{ px: 1 }} primary="Utenti" />}
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key="admin-anagrafiche" disablePadding>
              <Tooltip title={collapsed ? 'Anagrafiche' : ''}>
                <ListItemButton component={Link} to="/admin/anagrafiche">
                  <ListItemIcon
                    style={{ minWidth: 'auto', color: 'var(--clr-main-blue)' }}
                  >
                    <PeopleIcon />
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText sx={{ px: 1 }} primary="Anagrafiche" />
                  )}
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </>
        )}

        {user && user.role === ROLENAMES.BROKER && (
          <>
            <ListItem key="user-requests" disablePadding>
              <Tooltip title={collapsed ? 'Richieste' : ''}>
                <ListItemButton component={Link} to="/">
                  <ListItemIcon
                    style={{ minWidth: 'auto', color: 'var(--clr-main-blue)' }}
                  >
                    <ListIcon />
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText sx={{ px: 1 }} primary="Richieste" />
                  )}
                </ListItemButton>
              </Tooltip>
            </ListItem>

            <ListItem key="user-anagrafiche" disablePadding>
            <Tooltip title={collapsed ? 'Anagrafiche' : ''}>
                <ListItemButton component={Link} to="/anagrafiche">
                  <ListItemIcon
                    style={{ minWidth: 'auto', color: 'var(--clr-main-blue)' }}
                  >
                    <PeopleIcon />
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText sx={{ px: 1 }} primary="Anagrafiche" />
                  )}
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

export default Menu;
