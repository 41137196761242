import {
  Alert,
  Snackbar,
  Grid,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { authContext } from '../../context/auth';
import { getFields } from '../../models/request';
import { AlertMessage } from '../../types/form';
import {
  BeneficiarioField,
  beneficiarioFields,
  RequestField,
} from '../../types/request';
import DocumentTable from './DocumentTable';
import UpdateModal from './UpdateModal';
import { cloneDeep, get, set } from 'lodash';
import { validateBeneficiario, validateFieldEdit } from '../../utils/validate';
import { ROLENAMES } from '../../utils/constants';
import Actions from '../Actions';

const statuses = [
  '01_da_prendere_in_carico',
  '06_inviata_bozza',
  '07_in_attesa_reversale',
  '08_da_emettere',
  '10_in_attesa_copie_firmate',
  '11_da_inviare_esemplare_beneficiario',
];

type EditRequestDataProps = {
  request: any;
  setRequest: Function;
  submit?: () => void;
  submitInfo?: (editedRequest: any) => void;
};
const EditRequestData = ({
  request,
  setRequest,
  submit,
  submitInfo,
}: EditRequestDataProps) => {
  const { user } = useContext(authContext);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fields, setFields] = useState<RequestField[]>([]);

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [isEditable, setIsEditable] = useState(false);
  const [editedRequest, setEditedRequest] = useState(cloneDeep(request));

  const isAdmin = get(user, 'role', '') === ROLENAMES.ADMIN;

  const handleSubmit = () => {
    if (typeof submit === 'function') {
      submit();
    }
  };

  const handleSubmitInfo = () => {
    if (typeof submitInfo === 'function') {
      submitInfo(editedRequest);
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const res = await getFields(request.submission_type.id);
        if (res.status === 200) {
          setFields(res.data.data);
        } else {
          throw new Error('Unable to retrieve the field list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    fetchFields();
  }, []);

  const handleFieldChange = (value: string, field: RequestField) => {
    let updatedFieldValues = cloneDeep(editedRequest.fields);

    const fv = updatedFieldValues.findIndex((f: any) => f.field.id === field.id);

    if (fv >= 0) {
      set(updatedFieldValues, `[${fv}].value`, formatValue(field, value));
    } else {
      console.error('Field not found');
      return;
    }

    const updatedRequest = {
      ...editedRequest,
      fields: updatedFieldValues,
    };

    const updatedFields = validateFieldEdit(updatedRequest, field, fields)
    setFields(updatedFields);

    // Revalidate dependent child fields if necessary
    fields.forEach((potentialChildField) => {
      if (potentialChildField.conditional?.fieldId === field.id) {
        setFields(
          validateFieldEdit(
            updatedRequest,
            potentialChildField,
            fields,
          ));
      }
    });
    setEditedRequest(updatedRequest);
  };

  const renderValue = (field: any) =>
    editedRequest?.fields?.find((f: any) => f.field.id === field.id)?.value ||
    '';

  const formatValue = (field: any, value: any) => {
    if (field.check) {
      if (field.check.type === 'numberGreaterThanZero' || field.check.type === 'greaterThan') {
        // Remove any existing commas or symbols from the string and convert it to a number
        const valueToFormat = value.replace(/[. €]/g, '');
        // Convert the number back to a string with commas added every thousand
        return valueToFormat === '' ? '' : valueToFormat.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
      }
    }
    return value;
  }

  const isValid = () => {
    const visibleFields = fields.filter((field) => {
      if (
        field.conditional &&
        field.conditional.fieldId !== '03_importo_da_garantire'
      ) {
        const { fieldId, fieldValue } = field.conditional;
        const reqFieldObj = editedRequest.fields.find(
          (f: any) => f.field.id === fieldId
        );
        const fieldObj = fields.find(
          (f: any) => f.id === reqFieldObj?.field?.id
        );

        if (!fieldValue && reqFieldObj?.value && !fieldObj?.error) {
          return true;
        }

        return reqFieldObj?.value === fieldValue;
      }

      return true;
    });

    const hasErrors =
      visibleFields.some((field) => field.error) ||
      beneficiarioFields.some((field) => field.error);

    const hasMissingRequired = visibleFields.some((field) => {
      const fieldObj = editedRequest.fields?.find(
        (f: any) => f.field.id === field.id
      );

      const value = fieldObj?.value ?? '';

      return field.required && !value;
    });

    const validation = !hasErrors && !hasMissingRequired;

    return validation;
  };

  const renderEditButton = (editable: boolean) => {
    return editable ? (
      <>
        <div style={{ margin: `2px 8px` }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setIsEditable(false);
              setEditedRequest(request);

              beneficiarioFields.forEach((field) => {
                field.error = false;
                field.helperText = '';
              });

              const updatedFields = cloneDeep(fields);
              updatedFields.forEach((field) => {
                field.error = false;
                field.helperText = '';
              });

              setFields(updatedFields);
            }}
          >
            Annulla
          </Button>
        </div>
        <div style={{ margin: `2px 8px` }}>
          <Button
            variant="contained"
            onClick={handleSubmitInfo}
            disabled={!isValid()}
          >
            Salva
          </Button>
        </div>
      </>
    ) : (
      <div style={{ margin: `2px 8px` }}>
        <Button variant="outlined" onClick={() => setIsEditable(true)}>
          Modifica
        </Button>
      </div>
    );
  };

  const renderButtons = () => {
    const currentStatus = get(request, 'status.id', '');

    if (isAdmin || currentStatus === '03_richiesta_approfondimenti') {
      // Admin view or Status: 03_richiesta_approfondimenti
      return (
        <div style={{ margin: `2px 8px` }}>
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            Aggiorna
          </Button>
        </div>
      );
    } else if (currentStatus === statuses[0]) {
      // Broker view - Status: 01_da_prendere_in_carico
      return (
        <>
          <div style={{ margin: `2px 8px` }}>
            <Button
              variant="contained"
              onClick={() => {
                const updatedRequest = cloneDeep(request);

                const notes = get(updatedRequest, 'notes', []);
                const existingNote = notes.findIndex(
                  (n: any) => n.id === 'extra-note'
                );
                notes[existingNote].status = statuses[0];
                set(updatedRequest, 'notes', notes);

                setRequest(updatedRequest);
                setOpenDialog(true);
              }}
            >
              Invia messaggio
            </Button>
          </div>
        </>
      );
    } else if (currentStatus === statuses[1]) {
      // Broker view - Status: 06_inviata_bozza
      return (
        <>
          <div style={{ margin: `2px 8px` }}>
            <Button
              variant="contained"
              onClick={() => {
                const updatedRequest = cloneDeep(request);

                const notes = get(updatedRequest, 'notes', []);
                const existingNote = notes.findIndex(
                  (n: any) => n.id === 'extra-note'
                );
                notes[existingNote].status = statuses[0];
                set(updatedRequest, 'notes', notes);

                setRequest(updatedRequest);
                setOpenDialog(true);
              }}
            >
              Richiedi modifiche
            </Button>
          </div>
          <div style={{ margin: `2px 8px` }}>
            <Button
              variant="contained"
              onClick={() => {
                const updatedRequest = cloneDeep(request);

                const notes = get(updatedRequest, 'notes', []);
                const existingNote = notes.findIndex(
                  (n: any) => n.id === 'extra-note'
                );
                notes[existingNote].status = statuses[3];
                set(updatedRequest, 'notes', notes);

                setRequest(updatedRequest);
                setOpenDialog(true);
              }}
            >
              Richiedi emissione
            </Button>
          </div>
        </>
      );
    } else if (currentStatus === statuses[2]) {
      // Broker view - Status: 07_in_attesa_reversale
      return (
        <div style={{ margin: `2px 8px` }}>
          <Button
            variant="contained"
            onClick={() => {
              const updatedRequest = cloneDeep(request);

              const notes = get(updatedRequest, 'notes', []);
              const existingNote = notes.findIndex(
                (n: any) => n.id === 'extra-note'
              );
              notes[existingNote].status = statuses[3];
              set(updatedRequest, 'notes', notes);

              setRequest(updatedRequest);
              setOpenDialog(true);
            }}
          >
            Invia reversale
          </Button>
        </div>
      );
    } else if (currentStatus === statuses[4]) {
      // Broker view - Status: 10_in_attesa_copie_firmate
      return (
        <>
          <div style={{ margin: `2px 8px` }}>
            <Button
              variant="contained"
              onClick={() => {
                const updatedRequest = cloneDeep(request);

                const notes = get(updatedRequest, 'notes', []);
                const existingNote = notes.findIndex(
                  (n: any) => n.id === 'extra-note'
                );
                notes[existingNote].status = statuses[0];
                set(updatedRequest, 'notes', notes);

                setRequest(updatedRequest);
                setOpenDialog(true);
              }}
            >
              Invia messaggio
            </Button>
          </div>
          <div style={{ margin: `2px 8px` }}>
            <Button
              variant="contained"
              onClick={() => {
                const updatedRequest = cloneDeep(request);

                const notes = get(updatedRequest, 'notes', []);
                const existingNote = notes.findIndex(
                  (n: any) => n.id === 'extra-note'
                );
                notes[existingNote].status = statuses[5];
                set(updatedRequest, 'notes', notes);

                setRequest(updatedRequest);
                setOpenDialog(true);
              }}
            >
              Invia copie firmate
            </Button>
          </div>
        </>
      );
    }

    return null; // Default case - no buttons rendered
  };

  const openWindow = (id: string) => {
    window.open(`/contraente/${id}`, '_blank');
  };

  return (
    <div
      style={{
        borderRadius: 12,
        margin: `0 24px`,
        padding: `40px 4px`,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <h1>Riepilogo Richiesta</h1>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `flex-end`,
                }}
              >
                {renderButtons()}
                {user?.role === ROLENAMES.ADMIN && renderEditButton(isEditable)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <strong>Stato:</strong>
                    </InputAdornment>
                  ),
                }}
                disabled
                fullWidth
                value={request?.status?.name}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <strong>Data Creazione:</strong>
                    </InputAdornment>
                  ),
                }}
                disabled
                fullWidth
                value={new Date(request.created).toLocaleDateString('it-IT')}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
          </Grid>
        </Grid>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <strong>Tipologia richiesta:</strong>
                    </InputAdornment>
                  ),
                }}
                disabled
                fullWidth
                value={editedRequest.submission_type?.name}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
          </Grid>
        </Grid>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            {editedRequest.contraentes.map((c: any) => {
              const value =
                get(c, 'ragione_sociale') ||
                get(c, 'contraente.ragione_sociale', '') ||
                '';
              return (
                <Grid key={c?.id} item xs={12} md={6} sx={{display: 'flex'}}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <strong>
                            {editedRequest?.is_ati
                              ? c.is_mandatario
                                ? 'Mandataria:'
                                : 'Mandante:'
                              : 'Contraente:'}
                          </strong>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                    fullWidth
                    value={value}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#757575',
                      },
                    }}
                  />
                  <Actions
                    onEditClick={() => openWindow(c.contraente.id)}
                    showEdit={true}
                    showDelete={false}
                    showGenerateLink={false}
                    requestId={request?.id}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={11} className="anagrafiche">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={{color: '#757575'}}>Dati Beneficiario</Typography>
            </Grid>
            {beneficiarioFields.map((field: BeneficiarioField) => (
              <Grid key={field.id} item xs={12} md={6}>
                <TextField
                  label={field?.label}
                  InputLabelProps={{ shrink: true }}
                  disabled={!isEditable}
                  fullWidth
                  value={
                    editedRequest[`beneficiario_${field.id}`]
                      ? editedRequest[`beneficiario_${field.id}`]
                      : ''
                  }
                  required={field.required}
                  error={field.error}
                  helperText={field.helperText}
                  onChange={(e: any) => {
                    const value = get(e, 'target.value', '');
                    field.value = value;
                    const updatedReq = cloneDeep(editedRequest);
                    validateBeneficiario(value, field);
                    set(updatedReq, `beneficiario_${field.id}`, value);
                    setEditedRequest(updatedReq);
                  }}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#757575',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={11} className="fields">
          <Grid
            container
            spacing={2}
            justifyContent="start"
            alignItems="center"
          >
          <Grid item xs={12}>
            <Typography variant="h5" sx={{color: '#757575'}}>Dati richiesta</Typography>
          </Grid>
            {fields
              .filter((field) => {
                if (
                  !field.conditional ||
                  field.conditional.fieldId === '03_importo_da_garantire'
                ) {
                  return true;
                }
                const { fieldId, fieldValue } = field.conditional;
                const reqFieldObj = editedRequest.fields.find(
                  (f: any) => f.field.id === fieldId
                );
                const fieldObj = fields.find(
                  (f: any) => f.id === reqFieldObj?.field?.id
                );

                return reqFieldObj?.value === fieldValue;
              })
              .map((field) => {
                return (
                  <Grid item xs={12} md={6} key={field.id}>
                    {field.type === 'dropdown' ? (
                      <TextField
                        select
                        label={field.name}
                        InputLabelProps={{ shrink: true }}
                        value={renderValue(field)}
                        onChange={(e) =>
                          handleFieldChange(e.target.value, field)
                        }
                        fullWidth
                        required={field.required}
                        error={field.error}
                        helperText={field.helperText}
                        disabled={!isEditable}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#757575',
                          },
                        }}
                      >
                        {field.check?.options?.map((option, index) => (
                          <MenuItem key={index} value={get(option, 'id', '')}>
                            {get(option, 'value', '')}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.name}
                        InputLabelProps={{ shrink: true }}
                        type={field.type}
                        value={renderValue(field)}
                        onChange={(e) =>
                          handleFieldChange(e.target.value, field)
                        }
                        fullWidth
                        error={field.error}
                        helperText={field.helperText}
                        disabled={!isEditable}
                        required={field.required}
                        multiline={
                          field.check?.type === 'textarea' ? true : false
                        }
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#757575',
                          },
                        }}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        <Grid item xs={11} className="docs">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <DocumentTable documents={request.documents} request={request}/>
            </Grid>
          </Grid>
        </Grid>
        <UpdateModal
          open={openDialog}
          onClose={closeDialog}
          onSubmit={handleSubmit}
          request={request}
          setRequest={setRequest}
        />
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default EditRequestData;
