import {
  Grid,
  Button,
  Typography,
  Card,
  Menu,
  MenuItem,
  TextField,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import AdminRequests from '../../components/RequestsTable/AdminRequests';
import { useNavigate, useParams } from 'react-router-dom';
import SideMenu from '../../components/Request/SideMenu';
import { useState } from 'react';
import { Status } from '../../types/request';
import ExportModal from '../../components/Request/ExportModal';
import { MACRO_NAVS } from '../../utils/constants';
import { isEmpty } from 'lodash';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const AdminUserHome = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const macroFromNav = MACRO_NAVS.find((i) => i.params === id);
  const [selectedStatus, setSelectedStatus] = useState<Status>(null);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearFilter = () => {
    setSearchTerm('');
    setTriggerSearch(!triggerSearch);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleGenerateLink = () => {
    navigate('/generate-link');
    handleMenuClose();
  };

  const openDialog = () => {
    setExportModalOpen(true);
    handleMenuClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setTriggerSearch(!triggerSearch);
    }
  };

  const closeDialog = () => setExportModalOpen(false);

  return isEmpty(macroFromNav) ? (
    <Grid container justifyContent="center" gap={2} sx={{ padding: '40px' }}>
      <h1>Not Found</h1>
    </Grid>
  ) : (
    <Grid container justifyContent="center" gap={2} sx={{ padding: '40px' }}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Card
            sx={{
              padding: '16px 0',
              width: '600px',
              boxShadow: 'none',
              border: '1px solid #BDBDBD',
              backgroundColor: 'var(--clr-bg-grey)',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              align="center"
              color="var(--clr-main-blue)"
              fontWeight={600}
            >
              Elenco {macroFromNav?.id}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop="80px">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              columnGap={2}
              paddingRight={4}
            >
              <SideMenu
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                selectedMacro={macroFromNav?.id}
              />
              <Box display="flex" columnGap={2}>
                <TextField
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Filtra per nome Contraente"
                  style={{ width: '250px' }}
                  onKeyDown={handleKeyDown}
                />
                <Button
                  onClick={() => setTriggerSearch(!triggerSearch)}
                  variant="outlined"
                  style={{ width: '120px' }}
                >
                  Cerca
                </Button>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Tooltip title="Pulisci filtro">
                    <IconButton onClick={handleClearFilter}>
                      <FilterListOffIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleMenuClick}>
              Azioni
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={openDialog}>Esporta</MenuItem>
              <MenuItem onClick={handleGenerateLink}>Genera link</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>

      <Grid container gap={2} justifyContent="center">
        <Grid item xs={12}>
          {selectedStatus && (
            <AdminRequests
              selectedStatus={selectedStatus}
              searchTerm={searchTerm}
              triggerSearch={triggerSearch}
            />
          )}
        </Grid>
      </Grid>
      <ExportModal open={exportModalOpen} onClose={closeDialog} />
    </Grid>
  );
};

export default AdminUserHome;
