import {
  Alert,
  Snackbar,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { getDocuments, getFields, getRequestTypes } from '../../models/request';
import { AlertMessage } from '../../types/form';
import { beneficiarioFields, Doc, RequestField } from '../../types/request';
import { RequestType as reqType } from '../../types/request';

type RecapProps = {
  request: any;
};
const Recap = ({ request }: RecapProps) => {
  const [requestTypes, setRequestTypes] = useState<reqType[]>([
    { id: '', name: '', subTypes: [] },
  ]);
  const [fields, setFields] = useState<RequestField[]>([]);

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Doc[]>([]);

  useEffect(() => {
    const fetchRequestTypes = async () => {
      try {
        const res = await getRequestTypes();
        if (res.status === 200) {
          setRequestTypes(res.data.data);
        } else {
          throw new Error('Unable to retrieve request types.');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    fetchRequestTypes();
  }, []);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const res = await getFields(
          request.submissionTypeSubTypeId || request.submissionTypeId
        );
        if (res.status === 200) {
          setFields(res.data.data);
        } else {
          throw new Error('Unable to retrieve the field list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    const fetchDocuments = async () => {
      try {
        const res = await getDocuments(
          request.submissionTypeSubTypeId || request.submissionTypeId
        );
        if (res.status === 200) {
          setDocuments(res.data.data);
        } else {
          throw new Error('Unable to retrieve the documents list');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    fetchFields();
    fetchDocuments();
  }, []);

  const requestType = requestTypes.find(
    (type) => type.id === request.submissionTypeId
  );

  const subType = requestType?.subTypes.find(
    (subType) => subType.id === request.submissionTypeSubTypeId
  );

  const mandataria = request.contraentes.find(
    (item: any) => item?.is_mandataria === true
  );

  //Beneficiario fields to display
  const fieldsToDisplay = beneficiarioFields
    .filter((field) => field.id !== 'ragione_sociale')
    .map((field) => field.id);

  const renderValue = (field: any) => {
    const fieldValue = request.fieldValues.find(
      (fv: any) => fv.id === field.id
    )?.value;

    // If the field is a dropdown, renders the value instead of the ID
    const dropDownValue =
      field.type === 'dropdown'
        ? field?.check?.options?.find((option: any) => option.id === fieldValue)
            ?.value
        : null;

    return dropDownValue ?? fieldValue;
  };

  return (
    <Grid container spacing={8} justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h4" align="center">
            Riepilogo Richiesta
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Grid container spacing={2} justifyContent="start" alignItems="center">
          <Grid item xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <strong>Tipologia richiesta:</strong>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              value={
                requestType?.name +
                (request.submissionTypeSubTypeId ? ` (${subType?.name})` : '')
              }
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#757575',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <strong>Contraente:</strong>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              value={mandataria?.ragione_sociale}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#757575',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Grid container spacing={2} justifyContent="start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" sx={{color: '#757575'}}>Dati Beneficiario</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <strong>Beneficiario:</strong>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              value={request['beneficiario_ragione_sociale']}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#757575',
                },
              }}
            />
          </Grid>

          {fieldsToDisplay.map((fieldId) => (
            <Grid item xs={6} key={fieldId}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <strong>
                        {
                          beneficiarioFields.find(
                            (field) => field.id === fieldId
                          )?.label
                        }
                        :
                      </strong>
                    </InputAdornment>
                  ),
                }}
                disabled
                fullWidth
                value={
                  request[`beneficiario_${fieldId}`]
                    ? request[`beneficiario_${fieldId}`]
                    : ' '
                }
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Grid container spacing={2} justifyContent="start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" sx={{color: '#757575'}}>Dati richiesta</Typography>
          </Grid>

          {fields.map((field) => (
            <Grid item xs={field.name === 'Note' ? 12 : 6} key={field.id}>
              <TextField
                label={field.name}
                InputLabelProps={{ shrink: true }}
                disabled
                value={renderValue(field)}
                fullWidth
                multiline={field.check?.type === 'textarea' ? true : false}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={10} className="docs">
        <Grid container spacing={2} justifyContent="start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4">Allegati</Typography>
          </Grid>
          {request.documents.map((document: any, index: number) => (
            <Grid item xs={6} key={`key-${document.id}-${index}`}>
              <TextField
                label={
                  documents.find((doc: any) => doc.id === document.id)?.name ||
                  ' '
                }
                disabled
                value={document.name}
                fullWidth
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#757575',
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Recap;
