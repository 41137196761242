import { ValidationResponse } from '../types/form';

const isEmailValid = (email: string): boolean => {
  // eslint-disable-next-line
  const emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailRegex.test(email);
};

const sanitizeEmail = (email: string): string => {
  const result = email ? email.trim().toLowerCase() : '';
  return result;
};

const validatePassword = (
  password: string,
  confirmPassword?: string
): ValidationResponse => {
  if (confirmPassword && password !== confirmPassword) {
    return { isValid: false, error: 'Le password sono diverse' };
  }

  if (password.length < 5) {
    return {
      isValid: false,
      error: 'La Password deve essere di almeno 5 caratteri',
    };
  }

  return { isValid: true };
};

export { isEmailValid, sanitizeEmail, validatePassword };
