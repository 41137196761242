import {
  Grid,
  Autocomplete,
  TextField,
  Button,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { IndexKind } from 'typescript';
import {
  addContraente,
  getContraenteList,
  getContraenteDocumentTypeList,
} from '../../../models/request';
import { AlertMessage } from '../../../types/form';
import { Contraente } from '../../../types/request';
import AddContraenteDialog from '../../Request/AddContraente';
import { get, isUndefined } from 'lodash';

type SelectContraenteProps = {
  request: any;
  setRequest: Function;
  isValid: any;
  setIsValid: Function;
};

const STEP = 'step_1';

const SelectContraente = ({
  request,
  setRequest,
  isValid,
  setIsValid,
}: SelectContraenteProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [contraenteDocTypes, setContraenteDocTypes] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [contraenti, setContraenti] = useState<any>({
    mandataria: [],
  });

  // hack** added callback to trick the UI to wait for the state
  const fetchContraenteDocTypes = async (callback?: Function) => {
    try {
      const res = await getContraenteDocumentTypeList();
      if (res.status !== 200) {
        throw new Error("Errore durante l'aggiunta del Contraente.");
      }
      setContraenteDocTypes(get(res, 'data.data', []));
    } catch (error) {
      setAlertMessage({
        severity: 'warning',
        message: 'Non è stato possibile aggiungere il Contraente.',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
      !isUndefined(callback) && callback();
    }
  };

  const handleAggiungi = () => {
    setLoading(true);
    const runAsync = async () => {
      await fetchContraenteDocTypes(() => setOpenDialog(true));
    };
    runAsync();
  };

  useEffect(() => {
    const validation = request.is_ati
      ? request.contraentes.some((item: any) => !item.is_mandataria) &&
        request.contraentes.some((item: any) => item.is_mandataria)
      : request.contraentes.some((item: any) => item.is_mandataria);

    setIsValid({ ...isValid, [STEP]: validation });
  }, [contraenti, request.is_ati]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (fields: any) => {
    try {
      const res = await addContraente(fields);

      if (res.status === 201) {
        setAlertMessage({
          severity: 'success',
          message: 'Contraente aggiunto con successo.',
        });
        setShowAlert(true);
        setTimeout(() => {
          closeDialog();
        }, 3000);
      } else {
        throw new Error("Errore durante l'aggiunta del Contraente.");
      }
    } catch (error) {
      setAlertMessage({
        severity: 'warning',
        message: 'Non è stato possibile aggiungere il Contraente.',
      });
      setShowAlert(true);
    }
  };

  const handleMandatariaFetch = async (newInputValue: any) => {
    const res = await getContraenteList(newInputValue);
    if (res.status === 200) {
      const values = res.data.data.filter(
        (item: any) =>
          !Object.values(request.contraentes)
            .flatMap((c: any) => (c ? c.id : null))
            .includes(item.id)
      );
      setContraenti({
        ...contraenti,
        mandataria: values,
      });
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8}>
        <Autocomplete
          onOpen={async () => {
            if (!contraenti.mandataria || contraenti.mandataria.length === 0) {
              await handleMandatariaFetch('');
            }
          }}
          disablePortal
          id="combo-box-contraente"
          options={contraenti['mandataria'] || []}
          filterOptions={(x) => x}
          isOptionEqualToValue={(
            option: Contraente | null,
            value: Contraente | null
          ) => option?.id === value?.id}
          getOptionLabel={(option: Contraente | null) =>
            option?.ragione_sociale ?? ''
          }
          value={(request.contraentes && request.contraentes[0]) || null}
          onChange={(_, value: Contraente | null) => {
            const newRequest = { ...request };
            if (!newRequest.contraentes) {
              newRequest['contraentes'] = [];
            }
            if (value === null) {
              newRequest.contraentes.splice(0, 1);
            } else {
              newRequest.contraentes[0] = {
                ragione_sociale: value?.ragione_sociale,
                id: value?.id,
                is_mandataria: true,
              };
            }
            setRequest({ ...newRequest });
          }}
          onInputChange={async (_, newInputValue: any) => {
            await handleMandatariaFetch(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={request.is_ati ? 'Mandataria' : 'Contraente'}
              variant="outlined"
              sx={{ mt: 2, mb: 1, width: '100%' }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Button onClick={handleAggiungi}>Aggiungi Nuova Anagrafica</Button>
      </Grid>

      {openDialog && (
        <AddContraenteDialog
          open={openDialog}
          onClose={closeDialog}
          onSubmit={handleSubmit}
          documentTypes={contraenteDocTypes}
        />
      )}
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default SelectContraente;
