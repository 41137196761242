import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Alert,
  Snackbar,
} from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { getRequestTypes } from '../../models/request';
import { AlertMessage } from '../../types/form';
import { RequestType as reqType } from '../../types/request';
import { EXTRA_DOCUMENT_REQUEST } from '../../utils/constants';

type RequestTypeProps = {
  request: any;
  setRequest: Function;
  isValid: any;
  setIsValid: Function;
};

const STEP = 'step_3';

const RequestType = ({
  request,
  setRequest,
  isValid,
  setIsValid,
}: RequestTypeProps) => {
  const [requestTypes, setRequestTypes] = useState<reqType[]>([
    { id: '', name: '', subTypes: [] },
  ]);

  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const fetchRequestTypes = async () => {
      try {
        const res = await getRequestTypes();
        if (res.status === 200) {
          setRequestTypes(res.data.data);
        } else {
          throw new Error('Unable to retrieve request types.');
        }
      } catch (error) {
        setAlertMessage({
          severity: 'error',
          message: 'Errore durante il carimento della pagina.',
        });
        setShowAlert(true);
      }
    };

    fetchRequestTypes();
  }, []);

  useEffect(() => {
    const condition0 = !isEmpty(request.submissionTypeId);

    const condition1 =
      condition0 &&
      isEmpty(
        requestTypes.find((type) => type.id === request.submissionTypeId)
          ?.subTypes
      );

    const condition2 =
      requestTypes.some((field) => !isEmpty(field.subTypes)) &&
      !!request.submissionTypeSubTypeId;

    const validation = condition2 || condition1;

    setIsValid({ ...isValid, [STEP]: validation });
  }, [request.submissionTypeId, request.submissionTypeSubTypeId]);

  return (
    <Grid container spacing={10} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={4}>
        <FormControl sx={{ mt: 2, mb: 1, width: '100%' }} variant="outlined">
          <InputLabel id="select-type-label">Tipologia Richiesta</InputLabel>
          <Select
            labelId="select-type-label"
            id="select-type"
            value={request.submissionTypeId || ''}
            label="Tipologia Richiesta"
            onChange={(e) => {
              const updatedRequest = cloneDeep(request);
              setRequest({
                ...updatedRequest,
                submissionTypeId: e.target.value,
                submissionTypeSubTypeId: '',
                fieldValues: [],
                documents: [],
                extraDocuments: [EXTRA_DOCUMENT_REQUEST()],
                selectedFiles: [],
              });
            }}
          >
            {requestTypes.map((requestType) => (
              <MenuItem key={requestType.id} value={requestType.id}>
                {requestType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {(requestTypes.find((type) => type.id === request.submissionTypeId)
        ?.subTypes?.length || 0) > 0 && (
        <Grid item sm={4}>
          <RadioGroup
            aria-label="subType"
            name="subType"
            value={request.submissionTypeSubTypeId || ''}
            onChange={(e) => {
              const updatedRequest = cloneDeep(request);

              setRequest({
                ...updatedRequest,
                submissionTypeSubTypeId: e.target.value,
                fieldValues: [],
                documents: [],
                extraDocuments: [EXTRA_DOCUMENT_REQUEST()],
                selectedFiles: [],
              });
            }}
          >
            {requestTypes
              .find((type: any) => type.id === request.submissionTypeId)
              ?.subTypes?.map((subType: any) => (
                <FormControlLabel
                  key={subType.id}
                  value={subType.id}
                  control={<Radio />}
                  label={subType.name}
                />
              ))}
          </RadioGroup>
        </Grid>
      )}

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default RequestType;
