import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button
} from '@mui/material';
import Download from '@mui/icons-material/Download';
import { get } from 'lodash';
import moment from 'moment';
import downloadjs from 'downloadjs';
import api from '../../models/api';

interface DocumentType {
  id: string;
  name: string;
}

interface Document {
  id: string;
  name: string;
  mime?: string;
  date: string;
  document: DocumentType;
  created?: string;
  path?: string;
}

type DocumentTableProps = {
  documents: Document[];
  request: any;
};


const DocumentTable = ({ documents, request }: DocumentTableProps) => {
  const renderCreated = (document: Document) => {
    const created = get(document, 'created', '');
    return !!created ? moment(created).format('DD/MM/yyyy') : '';
  };
  
  const downloadHandler = (document?: Document) => {
    if(document)
      downloadOne(document);
    else 
      downloadZip();
  };

  const downloadOne = async (document: Document) => {
    try {
      const url = '/request/download/' + document.id;

      const response = await api.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      downloadjs(blob, document.name);
    } catch (error) {
      console.error('Error downloading file', error);
    }
  };

  const downloadZip = async () => {
    try {
      const url = '/request/submission/download/' + request.id;
      const response = await api.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/zip' });
      downloadjs(blob, `Richiesta-${request.beneficiario_ragione_sociale.trim()}-${request.contraentes[0].contraente.ragione_sociale.trim()}.zip`);
    } catch (error) {
      console.error('Error downloading files', error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 0, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Table size="small" sx={{ border: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell>Tipologia documento </TableCell>
            <TableCell>Nome documento</TableCell>
            <TableCell>Data inserimento</TableCell>
            <TableCell>Azioni</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document: Document, index: number) => (
            <TableRow key={`key-${document.id}-${index}`}>
              <TableCell>{document.document.name}</TableCell>
              <TableCell>{document.name}</TableCell>
              <TableCell>{renderCreated(document)}</TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => downloadHandler(document)}
                >
                  <Download />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
            variant="contained"
            color="primary"
            onClick={() => downloadHandler()}
            sx={{ margin: '1rem' }}
            >
            Scarica documenti
          </Button>
    </TableContainer>
  );
};

export default DocumentTable;
