import { BrowserRouter } from 'react-router-dom';
// import { Button } from '@mui/material';
import { AuthProvider } from './context/auth';
import { AxiosComponent } from './models/api';
import RoutesHOC from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// const ErrorFallback = ({ error, resetErrorBoundary }) => {
//   return (
//     <div role="alert" style={{ marginTop: '60px', marginLeft: '20px' }}>
//       <p>Qualcosa è andato storto</p>
//       <Button onClick={resetErrorBoundary} variant="outlined">
//         Torna indietro
//       </Button>
//       <small>
//         <pre>{error.message}</pre>
//         <pre>{error.stack}</pre>
//       </small>
//     </div>
//   );
// };

const theme = createTheme({
  palette: {
    primary: {
      main: '#263B7D',
    },
  },
});

function DateAdapterHOC({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {children}
    </LocalizationProvider>
  );
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <DateAdapterHOC>
              <AxiosComponent />
              <RoutesHOC />
            </DateAdapterHOC>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
