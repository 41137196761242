import { useNavigate } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import Login from '../../components/Login';

const LoginPage = () => {
  const navigate = useNavigate();

  const handleForgotPassword = (path: string) => {
    navigate(path);
  };

  return (
    <Container>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: '2em' }}
        gap={4}
      >
        <Login handleForgotPassword={handleForgotPassword} />
      </Grid>
    </Container>
  );
};

export default LoginPage;
