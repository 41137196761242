import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../models/auth';
import { authContext } from '../../context/auth';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material';
import { AlertMessage } from '../../types/form';

import { isEmailValid, sanitizeEmail } from '../../utils/email';

interface LoginProps {
  handleForgotPassword: (path: string) => void;
}

const Login = (props: LoginProps) => {
  const navigate = useNavigate();
  const { setToken, setRefresh } = useContext(authContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleForgotPassword = () => {
    props.handleForgotPassword('/forgot-password');
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    if (!email || !password) return;
    // Validate and sanitize the email input
    const sanitizedEmail = sanitizeEmail(email);
    if (!isEmailValid(sanitizedEmail)) {
      setAlertMessage({
        severity: 'warning',
        message: 'Indirizzo email non valido',
      });
      setShowAlert(true);

      return;
    }

    login({ user: sanitizedEmail, pass: password }).then((res: any) => {
      if (res.status === 201) {
        const json = res.data;
        setToken(json.authorization);
        setRefresh(json.refresh);
        navigate('/');
      }
    });
  };

  const handleKeyDown = (e: any) => {
    if (!email || !password) return;
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
      handleLogin();
    }
  };

  return (
    <>
      <img src="/mpa-logo-blue.svg" alt="Logo MPA" style={{ width: '250px' }} />

      <Typography gutterBottom variant="h4" component="h1" align="center">
        Login
      </Typography>
      <Card sx={{ maxWidth: 345, paddingTop: '20px' }}>
        <CardContent>
          <form onKeyDown={handleKeyDown}>
            <FormControl
              sx={{ mt: 2, mb: 1, width: '100%' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-username">
                Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-username"
                type="text"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              sx={{ mt: 2, mb: 1, width: '100%' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                startAdornment={
                  <InputAdornment position="start">
                    <KeyRoundedIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Typography
              className="link"
              align="left"
              onClick={handleForgotPassword}
              sx={{ cursor: 'pointer' }}
            >
              Password dimenticata?
            </Typography>
          </form>
        </CardContent>

        <CardActions>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={handleLogin}
            type="submit"
            disabled={!email || !password}
          >
            Log in
          </Button>
        </CardActions>

        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default Login;
