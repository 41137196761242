import { EXTRA_DOCUMENT_ID } from '../utils/constants';

export type RequestType = {
  id: string;
  name: string;
  subTypes: { id: string; name: string }[];
  status?: { id: string };
};

export type Contraente = {
  id: string;
  ragione_sociale: string;
  partita_iva: string;
  address: string;
  address_number: string;
  zip: string;
  province: string;
  comune: string;
  pec: string;
  is_mandataria: string;
} | null;

export type Beneficiario = {
  id: string;
  ragione_sociale: string;
  partita_iva: string;
  address: string;
  address_number: string;
  zip: string;
  province: string;
  comune: string;
  pec: string;
};

export const beneficiarioDefault: Beneficiario = {
  id: '',
  ragione_sociale: '',
  partita_iva: '',
  address: '',
  address_number: '',
  zip: '',
  province: '',
  comune: '',
  pec: '',
};

export type BeneficiarioField = {
  id: keyof Beneficiario;
  label: string;
  type: string;
  value: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  check?: {
    type?: string;
    pattern?: RegExp;
    helperText?: string;
  };
};

export const beneficiarioFields: BeneficiarioField[] = [
  {
    id: 'ragione_sociale',
    label: 'Ragione sociale',
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'partita_iva',
    label: 'P. Iva',
    type: 'text',
    value: '',
    required: false,
    check: {
      type: 'text',
      pattern: /^[0-9]{11}$/,
      helperText: 'Inserisci una Partita Iva valida',
    },
  },
  {
    id: 'address',
    label: 'Indirizzo',
    type: 'text',
    value: '',
    required: false,
  },
  {
    id: 'address_number',
    label: 'Numero civico',
    type: 'text',
    value: '',
    required: false,
  },
  {
    id: 'zip',
    label: 'CAP',
    type: 'text',
    value: '',
    required: false,
    check: {
      type: 'text',
      pattern: /^[0-9]{5}$/,
      helperText: 'Inserisci un CAP valido',
    },
  },
  {
    id: 'province',
    label: 'Provincia',
    type: 'text',
    value: '',
    required: false,
    check: {
      type: 'text',
      pattern: /^[A-Za-z]{2}$/,
      helperText: 'Usa il formato a due lettere (es. RM)',
    },
  },
  {
    id: 'comune',
    label: 'Comune',
    type: 'text',
    value: '',
    required: false,
    check: {
      type: 'text',
      pattern: /^[A-Za-z ]+$/,
      helperText: 'Usa solo lettere (es. San Gimignano)',
    },
  },
  {
    id: 'pec',
    label: 'Pec',
    type: 'text',
    value: '',
    required: false,
    check: {
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      helperText: "Inserisci un'email valida",
    },
  },
];

export type ContraenteField = {
  id: string; // keyof Contraente
  label_1: string;
  label_2: string;
  type: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  check?: {
    type?: string;
    pattern?: RegExp;
    helperText?: string;
    pattern_2?: RegExp;
    helperText_2?: string;
  };
  value: string;
  showFor?: 'persona_fisica' | 'persona_giuridica';
};

export const contraenteFields: ContraenteField[] = [
  {
    id: 'ragione_sociale',
    label_1: 'Ragione sociale',
    label_2: 'Nome Cognome',
    value: '',
    required: true,
    type: 'text',
  },
  {
    id: 'partita_iva',
    label_1: 'P. Iva',
    label_2: 'Codice Fiscale',
    value: '',
    required: true,
    type: 'text',
    check: {
      type: 'text',
      pattern: /^[0-9]{11}$/,
      helperText: 'Inserisci una Partita Iva valida',
      pattern_2:
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/,
      helperText_2: 'Inserisci un Codice Fiscale valido',
    },
  },
  {
    id: 'partita_iva_pf', // Only for Persona fisica
    label_1: 'P. Iva',
    label_2: 'P. Iva',
    type: 'text',
    value: '',
    required: false,
    showFor: 'persona_fisica',
  },
  {
    id: 'address',
    label_1: 'Indirizzo',
    label_2: 'Indirizzo',
    value: '',
    required: true,
    type: 'text',
  },
  {
    id: 'address_number',
    label_1: 'Numero civico',
    label_2: 'Numero civico',
    value: '',
    required: true,
    type: 'text',
  },
  {
    id: 'zip',
    label_1: 'CAP',
    label_2: 'CAP',
    value: '',
    required: true,
    type: 'text',
    check: {
      type: 'text',
      pattern: /^[0-9]{5}$/,
      helperText: 'Inserisci un cap valido',
      pattern_2: /^[0-9]{5}$/,
      helperText_2: 'Inserisci un cap valido',
    },
  },
  {
    id: 'province',
    label_1: 'Provincia',
    label_2: 'Provincia',
    value: '',
    required: true,
    type: 'text',
    check: {
      type: 'text',
      pattern: /^[A-Za-z]{2}$/,
      helperText: 'Usa il formato a due lettere (es. RM)',
      pattern_2: /^[A-Za-z]{2}$/,
      helperText_2: 'Usa il formato a due lettere (es. RM)',
    },
  },
  {
    id: 'comune',
    label_1: 'Comune',
    label_2: 'Comune',
    value: '',
    required: true,
    type: 'text',
    check: {
      type: 'text',
      pattern: /^[A-Za-z ]+$/,
      helperText: 'Usa solo lettere (es. San Gimignano)',
      pattern_2: /^[A-Za-z ]+$/,
      helperText_2: 'Usa solo lettere (es. San Gimignano)',
    },
  },
  {
    id: 'pec',
    label_1: 'Pec',
    label_2: 'Pec',
    type: 'text',
    value: '',
    required: false,
    check: {
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      helperText: "Inserisci un'email valida",
      pattern_2: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      helperText_2: "Inserisci un'email valida",
    },
  },
];

export type RequestData = {
  contraentes: Contraente[];
  beneficiario: Beneficiario;
};

export type RequestField = {
  id: string;
  name: string;
  required: boolean;
  type: string;
  error?: boolean;
  helperText?: string | null;
  check?: {
    type: string;
    options?: string[];
  };
  conditional?: {
    fieldId: string;
    fieldValue: string;
  };
};

export type Doc = {
  id: string;
  name: string;
  required: boolean;
  description: string;
};

export const personaGiuridicaDocs: Doc[] = [
  {
    id: 'ultimo_bilancio_depositato',
    name: 'Ultimo bilancio depositato',
    required: false,
    description: '',
  },
  {
    id: 'ultimo_bilancio_provvisorio',
    name: 'Ultimo bilancio provvisorio',
    required: false,
    description: '',
  },
  {
    id: 'visura_camerale',
    name: 'Visura camerale',
    required: false,
    description: '',
  },
];

export const personaFisicaDocs: Doc[] = [
  {
    id: 'dichiarazione_dei_redditi',
    name: 'Dichiarazione dei redditi',
    required: false,
    description: '',
  },
  {
    id: 'carta_di_identita',
    name: 'Carta di Identità',
    required: false,
    description: '',
  },
];

export type Status = {
  id: string;
  name: string;
  role: string;
} | null;

export type RequestAgencyField = {
  id: string;
  name: string;
  required?: boolean;
  check?: {
    type: string;
  };
};

export const agencyFields: RequestAgencyField[] = [
  {
    id: 'outcome',
    name: 'OutcomeTrattativa',
  },
  {
    id: 'tasso',
    name: 'Tasso',
    check: { type: 'percentage' },
  },
  {
    id: 'name',
    name: 'name',
    required: true,
    check: { type: 'required' },
  },

  {
    id: 'premio',
    name: 'Premio',
    check: { type: 'number' },
  },
];

export interface InitialSubmissionDTO {
  userId: string;
  status: string;
  contraentes: any[];
  is_ati: boolean;
}
