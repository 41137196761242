import {
  Grid,
  Button,
  Alert,
  Snackbar,
  Typography,
  Card,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';

import { useState } from 'react';
import AddContraenteDialog from '../../components/Request/AddContraente';
import AnagraficheTable from '../../components/Contraente/AnagraficheTable';
import {
  addContraente,
  getContraenteDocumentTypeList,
} from '../../models/request';
import { AlertMessage } from '../../types/form';
import { get, isUndefined } from 'lodash';
import Loading from '../../components/Loading';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const Anagrafiche = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [contraenteDocTypes, setContraenteDocTypes] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshFired, setRefreshFired] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  // hack** added callback to trick the UI to wait for the state
  const fetchContraenteDocTypes = async (callback?: Function) => {
    try {
      const res = await getContraenteDocumentTypeList();
      if (res.status !== 200) {
        throw new Error("Errore durante l'aggiunta del Contraente.");
      }
      setContraenteDocTypes(get(res, 'data.data', []));
    } catch (error) {
      setAlertMessage({
        severity: 'warning',
        message: 'Non è stato possibile aggiungere il Contraente.',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
      !isUndefined(callback) && callback();
    }
  };

  const handleAggiungi = () => {
    setLoading(true);
    const runAsync = async () => {
      await fetchContraenteDocTypes(() => setOpenDialog(true));
    };
    runAsync();
  };

  const closeDialog = () => setOpenDialog(false);

  const handleSubmit = async (fields: any) => {
    try {
      const res = await addContraente(fields);

      if (res.status === 201) {
        setAlertMessage({
          severity: 'success',
          message: 'Contraente aggiunto con successo.',
        });
        setShowAlert(true);
        setTimeout(() => {
          closeDialog();
        }, 3000);
      }
    } catch (error) {
      // Error handled elsewhere, not here
    } finally {
      setRefreshFired(true);
    }
  };

  const handleClearFilter = () => {
    setSearchTerm('');
    setTriggerSearch(!triggerSearch);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setTriggerSearch(!triggerSearch);
    }
  };

  return (
    <Grid container gap={2} justifyContent="center" sx={{ padding: '40px' }}>
      <Grid item xs={10}>
        <Grid container justifyContent="center" alignItems="center">
          <Card
            sx={{
              padding: '16px 0',
              width: '600px',
              boxShadow: 'none',
              border: '1px solid #BDBDBD',
              backgroundColor: 'var(--clr-bg-grey)',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              align="center"
              color="var(--clr-main-blue)"
              fontWeight={600}
            >
              Elenco anagrafiche
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={10} sx={{ marginTop: '80px' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={8}>
            <Box display="flex" gap={2}>
              <TextField
                placeholder="Filtra per nome Contraente"
                style={{ width: '250px' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                variant="outlined"
                style={{ width: '120px' }}
                onClick={() => setTriggerSearch(!triggerSearch)}
              >
                Cerca
              </Button>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Tooltip title="Pulisci filtro">
                  <IconButton onClick={handleClearFilter}>
                    <FilterListOffIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleAggiungi}>
              Nuova anagrafica
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <AnagraficheTable
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
          refreshFired={refreshFired}
          searchTerm={searchTerm}
          triggerSearch={triggerSearch}
        />
      </Grid>
      {loading && <Loading />}
      <AddContraenteDialog
        open={openDialog}
        onClose={closeDialog}
        onSubmit={handleSubmit}
        documentTypes={contraenteDocTypes}
      />
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Anagrafiche;
