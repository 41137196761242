import {
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
} from '@mui/material';
import { useState } from 'react';
import { AlertMessage } from '../../types/form';
import { abandonRequest } from '../../models/request';

import LoadingButton from '../Loading/button';
import { RequestType } from '../../types/request';
import { REQUEST_STATUS } from '../../utils/constants';

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  request: RequestType | null;
  refreshFn: Function;
};

const DeleteModal = ({
  open,
  onClose,
  request,
  refreshFn,
}: DeleteModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const putRequest = async (requestId: string) => {
    const success = [200, 201];
    try {
      const res = await abandonRequest(requestId);
      if (!success.includes(res.status)) {
        throw new Error('Unable to abandon requests');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage({
        severity: 'error',
        message: `Errore durante l'upload dei file`,
      });
      setShowAlert(true);
    } finally {
      await refreshFn();
      setLoading(false);
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (!request?.id) return;
    setLoading(true);
    await putRequest(request.id);
  };

  const handleClose = () => {
    onClose();
  };

  const isDraftStatus =
    request?.status?.id === REQUEST_STATUS.DRAFT_ADMIN ||
    request?.status?.id === REQUEST_STATUS.DRAFT_BROKER;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isDraftStatus ? 'Elimina richiesta' : 'Abbandona richiesta'}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          <div style={{ margin: `12px 4px` }}>
            <label>
              {isDraftStatus
                ? 'Sei sicuro di voler eliminare la richiesta?'
                : "Sei sicuro di voler modificare lo stato in 'Abbandonata'?"}
            </label>
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <Button sx={{ width: '100%' }} variant="outlined" onClick={handleClose}>
          Annulla
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          SÌ
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default DeleteModal;
