import {
  Grid,
  Button,
  Typography,
  Card,
  Menu,
  MenuItem,
  Box,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import UsersRequests from '../../components/RequestsTable/UserRequests';
import { useNavigate } from 'react-router-dom';
import SideMenu from '../../components/Request/SideMenu';
import { useState } from 'react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const UserHome = () => {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNewRequest = () => {
    navigate('/new-request');
    handleMenuClose();
  };

  const handleGenerateLink = () => {
    navigate('/generate-link');
    handleMenuClose();
  };

  const handleClearFilter = () => {
    setSearchTerm('');
    setTriggerSearch(!triggerSearch);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setTriggerSearch(!triggerSearch);
    }
  };

  return (
    <>
      <Grid container justifyContent="center" gap={2} sx={{ padding: '40px' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Card
              sx={{
                padding: '16px 0',
                width: '600px',
                boxShadow: 'none',
                border: '1px solid #BDBDBD',
                backgroundColor: 'var(--clr-bg-grey)',
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                align="center"
                color="var(--clr-main-blue)"
                fontWeight={600}
              >
                Elenco {selectedStatus}
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop="80px">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                columnGap={2}
              >
                <SideMenu
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                />
                <Box display="flex" columnGap={2}>
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Filtra per nome Contraente"
                    style={{ width: '250px' }}
                    onKeyDown={handleKeyDown}
                  />
                  <Button
                    onClick={() => setTriggerSearch(!triggerSearch)}
                    variant="outlined"
                    style={{ width: '120px' }}
                  >
                    Cerca
                  </Button>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip title="Pulisci filtro">
                      <IconButton onClick={handleClearFilter}>
                        <FilterListOffIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleMenuClick}>
                Nuova Richiesta
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleNewRequest}>Aggiungi</MenuItem>
                <MenuItem onClick={handleGenerateLink}>Genera link</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Grid container gap={2} justifyContent="center">
          <Grid item xs={12}>
            {selectedStatus && (
              <UsersRequests
                selectedStatus={selectedStatus}
                searchTerm={searchTerm}
                triggerSearch={triggerSearch}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserHome;
