import { get } from 'lodash';
import api from './api';
import { InitialSubmissionDTO } from '../types/request';

export const userListRequests = async (
  status: string = '',
  page?: number,
  perPage: number = 10,
  searchTerm: string = ''
) => {
  try {
    const params = {
      status,
      page,
      take: perPage,
      searchTerm,
    };
    return await api.get('/request', { params });
  } catch (error) {
    throw new Error('An error occurred while retrieving the user requests');
  }
};

export const deleteRequest = async (id: string) => {
  try {
    return await api.delete(`/request/details/${id}`);
  } catch (error) {
    throw new Error('An error occurred while deleting the request details');
  }
};

export const abandonRequest = async (id: string) => {
  try {
    return await api.put(`/request/details/${id}/abandon`);
  } catch (error) {
    throw new Error('An error occurred while abandoning the request details');
  }
};

export const getRequest = async (id: string) => {
  try {
    return await api.get(`/request/details/${id}`);
  } catch (error) {
    throw new Error('An error occurred while retrieving the request details');
  }
};

export const getInitialSubmission = async (id: string) => {
  try {
    return await api.get(`/request/initial/${id}`);
  } catch (error) {
    throw new Error(
      'An error occurred while retrieving the initial submission'
    );
  }
};

export const getRequestTypes = async () => {
  try {
    return await api.get('/request/submission-type');
  } catch (error) {
    throw new Error('An error occurred while retrieving the request types');
  }
};

export const getBeneficiarioList = async (
  q?: string,
  page?: number,
  perPage: number = 3
) => {
  try {
    const params = {
      q,
      page,
      perPage,
    };
    return await api.get('/request/beneficiario', { params });
  } catch (error) {
    throw new Error('An error occurred while retrieving the Beneficiario list');
  }
};

export const addBeneficiario = async (body: any) => {
  try {
    return await api.post('/request/beneficiario', body);
  } catch (error) {
    throw new Error('An error occurred while adding Beneficiario');
  }
};


export const getContraenteList = async (
  q?: string,
  page?: number,
  perPage: number = 3,
  orderBy?: string,
  orderDirection?: 'ASC' | 'DESC'
) => {
  try {
    const params = {
      q,
      page,
      perPage,
      orderBy,
      orderDirection,
    };
    return await api.get('/request/contraente', { params });
  } catch (error) {
    throw new Error('An error occurred while retrieving the Contraente list');
  }
};

export const getContraenteListFromGuest = async (
  q?: string,
  page?: number,
  perPage: number = 100,
  orderBy?: string,
  orderDirection?: 'ASC' | 'DESC'
) => {
  try {
    const params = {
      q,
      page,
      perPage,
      orderBy,
      orderDirection,
    };
    return await api.get('/request/guest/contraente', { params });
  } catch (error) {
    throw new Error('An error occurred while retrieving the Contraente list');
  }
};

export const getContraenteDocumentTypeList = async () => {
  try {
    return await api.get('/request/contraente/document-types');
  } catch (error) {
    throw new Error(
      'An error occurred while retrieving the Contraente Document Type list'
    );
  }
};

export const addContraente = async (body: any) => {
  try {
    return await api.post('/request/contraente', body);
  } catch (error) {
    throw new Error('An error occurred while adding Contraente');
  }
};

export const addContraenteFromGuest = async (body: any) => {
  try {
    return await api.post('/request/guest/contraente', body);
  } catch (error) {
    throw new Error('An error occurred while adding Contraente');
  }
};

export const updateContraente = async (id: string, body: any) => {
  try {
    return await api.put(`/request/contraente/${id}`, body);
  } catch (error: any) {
    const message = error?.response?.data?.message || '';

    throw {
      message,
    };
  }
};

export const getContraente = async (id: string) => {
  try {
    return await api.get(`/request/contraente/${id}`);
  } catch (error) {
    throw new Error('An error occurred while retrieving the Contraente');
  }
};

export const getFields = async (id?: string) => {
  try {
    return await api.get(`/request/type/${id}/fields`);
  } catch (error) {
    throw new Error('An error occurred while retrieving the request fields');
  }
};

export const getDocuments = async (id: string) => {
  try {
    return await api.get(`/request/type/${id}/documents`);
  } catch (error) {
    throw new Error('An error occurred while retrieving the documents');
  }
};

export const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    return await api.post('/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new Error('An error occurred while uploading the file');
  }
};

export const sendRequest = async (body: any) => {
  try {
    return await api.post('/request', body);
  } catch (error) {
    throw new Error('An error occurred while sending the request');
  }
};

export const createInitialSubmission = async (body: InitialSubmissionDTO) => {
  try {
    return await api.post('/request/create-initial', body);
  } catch (error) {
    throw new Error('An error occurred while creating the submission');
  }
};

export const finalizeSubmission = async (
  submissionId: string,
  submissionData: any
) => {
  try {
    return await api.post(`/request/finalize/${submissionId}`, submissionData);
  } catch (error) {
    throw new Error('An error occurred while finalizing the submission');
  }
};

export const getListOfStatus = async () => {
  try {
    return await api.get('/request/submission-status');
  } catch (error) {
    throw new Error('An error occurred while fetching status');
  }
};

export const getStatuses = async () => {
  try {
    return await api.get(`/request/submission-status`);
  } catch (error) {
    throw new Error('An error occurred while retrieving the list of statuses');
  }
};

export const updateSubmission = async (body: any) => {
  try {
    const id = get(body, 'id', '');
    if (!id) {
      throw new Error('An error occurred while updating request');
    }
    return await api.put(`/request/details/${id}`, body);
  } catch (error) {
    throw new Error('An error occurred while updating request');
  }
};

export const updateSubmissionStatus = async (body: any) => {
  try {
    const id = get(body, 'id', '');
    if (!id) {
      throw new Error('An error occurred while updating status');
    }
    return await api.put(`/request/details/${id}/status`, body);
  } catch (error) {
    throw new Error('An error occurred while updating status');
  }
};

export const updateSubmissionInternalAgency = async (body: any) => {
  try {
    const id = get(body, 'id', '');
    if (!id) {
      throw new Error('An error occurred while updating internal agency');
    }
    return await api.put(`/request/details/${id}/internalagency`, body);
  } catch (error) {
    throw new Error('An error occurred while updating internal agency');
  }
};

export const exportSubmissions = async (body: any) => {
  try {
    return await api.post(`/request/export`, body, { responseType: 'blob' });
  } catch (error) {
    throw new Error('An error occurred in exporting');
  }
};
